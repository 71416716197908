import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import useSettingsApi from 'hooks/api/useSettingsApi';
import ScreenHeader from 'components/ScreenHeader';
import { CheckboxField, TextField } from 'components/fields';
import Button from '@setproduct-ui/core/Button';
import Spinner from '@setproduct-ui/core/Spinner';
import endpoints from 'consts/endpoints';
import { Typography } from 'components';
import { PdfLinkIcon } from 'assets/icons';
import { MANUAL_LINK } from 'consts';

import './style.scss';

const SettingsScreen = () => {
  const buttonStyle = {
    marginTop: 'auto',
    marginLeft: 'auto',
    width: 150,
  };
  const fieldStyle = ({
    width = 470, marginBottom = 12,
  } = {}) => ({
    container: {
      width,
      marginBottom,
    },
  });

  const checkBoxStyles = {
    container: {
      marginLeft: 0,
      marginBottom: 24,
    },
  };

  const { t } = useTranslation();
  const [buildInfo, setBuildInfo] = useState('');
  const {
    getSettings,
    settings,
    putSettings,
    isPendingPutSettings,
    isPendingGetSettings,
  } = useSettingsApi();

  const onSubmit = (body) => {
    putSettings({
      body,
      successCallback: () => getSettings(),
    });
  };

  useEffect(() => {
    getSettings();

    fetch(endpoints.getBuildInfoUrl())
      .then(res => res.json())
      .then(res => setBuildInfo(Object.entries(res).reduce((acc, [key, value]) => {
        acc += `${acc ? '\n' : ''}${key.replace('.', ' ')}: ${value}`;
        return acc;
      }, '')));
  }, []);

  return (
    <div className="settings-layout">
      <ScreenHeader
        title="TABS.GENERAL_SETTINGS"
        showColumns={false}
        showFilters={false}
      />
      {
        isPendingGetSettings
          ? (
            <div className="settings-layout__spinner-wrapper">
              <Spinner />
            </div>
          ) : (
            <Formik
              initialValues={settings}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ handleSubmit, values }) => (
                <Form className="settings-layout__form">
                  <Field
                    name="googleRcsURL"
                    label="Google RCS URL"
                    component={TextField}
                    styles={fieldStyle({ marginBottom: 24 })}
                  />
                  <Field
                    name="nexmoURL"
                    label="Nexmo URL"
                    component={TextField}
                    styles={fieldStyle()}
                  />
                  <Field
                    name="nexmoApiKey"
                    label="Nexmo API key"
                    component={TextField}
                    styles={fieldStyle()}
                  />
                  <Field
                    name="nexmoApiSecret"
                    label="Nexmo API secret"
                    component={TextField}
                    styles={fieldStyle({ marginBottom: 5 })}
                    disabled={!values.setNexmoApiSecret}
                  />
                  <Field
                    type="dense"
                    name="setNexmoApiSecret"
                    component={CheckboxField}
                    styles={checkBoxStyles}
                    text="Edit Nexmo API secret"
                  />
                  <Field
                    name="dialog360Url"
                    label="Dialog360 URL"
                    component={TextField}
                    styles={fieldStyle({ marginBottom: 24 })}
                  />
                  <Field
                    name="transitRcsAuthURL"
                    label="Transit RCS Auth URL"
                    component={TextField}
                    styles={fieldStyle()}
                  />
                  <Field
                    name="transitRcsURL"
                    label="Transit RCS URL"
                    component={TextField}
                    styles={fieldStyle()}
                  />
                  <Field
                    name="transitSmsURL"
                    label="Transit SMS URL"
                    component={TextField}
                    styles={fieldStyle()}
                  />
                  <Button
                    text={t('CONTROLS.SAVE')}
                    color="primary"
                    onClick={handleSubmit}
                    style={buttonStyle}
                    loading={isPendingPutSettings}
                  />
                </Form>
              )}
            </Formik>
          )
      }
      <div className="settings-layout__info-container">
        <div className="settings-layout__build-info">
          {buildInfo}
        </div>
        <a
          className="settings-layout__manual-link"
          target="_blank"
          href={MANUAL_LINK}
          rel="noreferrer"
        >
          <PdfLinkIcon />
          <Typography
            tagName="a"
          >
            INSTANCES.USER_MANUAL
          </Typography>
        </a>
      </div>
    </div>
  );
};

export default SettingsScreen;
