import endpoints from 'consts/endpoints';

export default ({ group = 'backoffice', listKey = 'boRoles' } = {}) => ({
  url: endpoints.getRolesUrl(),
  body: {
    group,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: response => ({
    [listKey]: response,
  }),
  update: {
    [listKey]: (oldValue, newValue) => newValue,
  },
});
