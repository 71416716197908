import React, { useMemo } from 'react';
import { Icon } from '@blueprintjs/core';
import moment from 'moment';

import Button from '@setproduct-ui/core/Button';
import {
  RCSIcon,
  SmsChannelIcon,
  ViberChannelIcon,
  WhatsappChannelIcon,
  EmailChannelIcon,
} from 'assets/icons';
import { useDictionariesApi } from 'hooks/api';

import styles from './RequestModal.module.scss';

import {
  WhatsAppPreview,
  ViberPreview,
  SmsPreview,
  RcsPreview,
  EmailPreview,
  WeChatPreview,
} from '../previews';
import parseMessage from '../helpers/parseMessage';

const CHANNELS_ICONS = {
  1: <SmsChannelIcon />,
  2: <RCSIcon />,
  3: <ViberChannelIcon />,
  4: <WhatsappChannelIcon />,
  7: <EmailChannelIcon />,
};

const RequestModal = ({
  data,
  onAllowRequests,
  onBlockRequests,
  mode,
  onClose,
}) => {
  const {
    companiesDictionary,
  } = useDictionariesApi();

  const clientName = useMemo(
    () =>
      companiesDictionary[data.clientCompanyID]?.name,
    [data, companiesDictionary],
  );

  const preparedMessages = useMemo(() => {
    let result = [];
    if (data?.rawContent) {
      result = data?.rawContent.reduce((acc, item) => {
        const message = parseMessage(item.content);
        switch (item.type) {
          case 'WHATSAPP': {
            const preview = (
              <WhatsAppPreview
                message={message}
                showChannelIcon={data.channel.length > 1}
              />
            );
            acc.push(preview);
            break;
          }
          case 'SMS': {
            const preview = (
              <SmsPreview
                message={message}
                showChannelIcon={data.channel.length > 1}
              />
            );
            acc.push(preview);
            break;
          }
          case 'VIBER': {
            const preview = (
              <ViberPreview
                message={message}
                showChannelIcon={data.channel.length > 1}
              />
            );
            acc.push(preview);
            break;
          }
          case 'RCS': {
            const preview = (
              <RcsPreview
                message={message}
                showChannelIcon={data.channel.length > 1}
              />
            );
            acc.push(preview);
            break;
          }
          case 'WE_CHAT': {
            const preview = (
              <WeChatPreview
                message={message}
                showChannelIcon={data.channel.length > 1}
              />
            );
            acc.push(preview);
            break;
          }
          case 'EMAIL': {
            const preview = (
              <EmailPreview
                message={JSON.parse(item.content)?.body?.rendered?.find(view => view.contentType === 'html')?.content}
              />
            );
            acc.push(preview);
            break;
          }
          default:
            break;
        }
        return acc;
      }, []);
    }
    return result;
  }, [data.rawContent]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerItems}>
          <div className={styles.headerItem}>
            <div className={styles.headerItemTitle}>
              {data.type === 0 ? 'Broadcast Name:' : 'Chatbot Name:'}
            </div>
            {data?.externalName}
          </div>
          <div className={styles.headerItem}>
            <div className={styles.headerItemTitle}>
              Client Name:
            </div>
            {clientName}
          </div>
          <div className={styles.headerItem}>
            <div className={styles.headerItemTitle}>
              Created:
            </div>
            {moment(data.createdAt).format('DD.MM.YYYY hh:mm')}
          </div>
          <div className={styles.headerChannelItem}>
            {data.channel.map(I => CHANNELS_ICONS[I])}
          </div>
        </div>
        <Icon icon="cross" size={24} className={styles.closeButton} onClick={onClose} />
      </div>
      <div className={styles.body}>
        {preparedMessages.map(I => I)}
      </div>
      {mode === 'edit' && (
        <div className={styles.controls}>
          <Button
            color="danger"
            icon="disable"
            text="Block"
            type="default"
            view="filled"
            onClick={() => onBlockRequests([data.id])}
          />
          <Button
            color="primary"
            icon="tick"
            text="Allow"
            type="default"
            view="filled"
            onClick={() => onAllowRequests([data.id])}
          />
        </div>
      )}
    </div>
  );
};

export default RequestModal;
