import { Icon } from '@blueprintjs/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useDropdownLogic } from 'hooks';
import { Typography } from 'components/Typography';

import './style.scss';

export const FlatDropdown = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
  } = {},
  label,
  disabled,
  options = [],
  styles = {},
  caretOptions: {
    visible = true,
  } = {},
  translateOptions = true,
  readOnly,
  clearButton = false,
  fieldClassNames = {},
  children,
}) => {
  const { t } = useTranslation();

  const fieldClass = classNames(`flat-dropdown-field ${fieldClassNames.container}`, {
    'flat-dropdown-field_disabled': disabled,
  });

  const {
    dropdownRef,
    isDropdownOpen,
    setDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();

  const handleChange = (selectedItem) => {
    if (setFieldValue) {
      setFieldValue(name, selectedItem.value);
    } else {
      onChange(selectedItem.value);
    }
    setDropdownOpen(false);
  };

  const onCaretClick = () => {
    if (!disabled && !readOnly) {
      toggleDropdown();
    }
  };

  const displayOptions = useMemo(() => options.filter(I => I.value === value), [options, value]);

  const selectedItem = useMemo(() => options.find(I => I.value === value).label, [options, value]);

  const iconClassName = classNames('flat-dropdown-field__content__icon', {
    'flat-dropdown-field__content__icon_open': isDropdownOpen,
    'flat-dropdown-field__content__icon_disabled': disabled,
  });

  return (
    <div className={fieldClass} ref={dropdownRef} style={styles.container}>
      {
        label && (
          <Typography className="flat-dropdown-field__label" style={styles.label}>
            {label}
          </Typography>
        )
      }
      <div className="flat-dropdown-field__content" style={styles.content}>
        <div
          role="presentation"
          className="flat-dropdown-field__content__title"
          onClick={onCaretClick}
        >
          <div className="flat-dropdown-field__content__text">{selectedItem}</div>
          {
            visible && !(clearButton && isDropdownOpen && value) && (
              <Icon
                icon="chevron-down"
                iconSize={18}
                color="var(--blue70)"
                className={iconClassName}
              />
            )
          }
        </div>
        {
          isDropdownOpen && (
            <div className="flat-dropdown-field__content__overlay" style={styles.overlay}>
              {
                displayOptions.map(I => (
                  <button
                    className="flat-dropdown-field__content__overlay__item"
                    onClick={() => handleChange(I)}
                    type="button"
                    name="country"
                    key={I.value}
                    style={styles.item}
                    data-testid={I.value
                      ? `${window.location.pathname}/flat-dropdown-field/${I.value}`
                      : undefined}
                  >
                    {translateOptions ? t(I.label) : I.label}
                  </button>
                ))
              }
            </div>
          )
        }
      </div>
      {children}
    </div>
  );
};
