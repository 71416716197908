import classNames from 'classnames';
import { Icon } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { paths } from 'routes';

import { Typography } from '../Typography';

const SidebarItem = ({
  name,
  icon,
  iconComponent,
  path,
  asideShow,
  childrenRoutes,
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { activeTab } = useSelector(state => state.states.routes);

  const activeDirectory = useMemo(() => {
    if (activeTab) {
      const directory = activeTab.substring(0, activeTab.indexOf(' '));
      if (directory === 'companies') {
        return '/';
      }

      return `/${directory}`;
    }

    return '';
  }, [activeTab]);
  const isChildrenExisted = Array.isArray(childrenRoutes) && !!childrenRoutes.length;
  const isActive = isChildrenExisted && pathname !== paths.companies
    ? childrenRoutes.some(el =>
      (pathname === el.path && el.path !== paths.companies)
      || el.children?.some(subel => pathname === subel.path))
    : path === pathname;
  const isExpanded = isActive && isChildrenExisted && asideShow;
  const expandIconName = isActive ? 'small-minus' : 'small-plus';
  const itemClasses = classNames('sidebar__item', {
    expandable: isChildrenExisted,
    expandable_active: isExpanded,
    active: !asideShow && isActive,
  });
  const expandedClasses = classNames('sidebar__item__hidden', {
    sidebar__item__expanded: isExpanded,
  });

  const handleNavClick = useCallback(() => {
    dispatch({ type: 'setActiveTab', activeTab: '' });

    if (isChildrenExisted && !asideShow) {
      dispatch({ type: 'set', asideShow: true });
    }
  }, [dispatch, isChildrenExisted, asideShow]);

  const resetActiveTab = () => {
    dispatch({ type: 'setActiveTab', activeTab: '' });
  };

  const renderChildrenLinks = useCallback(
    chidls =>
      chidls?.map(({
        name: childrenName, path: childrenPath, children,
      }) => {
        const isSublinkActive = pathname === childrenPath
        || children?.some(el => pathname.includes(el.path));
        const isSubLinkExpanded = isSublinkActive && children;
        const subLinkExpandIconName = isSublinkActive ? 'chevron-down' : 'chevron-right';
        const subLinkExpandedClasses = classNames('sidebar__item__hidden sidebar__item__subitems', {
          sidebar__item__expanded: isSubLinkExpanded,
        });
        const sublinkClases = classNames('sidebar__item', {
          actived: pathname !== paths.companies && paths.companies === childrenPath,
        });
        return (
          <div key={childrenPath}>
            <NavLink
              to={childrenPath}
              className={sublinkClases}
              style={{ paddingLeft: 10, marginBottom: 0 }}
              onClick={resetActiveTab}
            >
              {children && asideShow && (
                <div className="sidebar__item__chevron">
                  <Icon
                    icon={subLinkExpandIconName}
                    iconSize={12}
                    color="var(--indigo10)"
                  />
                </div>
              )}
              <Typography className="sidebar__item__expanded__name">
                {childrenName}
              </Typography>
              {
                activeDirectory === childrenPath && (
                  <Icon
                    icon="add"
                    size={12}
                    color="var(--indigo30)"
                    style={{ marginLeft: 8 }}
                  />
                )
              }
            </NavLink>
            <div
              className={subLinkExpandedClasses}
              style={{ paddingLeft: 10 }}
            >
              {renderChildrenLinks(children)}
            </div>
          </div>
        );
      }),
    [childrenRoutes, pathname, asideShow, activeDirectory],
  );

  return (
    <>
      <NavLink
        exact
        className={itemClasses}
        onClick={handleNavClick}
        to={path}
      >
        <span className="bp3-icon sidebar__item__icon">
          {iconComponent}
        </span>
        <Icon
          icon={icon}
          size={16}
          color="var(--indigo30)"
          className="sidebar__item__icon"
        />
        <Typography className="sidebar__item__name">{name}</Typography>
        {isChildrenExisted && asideShow && (
          <div className="sidebar__item__expand">
            <Icon
              icon={expandIconName}
              size={16}
              color={isActive ? 'var(--orange30)' : 'var(--indigo30)'}
            />
          </div>
        )}
      </NavLink>
      <div className={expandedClasses}>{renderChildrenLinks(childrenRoutes)}</div>
    </>
  );
};

export default SidebarItem;
