import { Icon } from '@blueprintjs/core';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useKey } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';

import { MaskField, ButtonGroupField } from 'components/fields';
import { Typography } from 'components/Typography';
import Button from '@setproduct-ui/core/Button/Button';
import Card from '@setproduct-ui/core/Card';
import { FILTER_BUTTONS } from 'consts';

import './style.scss';

export default forwardRef(
  (
    {
      api,
      filterChangedCallback,
      styles,
      onCancel = Function.prototype,
      colDef: { field, fieldId, headerComponentParams: { withoutEmpty } = {} } = {},
    },
    ref,
  ) => {
    const isApply = useRef();
    const formRef = useRef();
    const { t } = useTranslation();

    const fieldStyle = ({ width, marginTop } = {}) => ({
      container: {
        width,
      },
      label: {
        marginTop,
      },
    });

    useKey('Escape', onCancel);

    useKey('Enter', () => {
      formRef.current.handleSubmit();
    });

    useImperativeHandle(ref, () => ({
      getModel() {
        // eslint-disable-next-line react/no-this-in-sfc
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          values: formRef.current.values,
          type: 'mask',
          isWithoutEmpty: withoutEmpty,
        };
      },
      setModel(data) {
        isApply.current = true;
        if (data) {
          const { values } = data;
          if (!withoutEmpty && values.value) {
            values.isMask = true;
          }

          if (formRef?.current?.setValues) {
            formRef.current.setValues(values);
          }
          // todo нужно для таких кейсов, применен именной фильтр к одному атрибуту
          // а потом применяем именой фильтр к другому атрибуты и поля в этом фильтре не заполняются
          setTimeout(() => {
            formRef.current.resetForm();
            if (formRef?.current?.setValues) {
              formRef.current.setValues(values);
            }
          });
        } else {
          formRef.current.resetForm({
            values: {
              value: '',
            },
          });
        }
      },
      isFilterActive() {
        const { values } = formRef.current;

        if (!values || !isApply.current) {
          return false;
        }

        return (
          !!values.value || values.empty || values.notempty || values.isMask
        );
      },
      doesFilterPass() {
        return true;
      },
      afterGuiAttached() {
        const { values, setFieldValue } = formRef.current;

        if (!values.empty && !values.notempty) {
          if (!withoutEmpty && !values.isMask) {
            setFieldValue('isMask', true);
          }
        }
      },
    }));

    return (
      <Formik
        innerRef={formRef}
        keepDirtyOnReinitialize
        initialValues={{}}
        onSubmit={(values) => {
          isApply.current = true;
          filterChangedCallback({ values, field, fieldId });

          if (values.filterConfig === 'empty' || values.filterConfig === 'notempty') {
            formRef.current.setFieldValue('value', '');
          }

          if (api) {
            api.menuFactory.hidePopup();
          }
          api.redrawRows();
          setTimeout(() => api.sendQuery());
        }}
      >
        {({
          handleSubmit, values,
        }) => (
          <Form
            data-testid={`${window.location.pathname}/filter/mask/${field}`}
            onSubmit={handleSubmit}
            className="mask-filter"
            style={styles}
          >
            {withoutEmpty ? (
              <div className="mask-filter__mask-field">
                <Field
                  name="value"
                  component={MaskField}
                  placeholder={t('PLACEHOLDERS.FILTER.INPUT_MASK')}
                  data-testid={`${window.location.pathname}/filter/mask/${field}/value`}
                />
              </div>
            ) : (
              <>
                <Field
                  name="filterConfig"
                  className="mask-filter__button-group"
                  component={ButtonGroupField}
                  options={FILTER_BUTTONS}
                  fieldName={field}
                  data-testid={`${window.location.pathname}/filter/mask/${field}/filter-config`}
                />
                {values.filterConfig === 'notempty' || values.filterConfig === 'empty' ? (
                  <Card
                    type="def"
                    view="smooth"
                    color="warning_alt"
                    interactive={false}
                    className="mask-filter__card"
                  >
                    <div className="mask-filter__card__content">
                      <Icon color="#FDC68B" icon="info-sign" iconSize={16} />
                      <Typography
                        tagName="span"
                        className="mask-filter__card__content__text"
                      >
                        {t('CONTROLS.FILTER.DISPLAYED_CELLS', {
                          what: t(`CONTROLS.FILTER.${values.filterConfig === 'empty' ? 'EMPTY_CELLS' : 'NOT_EMPTY_CELLS'}`),
                        })}
                      </Typography>
                    </div>
                  </Card>
                ) : (
                  <div className="mask-filter__mask-field">
                    <Field
                      name="value"
                      styles={fieldStyle({ width: 286 })}
                      component={MaskField}
                      placeholder={t('PLACEHOLDERS.FILTER.INPUT_MASK')}
                      data-testid={`${window.location.pathname}/filter/mask/${field}/value`}
                    />
                  </div>
                )}
              </>
            )}
            <div className="mask-filter__filter-controls">
              <Button
                dense
                view="filled"
                color="primary"
                onClick={handleSubmit}
                className="mask-filter__filter-controls__button"
                data-testid={`${window.location.pathname}/filter/mask/${field}/apply`}
              >
                <Typography
                  tagName="span"
                  className="mask-filter__filter-controls__button__text"
                >
                  {t('CONTROLS.FILTER.APPLY_FILTER')}
                </Typography>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  },
);
