import React from 'react';

import { Modal } from 'components';
import Table from 'components/Table';
import { AGREEMENTS } from 'consts/columns';
import { CustomColumnsView } from 'components/blocks';
import { useScreen } from 'hooks';
import { useAgreementsApi } from 'hooks/api';

import ClientModal from './ClientModal';

import './style.scss';

const ClientsScreen = ({ screenRef }) => {
  const {
    agreements,
    agreementsMeta,
    getAgreements,
    isPendingGetAgreements,
  } = useAgreementsApi();

  const {
    openModal,
    sendQuery,
    closeModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    isOpenModal,
  } = useScreen({
    screenRef,
    getFunc: getAgreements,
  });

  const onRowClicked = () => {
    openModal('add');
  };

  return (
    <div className="clients-layout">
      {(process.env.NODE_ENV !== 'production' || window.location.origin.includes('mkitdev2.alarislabs')) ? (
        <Table
          limit={limit}
          total={agreementsMeta.size}
          page={offset}
          onChangePage={setOffset}
          onChangeLimit={setLimit}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          filteredColumns={filteredColumns}
          tableHeader="TABS.CLIENTS"
          // openForm={() => openModal('add')}
          openCustomColumns={openCustomViewDrawer}
          buttonText="CONTROLS.CLIENTS.ADD"
          columnDefs={AGREEMENTS}
          rowData={agreements}
          tableRef={tableRef}
          onRowClicked={onRowClicked}
          sendQuery={sendQuery}
          showFilters={false}
          isPending={isPendingGetAgreements}
        />
      ) : (
        <div>Coming soon</div>
      )}
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={AGREEMENTS}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            withTitle={false}
            className="client-modal"
          >
            <ClientModal closeModal={closeModal} />
          </Modal>
        )
      }
    </div>
  );
};

export default ClientsScreen;
