import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';

import Button from '@setproduct-ui/core/Button/Button';
import { useAsideOpened } from 'hooks';
import { MenuOpenIcon } from 'assets/icons';

import SavedTabs from './SavedTabs';
import RightTools from './RightTools';

import './style.scss';

const Header = ({ screenRef }) => {
  const { width } = useWindowSize();

  const dispatch = useDispatch();
  const asideShow = useSelector(state => state.states.asideShow);
  const { activeTab } = useSelector(state => state.states.routes);
  const menuOpenIconClasses = classNames('header__icon', {
    header__icon__rotated: !asideShow,
  });

  useAsideOpened(asideShow);

  const handleMenuIconClick = useCallback(
    () => {
      dispatch({ type: 'set', asideShow: !asideShow });
    },
    [dispatch, asideShow],
  );

  const onPinClick = () => {
    screenRef?.current?.saveState();
  };

  useEffect(() => {
    if (width <= 1440 && asideShow) {
      dispatch({ type: 'set', asideShow: false });
    }
  }, [width]);

  return (
    <header className="header">
      <button type="button" className={menuOpenIconClasses} onClick={handleMenuIconClick}>
        <MenuOpenIcon />
      </button>
      <SavedTabs screenRef={screenRef} />
      <Button
        dense
        view="smooth"
        icon="unpin"
        type="icon"
        color="primary"
        iconSize={18}
        onClick={onPinClick}
        className="header__pin-button"
        disabled={!!activeTab}
      />
      <RightTools />
    </header>
  );
};

export default Header;
