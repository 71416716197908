/* eslint-disable */
import { Typography, Accordion } from 'components';
import Button from '@setproduct-ui/core/Button/Button';
import {
  ArrayField,
  TextField,
  HistoricalField,
  DropdownField,
  TextAreaField,
} from 'components/fields';

import {
  Formik,
  Form,
  FieldArray,
  Field,
} from 'formik';
import React from 'react';

import ContactPerson from './ContactPerson';

import './style.scss';

const CONTACTS = [{
  id: 1,
  name: 'Tadeo Antoniutti',
  type: 'Trantow Group',
  phone: '320-666-2138',
  email: 'tantoniutti0@addtoany.com',
}, {
  id: 2,
  name: 'Niccolo Clarey',
  type: 'Yundt-Heller',
  phone: '185-810-2064',
  email: 'nclarey1@wufoo.com',
}, {
  id: 3,
  name: 'Obadias Janik',
  type: 'Macejkovic-Keebler',
  phone: '875-596-2660',
  email: 'ojanik2@friendfeed.com',
}, {
  id: 4,
  name: 'Aliza Passy',
  type: 'Moore LLC',
  phone: '108-169-2067',
  email: 'apassy3@forbes.com',
}, {
  id: 5,
  name: 'Loralie Aspell',
  type: 'Morissette-Corwin',
  phone: '486-388-1836',
  email: 'laspell4@skype.com',
}, {
  id: 6,
  name: 'Kala Genge',
  type: "Murphy, Stoltenberg and O'Kon",
  phone: '606-307-2265',
  email: 'kgenge5@microsoft.com',
}, {
  id: 7,
  name: 'Jard Mullany',
  type: 'Ankunding-Kunze',
  phone: '490-171-1470',
  email: 'jmullany6@paypal.com',
}, {
  id: 8,
  name: 'Joaquin Prinnett',
  type: 'Padberg, Hyatt and Wintheiser',
  phone: '208-443-0724',
  email: 'jprinnett7@wikipedia.org',
}, {
  id: 9,
  name: 'Emili Parslow',
  type: "Schiller, Nader and O'Connell",
  phone: '492-104-3757',
  email: 'eparslow8@cnn.com',
}, {
  id: 10,
  name: 'Aleece Wrankling',
  type: 'Denesik-Windler',
  phone: '253-463-6441',
  email: 'awrankling9@nhs.uk',
}];

const FILES = [{
  id: 1,
  fileName: 'In',
  created: '2022-01-11T07:44:42.16969',
  files: [],
}, {
  id: 4,
  fileName: 'UltricesPosuere',
  created: '2022-01-11T07:44:42.16969',
  files: [
    {
      id: 5,
      fileName: 'UtUltrices',
      created: '2022-01-11T07:44:42.16969',
    },
  ],
}, {
  id: 6,
  fileName: 'TortorDuisMattis',
  created: '2022-01-11T07:44:42.16969',
  files: [
    {
      id: 7,
      fileName: 'UtUltrices',
      created: '2022-01-11T07:44:42.16969',
    },
    {
      id: 8,
      fileName: 'UtUltrices',
      created: '2022-01-11T07:44:42.16969',
    },
    {
      id: 9,
      fileName: 'UtUltrices',
      created: '2022-01-11T07:44:42.16969',
    },
  ],
}, {
  id: 10,
  fileName: 'UtUltrices',
  created: '2022-01-11T07:44:42.16969',
  files: [
    {
      id: 11,
      fileName: 'UtUltrices',
      created: '2022-01-11T07:44:42.16969',
    },
    {
      id: 12,
      fileName: 'UtUltrices',
      created: '2022-01-11T07:44:42.16969',
    },
    {
      id: 13,
      fileName: 'UtUltrices',
      created: '2022-01-11T07:44:42.16969',
    },
  ],
}, {
  id: 14,
  fileName: 'Vel',
  created: '2022-01-11T07:44:42.16969',
  files: [
    {
      id: 15,
      fileName: 'Vel.pdf',
      created: '2022-01-11T07:44:42.16969',
    },
  ],
}];

const SITES = [
  'www.smmerboy.com',
  'www.smmerboy.comee',
];

const historicalData = [{
  value: '10856 Michigan Alley',
  date: '2021-06-17T06:35:25Z',
}, {
  value: '4867 Emmet Drive',
  date: '2021-03-14T20:36:41Z',
}, {
  value: '18750 4th Junction',
  date: '2021-12-08T06:23:14Z',
}, {
  value: '783 Delladonna Crossing',
  date: '2021-07-24T10:48:47Z',
}, {
  value: '6151 Westridge Hill',
  date: '2021-02-21T20:21:21Z',
}];

export default () => {
  const fieldStyle = ({
    marginBottom,
    marginRight,
    marginLeft,
    marginTop,
  } = {}) => ({
    container: {
      marginBottom,
      marginRight,
      marginLeft,
    },
    label: {
      marginTop,
    },
  });

  return (
    <div className="total-information">
      <div className="client-information">
        <div className="total-information__block-header">
          <Typography large>Client information</Typography>
        </div>
        <div className="total-information__block-content">
          <Formik
            enableReinitialize
            initialValues={{
              id: 111,
              regDate: '10.11.2022',
              currency: 'RUB',
              tax: '20%',
              companyName: 'ООО "Село Зеленое"',
              brandName: '"Summer"',
              comments: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor',
              legalAddress: 'Томская область, с.Богашево, ул. Киевская, 28',
              corlAddress: 'Томская область, с.Богашево, ул. Киевская, 28',
              sites: SITES,
            }}
            validateOnMount
            onSubmit={() => {}}
          >
            <Form>
              <div className="total-information__form">
                <div className="total-information__form__row">
                  <Field
                    newDesign
                    name="id"
                    label="id"
                    component={TextField}
                    view="smooth"
                    fill
                  />
                  <Field
                    newDesign
                    name="regDate"
                    label="Registration date"
                    component={TextField}
                    view="smooth"
                    fill
                  />
                </div>
                <div className="total-information__form__row">
                  <Field
                    newDesign
                    name="currency"
                    label="Currency"
                    styles={fieldStyle({ marginTop: 18 })}
                    component={TextField}
                    view="smooth"
                    fill
                  />
                  <Field
                    newDesign
                    name="tax"
                    label="Tax"
                    styles={fieldStyle({ marginTop: 18 })}
                    component={TextField}
                    view="smooth"
                    fill
                  />
                </div>
                <Field
                  newDesign
                  name="companyName"
                  label="Company Name"
                  styles={fieldStyle({ marginTop: 18 })}
                  component={HistoricalField}
                  historicalData={historicalData}
                  fill
                />
                <Field
                  newDesign
                  name="brandName"
                  label="Brand name"
                  styles={fieldStyle({ marginTop: 18 })}
                  component={TextField}
                  fill
                />
               <FieldArray name="sites">
                {props => (
                  <ArrayField
                    {...props}
                    label="Client site"
                    newDesign
                    styles={fieldStyle({ marginTop: 18 })}
                    fill
                  />
                )}
               </FieldArray>
                <Field
                  newDesign
                  name="scope"
                  label="Scope"
                  styles={fieldStyle({ marginTop: 18 })}
                  component={DropdownField}
                  fill
                />
                <Field
                  newDesign
                  name="corlAddress"
                  label="Correspondence address"
                  styles={fieldStyle({ marginTop: 18 })}
                  component={HistoricalField}
                  historicalData={historicalData}
                  fill
                />
                <Field
                  newDesign
                  name="legalAddress"
                  label="Legal address"
                  component={HistoricalField}
                  styles={fieldStyle({ marginTop: 18 })}
                  historicalData={historicalData}
                  fill
                />
                <Field
                  newDesign
                  name="comments"
                  label="Comments"
                  component={TextAreaField}
                  showCharCount
                  maxLength={300}
                  styles={fieldStyle({ marginTop: 18 })}
                  fill
                />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="contacts">
        <div className="contacts__contact-persons">
          <div className="total-information__block-header">
            <Typography large>Contact persons</Typography>
            <Button
              type="icon"
              view="smooth"
              color="primary"
              icon="plus"
            />
          </div>
          <div className="total-information__block-content">
            {CONTACTS.map(I => (
              <ContactPerson {...I} />
            ))}
          </div>
        </div>
        <div className="contacts__agreements">
          <div className="total-information__block-header">
            <Typography large>Agreements</Typography>
            <Button
              type="icon"
              view="smooth"
              color="primary"
              icon="plus"
            />
          </div>
          <div className="total-information__block-content">
            <Accordion data={FILES} />
          </div>
        </div>
      </div>
    </div>
  );
};
