import React, { useState, useMemo } from 'react';

import Drawer from 'components/Drawer';
import Radio from '@setproduct-ui/core/Radio';
import Button from '@setproduct-ui/core/Button';
import { SENDERS_CHANNELS_IDS } from 'consts/channels';

import './style.scss';

const SelectChannel = ({
  openModal,
  isOpen,
  onClose,
}) => {
  const buttonStyle = {
    margin: '24px 0 0 auto',
  };

  const [channel, setChannel] = useState(1);

  const channelsOptionsModify = useMemo(() => {
    if (process.env.NODE_ENV !== 'production'
      || window.location.origin.includes('mkitdev2.alarislabs')) {
      return SENDERS_CHANNELS_IDS;
    }

    return SENDERS_CHANNELS_IDS.filter(item => item.value !== 5);
  }, []);

  const onApplyModify = () => {
    openModal(SENDERS_CHANNELS_IDS.find(I => I.value === channel).value);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="CONTROLS.SENDER_IDS.ADD"
    >
      <div className="select-channel">
        {
          channelsOptionsModify.map(C => (
            <Radio
              key={C.value}
              checked={C.value === channel}
              label={C.label}
              onChange={() => setChannel(C.value)}
              color="primary"
              className="select-channel__radio"
            />
          ))
        }
      </div>
      <Button
        color="primary"
        onClick={onApplyModify}
        text="CONTROLS.APPLY"
        style={buttonStyle}
        dense
      />
    </Drawer>
  );
};

export default SelectChannel;
