import { Icon } from '@blueprintjs/core';
import React, { useState } from 'react';

import TextField from 'components/fields/TextField';

import './style.scss';

export const ArrayField = ({
  push,
  remove,
  pop,
  replace,
  name: externalName,
  values: externalValues,
  field: {
    name = externalName,
  } = {},
  form: {
    values = externalValues,
    errors = {},
    touched = {},
  } = {},
  placeholder,
  label,
  disabled,
  styles = {},
  isRequired,
  readOnly,
  fieldClassNames = {},
  disabledItem,
  fill,
  newDesign,
  ...props
}) => {
  const [newItem, setNewItem] = useState('');

  const onChangeItem = (index, value) => {
    replace(index, value);
  };

  return (
    <div className={`array-field ${fieldClassNames.container}`} style={styles.container}>
      {values[name].map((I, index) => (
        <TextField
          newDesign={newDesign}
          fill={fill}
          value={I}
          label={index === 0 ? label : undefined}
          classNames={{ container: 'array-field__input array-field__item-input' }}
          onChange={value => onChangeItem(index, value)}
          disabled={disabledItem}
          styles={{ container: styles.itemInput, label: index === 0 ? styles.label : {} }}
          clearButton={false}
          showErrorIcon={false}
          dense={false}
          rightElement={(
            <div className="array-field__input-icon">
              <Icon
                color="var(--grey30)"
                icon="delete"
                iconSize={20}
                onClick={() => remove(index)}
              />
            </div>
          )}
        />
      ))}
      <TextField
        fill={fill}
        name={name}
        newDesign={newDesign}
        label={!values[name].length ? label : undefined}
        classNames={{ container: 'array-field__input' }}
        value={newItem}
        onChange={setNewItem}
        placeholder={placeholder}
        disabled={disabled}
        styles={{ container: styles.input, label: !values[name].length ? styles.label : {} }}
        clearButton={false}
        showErrorIcon={false}
        fieldError={errors[name]}
        fieldTouched={touched[name]}
        readOnly={readOnly}
        dense={false}
        isRequired={isRequired}
        rightElement={(
          <div className="array-field__input-icon">
            <Icon
              color="var(--blue70)"
              icon="add"
              size={20}
              onClick={() => push(newItem)}
            />
          </div>
        )}
        {...props}
      />
    </div>
  );
};
