import React, { useEffect, useMemo, useState } from 'react';

import { useScreen } from 'hooks';
import Table from 'components/Table';
import { TWO_FACTOR_COLUMNS } from 'consts/columns';
import { CustomColumnsView } from 'components/blocks';
import Modal from 'components/Modal';
import {
  useSendersApi,
  useIntegrationsApi,
  useDictionariesApi,
} from 'hooks/api';
import { ConfirmModal } from 'components/modals';

import StatisticBlock from './StatisticBlock';
import IntegrationForm from './IntegrationForm';
import styles from './TwoFactorSmsScreen.module.scss';

const TwoFactorSmsScreen = ({ screenRef }) => {
  const [statisticRecord, setStatisticRecord] = useState(undefined);
  const {
    integrations,
    integrationsMeta,
    getIntegrations,
    deleteIntegration,
    getIntegrationById,
    getIntegrationsUrls,
    postIntegration,
    putIntegration,
    isPendingGetIntegrations,
    isPendingDeleteIntegration,
    isPendingPostIntegration,
    isPendingPutIntegration,
    isPendingGetIntegrationById,
    lastUpdatedGetIntegrations,
  } = useIntegrationsApi();
  const { getCompaniesDictionary } = useDictionariesApi();
  const { getSenders, senders } = useSendersApi();

  const {
    editableRecord,
    setEditableRecord,
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    openModal,
    onCloseModal,
    isOpenModal,
  } = useScreen({
    screenRef,
    getFunc: getIntegrations,
    deleteFunc: deleteIntegration,
    onRowClickCallback: data => ({
      ...data,
      enabled: !data.enabled,
    }),
  });

  const sendersOptions = useMemo(() => senders.filter(sender => sender.channel === 1 && sender.status === 2).map(sender => ({
    value: sender.senderId,
    label: sender.senderId,
    companyId: sender.companyId,
  })), [senders]);

  const onOpenStatistic = (data) => {
    setStatisticRecord(data);
  };
  const onEdit = (data) => {
    getIntegrationById({
      id: data.id,
      successCallback: ({ senderConfig = {}, ...res }) => {
        setEditableRecord({
          ...data,
          ...res,
          ...senderConfig,
          integrationType: senderConfig.codeSize ? '2fa-sms-verify' : '2fa-sms-send',
        });
        openModal('form');
      },
      errorCallback: onCloseModal,
    });
  };

  const onSubmit = (values) => {
    (editableRecord ? putIntegration : postIntegration)({
      method: values?.integrationType,
      body: values?.body,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  const columns = useMemo(() => [
    ...TWO_FACTOR_COLUMNS,
    {
      headerName: 'INSTANCES.ACTIONS',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererActions',
      width: 90,
      minWidth: 90,
      pinned: 'right',
      resizable: false,
      sortable: false,
      cellRendererParams: {
        buttons: [
          {
            id: 'edit',
            icon: 'edit',
            title: 'CONTROLS.EDIT',
            onClick: onEdit,
          },
          {
            id: 'statistic',
            icon: 'grouped-bar-chart',
            title: 'SCREENS.TWO_FACTOR.INTEGRATION_STATISTIC',
            onClick: onOpenStatistic,
          },
        ],
      },
      lockVisible: true,
    },
  ], []);

  const getRowHeight = () => 40;

  useEffect(() => {
    getCompaniesDictionary();
    getSenders({
      limit: 1000,
    });
    getIntegrationsUrls();
  }, []);

  if (statisticRecord) {
    return (
      <StatisticBlock
        {...statisticRecord}
        setStatisticRecord={setStatisticRecord}
      />
    );
  }

  return (
    <div>
      <Table
        tableRef={tableRef}
        rowData={integrations}
        columnDefs={columns}
        limit={limit}
        page={offset}
        onChangeLimit={setLimit}
        onChangePage={setOffset}
        tableHeader="TABS.2FA_SMS"
        getRowHeight={getRowHeight}
        total={integrationsMeta.size}
        isPending={isPendingGetIntegrations || !lastUpdatedGetIntegrations}
        buttonText="CONTROLS.TWO_FACTOR.ADD"
        openForm={() => openModal('add')}
        filteredColumns={filteredColumns}
        openCustomColumns={openCustomViewDrawer}
        openCustomViewDrawer={openCustomViewDrawer}
        sendQuery={sendQuery}
      />
      {isOpenCustomViewDrawer && (
        <CustomColumnsView
          initialValues={filteredColumns}
          columnsNames={TWO_FACTOR_COLUMNS}
          onSubmit={onSaveCustomView}
          onClose={closeCustomViewDrawer}
          isOpen={isOpenCustomViewDrawer}
        />
      )}
      {isOpenModal && (
        <Modal
          closeModal={onCloseModal}
          title={`SCREENS.TWO_FACTOR.${editableRecord ? 'EDIT' : 'ADD'}_INTEGRATION`}
          editableRecord={editableRecord}
          className={styles.modal}
        >
          <IntegrationForm
            initialValues={editableRecord}
            onCancel={onCloseModal}
            onDelete={onDeleteClick}
            onSubmit={onSubmit}
            isEdit={!!editableRecord}
            sendersOptions={sendersOptions}
            isPending={isPendingGetIntegrationById || isPendingDeleteIntegration
              || isPendingPostIntegration || isPendingPutIntegration}
          />
        </Modal>
      )}
      {isOpenConfirm && (
        <ConfirmModal
          isDelete={isOpenConfirm}
          closeModal={closeConfirm}
          onConfirm={onDeleteConfirm}
        />
      )}
    </div>
  );
};

export default TwoFactorSmsScreen;
