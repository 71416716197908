import React from 'react';

import { NoUserIcon } from 'assets/icons';
import { Typography } from 'components';
import Button from '@setproduct-ui/core/Button';

import './style.scss';

export default ({
  id,
  name,
  type,
  phone,
  email,
  onMorePress,
}) => (
  <div key={id} className="contact-person">
    <div className="contact-person__contact-block-container">
      <NoUserIcon className="contact-person__contact-avatar" />
      <div className="contact-person__contact-info">
        <Typography small>{name}</Typography>
        <Typography type="caption" colorStep={60}>{type}</Typography>
      </div>
    </div>
    <div className="contact-person__contact-block-container">
      <div className="contact-person__contact-info contact-person__contact-info-end">
        <Typography small color="primary" colorStep={60}>{phone}</Typography>
        <Typography type="caption" colorStep={60}>{email}</Typography>
      </div>
      <Button
        type="icon"
        view="flat"
        color="default"
        icon="more"
        onClick={onMorePress}
      />
    </div>
  </div>
);
