import { Formik, Form, Field } from 'formik';
import { useClickAway } from 'react-use';
import React, { useRef, useState } from 'react';

import { Typography } from 'components';
import { TextAreaField, TextField } from 'components/fields';
import Table from 'components/Table';
import Chips from '@setproduct-ui/core/Chips';
import ProgressBar from '@setproduct-ui/core/ProgressBar';
import Button from '@setproduct-ui/core/Button';
import { CLIENT_PROFILE_INVOICES } from 'consts/columns';

import './style.scss';

const invoicesData = [
  {
    id: 1, numberDocument: 492, getDate: '21.09.2021', payDate: '01.01.2022', paySum: 984, status: 2,
  },
  {
    id: 2, numberDocument: 343, getDate: '10.04.2021', payDate: '04.03.2021', paySum: 396, status: 2,
  },
  {
    id: 3, numberDocument: 947, getDate: '31.08.2021', payDate: '21.06.2021', paySum: 662, status: 4,
  },
  {
    id: 4, numberDocument: 265, getDate: '10.11.2021', payDate: '22.03.2021', paySum: 358, status: 1,
  },
  {
    id: 5, numberDocument: 100, getDate: '15.08.2021', payDate: '28.07.2021', paySum: 255, status: 2,
  },
  {
    id: 6, numberDocument: 530, getDate: '23.06.2021', payDate: '13.06.2021', paySum: 202, status: 1,
  },
  {
    id: 7, numberDocument: 228, getDate: '19.09.2021', payDate: '16.10.2021', paySum: 972, status: 4,
  },
  {
    id: 8, numberDocument: 154, getDate: '05.11.2021', payDate: '20.09.2021', paySum: 191, status: 2,
  },
  {
    id: 9, numberDocument: 637, getDate: '06.05.2021', payDate: '17.02.2021', paySum: 295, status: 4,
  },
  {
    id: 10, numberDocument: 521, getDate: '04.05.2021', payDate: '02.09.2021', paySum: 845, status: 2,
  },
];

export default () => {
  const ref = useRef(null);
  const [isDropdownShow, setIsDropdownShow] = useState(false);

  useClickAway(ref, () => {
    setIsDropdownShow(false);
  });

  return (
    <div className="finance">
      <div className="finance__header-blocks">
        <div className="finance__header-blocks__item finance__header-blocks__item_balance">
          <Typography small colorStep={40} className="finance__header-blocks__item-title">Общий баланс</Typography>
          <div className="finance__row">
            <Typography className="finance__header-blocks__item-sum" type="h4">3 456</Typography>
            <Typography className="finance__header-blocks__item-currency">₽</Typography>
          </div>
        </div>
        <div className="finance__header-blocks__item finance__header-blocks__item_account-balance">
          <Typography small colorStep={40} className="finance__header-blocks__item-title">Баланс аккаунта</Typography>
          <div className="finance__row">
            <Typography className="finance__header-blocks__item-sum" type="h4">9 567</Typography>
            <Typography className="finance__header-blocks__item-currency">₽</Typography>
          </div>
        </div>
        <div className="finance__header-blocks__item finance__header-blocks__item_credit">
          <Typography small colorStep={40} className="finance__header-blocks__item-title_inactive">Кредитная линия</Typography>
          <div className="finance__row finance__row_between">
            <div className="finance__row">
              <Typography className="finance__header-blocks__item-sum finance__header-blocks__item-sum_inactive" type="h4">100 000</Typography>
              <Typography className="finance__header-blocks__item-currency finance__header-blocks__item-currency_inactive">₽</Typography>
            </div>
            <div className="finance__increase" ref={ref}>
              <div
                role="presentation"
                onClick={() => setIsDropdownShow(!isDropdownShow)}
              >
                <Chips
                  type="def"
                  color="success"
                  tag="Увеличить"
                  className="finance__increase__button"
                />
              </div>
              {isDropdownShow && (
                <Formik
                  initialValues={{}}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({
                    handleSubmit,
                  }) => (
                    <Form className="finance__increase__dropdown">
                      <Typography className="finance__increase__dropdown-title">Increase credit line</Typography>
                      <div className="finance__increase__dropdown-content">
                        <Field
                          name="amount"
                          label="Amount"
                          component={TextField}
                          placeholder="PLACEHOLDERS.ACCOUNTS.BANK_NAME"
                          classNames={{ label: 'finance__increase__amount-label' }}
                          view="raised"
                          fill
                        />
                      </div>
                      <div className="finance__increase__dropdown-controls">
                        <Button
                          type="default"
                          view="outlined"
                          color="success"
                          text="Cancel"
                          onClick={() => setIsDropdownShow(false)}
                          dense
                        />
                        <Button
                          type="default"
                          view="filled"
                          color="success"
                          text="Increase"
                          onClick={handleSubmit}
                          dense
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="finance__rates-blocks">
        <div className="finance__rate-plan">
          <div className="finance__rate-plan__header">
            <Typography small colorStep={40} className="finance__rate-plan__header-title">Тариф</Typography>
            <Button
              type="default"
              view="flat"
              color="success_alt"
              dense
              text="Все"
              rightIcon="chevron-right"
              className="finance__rate-plan__header-button"
            />
          </div>
          <div className="finance__rate-plan__content">
            <Typography>Default SMS rate plan</Typography>
            <ProgressBar
              type="def"
              view="filled"
              color="success_alt"
              className="finance__rate-plan__progress-bar"
              animate={false}
              stripes={false}
              value="0.2"
            />
          </div>
          <div className="finance__rate-plan__footer">
            <div className="finance__row">
              <Typography small>500</Typography>
              &nbsp;
              <Typography small colorStep={40}> из 1000</Typography>
              &nbsp;
              <Typography small>- 50 коп.</Typography>
            </div>
            <Typography small>02.11.2021</Typography>
          </div>
        </div>
        <div className="finance__promotion">
          <div className="finance__rate-plan__header">
            <Typography small colorStep={40} className="finance__rate-plan__header-title">Акция до 12.12.2021</Typography>
            <Button
              type="default"
              view="flat"
              color="warning"
              dense
              text="Все"
              rightIcon="chevron-right"
              className="finance__rate-plan__header-button"
            />
          </div>
          <div className="finance__rate-plan__content">
            <Typography>Default SMS rate plan 2</Typography>
            <ProgressBar
              type="def"
              view="filled"
              color="warning"
              className="finance__rate-plan__progress-bar"
              animate={false}
              stripes={false}
              value="0.5"
            />
          </div>
          <div className="finance__rate-plan__footer">
            <div className="finance__row">
              <Typography small>500</Typography>
              &nbsp;
              <Typography small colorStep={40}> из 1000</Typography>
              &nbsp;
              <Typography small>- 50 коп.</Typography>
            </div>
            <Typography small>02.11.2021</Typography>
          </div>
        </div>
      </div>
      <div className="finance__invoices-table__wrapper">
        <Typography large className="finance__invoices-table__title">INSTANCES.CLIENT_PROFILE.INVOICES</Typography>
        <Table
          className="finance__invoices-table"
          classNames={{ screenHeader: 'finance__invoices-table__header', table: 'finance__invoices-table__table' }}
          showFilters={false}
          showColumns={false}
          columnDefs={CLIENT_PROFILE_INVOICES}
          rowData={invoicesData}
          rowHeight={40}
          denseRowHeight={40}
          total={10}
          rowBuffer={5}
          rowClassRules={{
            'finance__invoices-table-item_danger': params => params.data.status === 2,
            'finance__invoices-table-item_primary': params => params.data.status === 3,
            'finance__invoices-table-item_disabled': params => params.data.status === 4,
          }}
          newDesign
        />
      </div>
      <div className="finance__fines">
        <Typography large className="finance__fines__title">INSTANCES.CLIENT_PROFILE.FINES</Typography>
        <TextAreaField
          classNames={{ input: 'finance__fines__textarea' }}
          value="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
        />
      </div>
    </div>
  );
};
