import { isEmpty } from 'lodash';
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RunReportBlock, CreateReportBlock } from 'components/blocks';
import { useReportsApi } from 'hooks/api';
import { useModalLogic } from 'hooks';
import { ConfirmModal } from 'components/modals';

import './style.scss';

const ReportsScreen = ({ screenRef }) => {
  const {
    groups,
    reports,
    reportParameters,
    getGroups,
    getReport,
    getParameters,
    postNewReport,
    postCreateReport,
    putReport,
    removeReport,
    isPendingPostCreateReport,
    deleteReport,
    // isPendingDeleteReport,
  } = useReportsApi();

  const tabRef = useRef();
  const childrenValuesRef = useRef(null);
  const formValuesRef = useRef(null);
  const reportFormValuesRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeTab: activeRouteTab } = useSelector(state => state.states.routes);

  const [
    isOpenConfirm,
    { openModal: openConfirm, closeModal: closeConfirm },
  ] = useModalLogic('');

  const [activeTab, setActiveTab] = useState('control');
  const [editableReport, setEditableReport] = useState({});
  const [isForm, setIsForm] = useState(false);
  const [internalReports, setInternalReports] = useState('client');
  const [parameters, setParameters] = useState([]);

  useImperativeHandle(screenRef, () => ({
    saveState() {
      dispatch({
        type: 'saveRoute',
        art: activeTab,
        erv: editableReport,
        ifm: isForm,
        irv: internalReports,
        fv: formValuesRef.current,
        cv: childrenValuesRef.current,
        rfv: reportFormValuesRef.current,
        p: parameters,
      });
    },
  }));

  const reportInitialValues = useMemo(() => {
    if (history.location.state) {
      const data = JSON.parse(history.location.state.data);

      return data.rfv;
    }

    return null;
  }, [history.length, history.location.state]);

  const onSelectReport = (templateId) => {
    getParameters({
      templateId,
    });
  };

  const onCloseReport = (reportName) => {
    if (activeTab !== reportName) {
      setActiveTab(reportName);
    }
    tabRef.current = true;
    removeReport(reportName);
    setActiveTab('control');
  };

  const onEditReport = ({ selectedSubsection }) => {
    getReport({
      id: selectedSubsection.id,
      successCallback: ({ result }) => {
        const params = result.parameters.map(I => ({
          ...I,
        }));
        const selectedReport = {
          ...result,
          parameters: params,
        };
        setEditableReport(selectedReport);
        setIsForm(true);
        getGroups();
      },
    });
  };

  const onDeleteReport = () => {
    if (editableReport?.id) {
      openConfirm('delete');
    }
  };

  const onDeleteConfirm = () => {
    deleteReport({
      id: editableReport?.id,
      successCallback: () => {
        setIsForm(false);
        setEditableReport({});
      },
    });
    closeConfirm();
  };

  const onNewReport = () => {
    setEditableReport({});
    setIsForm(true);
  };

  const onSubmit = (body) => {
    (isEmpty(editableReport) ? postNewReport : putReport)({
      body: {
        ...body,
      },
      successCallback: () => {
        getGroups();
        if (!isEmpty(editableReport)) {
          getParameters({
            templateId: editableReport.id,
          });
        }
      },
    });
  };

  const handleChangeTab = (tab) => {
    if (activeTab !== tab && !tabRef.current) {
      setActiveTab(tab);
    } else if (activeTab !== tab && tabRef.current) {
      tabRef.current = false;
      setActiveTab('removed');
    }
  };

  useEffect(() => {
    if (activeTab === 'removed') {
      setActiveTab('control');
    }
  }, [activeTab]);
  useEffect(() => {
    if (activeRouteTab) {
      dispatch({
        type: 'saveRoute',
        art: activeTab,
      });
    }
  }, [activeTab]);
  useEffect(() => {
    if (activeRouteTab) {
      dispatch({
        type: 'saveRoute',
        erv: editableReport,
      });
    }
  }, [editableReport]);
  useEffect(() => {
    if (activeRouteTab) {
      dispatch({
        type: 'saveRoute',
        ifm: isForm,
      });
    }
  }, [isForm]);
  useEffect(() => {
    if (activeRouteTab) {
      dispatch({
        type: 'saveRoute',
        irv: internalReports,
      });
    }
  }, [internalReports]);
  useEffect(() => {
    if (activeRouteTab) {
      dispatch({
        type: 'saveRoute',
        p: parameters,
      });
    }
  }, [parameters]);
  useEffect(() => {
    if (history.location.state) {
      const data = JSON.parse(history.location.state.data);
      const {
        art,
        erv,
        ifm,
        irv,
        p,
      } = data;

      setActiveTab(art);
      setEditableReport(erv);
      setIsForm(ifm);
      setInternalReports(irv);
      setParameters(p || []);
    } else {
      setActiveTab('control');
      setEditableReport({});
      setIsForm(false);
      setInternalReports('client');
      setParameters([]);
    }
  }, [history.length, history.location.state]);

  return (
    <div className="reports-layout">
      {
        isForm ? (
          <CreateReportBlock
            setIsForm={setIsForm}
            onSubmit={onSubmit}
            editableReport={editableReport}
            reportFormValuesRef={reportFormValuesRef}
            reportInitialValues={reportInitialValues}
            parameters={parameters}
            setParameters={setParameters}
            onDeleteReport={onDeleteReport}
          />
        ) : (
          <RunReportBlock
            list={groups}
            reports={reports}
            getGroups={getGroups}
            onSelectReport={onSelectReport}
            reportParameters={reportParameters}
            editableReport={editableReport}
            onEditReport={onEditReport}
            onNewReport={onNewReport}
            postCreateReport={postCreateReport}
            onClose={onCloseReport}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleChangeTab={handleChangeTab}
            isPendingPostCreateReport={isPendingPostCreateReport}
            internalReports={internalReports}
            setInternalReports={setInternalReports}
            childrenValuesRef={childrenValuesRef}
            history={history}
            formValuesRef={formValuesRef}
          />
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm}
            closeModal={closeConfirm}
            onConfirm={onDeleteConfirm}
          />
        )
      }
    </div>
  );
};

export default ReportsScreen;
