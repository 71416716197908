import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import routes from 'routes/routes';

import styles from './SectionCellRenderer.module.scss';

export default ({ node, value }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const sectionsOptions = useMemo(() => routes.reduce((acc, parent) => {
    acc.push({
      value: parent.path,
      label: t(parent.name),
    });
    parent?.children?.map(child => acc.push({
      value: child.path,
      label: t(child.name),
    }));
    return acc;
  }, []), [routes]);

  const targetSection = useMemo(
    () =>
      sectionsOptions.find(I => I.value === value),
    [sectionsOptions],
  );

  const onClick = () => {
    node.gridOptionsWrapper.gridOptions.isPropagationStopped = true;
    setTimeout(() => {
      node.gridOptionsWrapper.gridOptions.isPropagationStopped = false;
    });
    history.push({
      pathname: targetSection.value,
    });
  };

  return (
    <div
      role="presentation"
      className={styles.link}
      onClick={onClick}
    >
      {targetSection.label}
    </div>
  );
};
