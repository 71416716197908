import { Typography } from 'components';
import { PdfIcon } from 'assets/icons';

import moment from 'moment';
import React from 'react';

import './style.scss';
import { Icon } from '@blueprintjs/core';

export default ({
  data,
}) => (
  <div className="accordion">
    {data.map(I => (
      <div className="accordion__item-container" key={I.id}>
        <input disabled={!I.files.length} className="accordion__checkbox" type="checkbox" id={I.id} />
        <label className="accordion__item" htmlFor={I.id}>
          <div className="accordion__item__left">
            <PdfIcon className="accordion__item__icon" />
            <Typography small className="accordion__item__title">{I.fileName}</Typography>
          </div>
          <div className="accordion__item__right">
            <Typography small className="accordion__item__date">{moment(I.created).format('DD.MM.YYYY')}</Typography>
            <Icon icon="chevron-up" size={20} className="accordion__item__chevron" />
          </div>
        </label>
        {!!I.files.length && (
          <div className="accordion__item__content">
            {I.files.map(F => (
              <div className="accordion__sub-item">
                <div className="accordion__item__left">
                  <PdfIcon className="accordion__sub-item__icon" />
                  <Typography small className="accordion__sub-item__title">{F.fileName}</Typography>
                </div>
                <div className="accordion__item__right">
                  <Typography small className="accordion__item__date">{moment(F.created).format('DD.MM.YYYY')}</Typography>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
);
