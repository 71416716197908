import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Button from '@setproduct-ui/core/Button';
import {
  INVOICE_DIRECTIONS,
  INVOICE_STATUSES,
  INVOICE_DISPATCH_STATUSES,
  INVOICE_DISPUTE_STATUSES,
  INVOICE_PAYMENT_STATUSES,
} from 'consts';
import { CHARGES } from 'consts/columns';
import { round } from 'helpers';
import {
  useAgreementsApi, useChargesApi, useDictionariesApi, useInvoicesApi,
} from 'hooks/api';
import { useModalLogic, useTable, useToken } from 'hooks';
import DatePickerField from 'components/fields/DatePickerField';
import TextField from 'components/fields/TextField';
import toastRef from 'helpers/toast';
import { AgreementsForm } from 'components/forms';
import endpoints from 'consts/endpoints';
import Modal from 'components/Modal';
import MenuItem from '@setproduct-ui/core/Menu/MenuItem';
import { ConfirmModal } from 'components/modals';

import NotesBlock from './NotesBlock';

import ButtonDropdown from '../ButtonDropdown';
import DetailCell from '../DetailCell';
import PopoverEditBlock from '../PopoverEditBlock';
import DetailsBlock from '../DetailsBlock';

import './style.scss';

const InvoiceDetails = ({
  onRowDoubleClicked,
  id,
  agreementId,
  direction,
  documentType,
  periodStart,
  periodEnd,
  periodStartTz,
  periodEndTz,
  netAmount,
  taxAmount,
  totalAmount,
  dueAmount,
  status,
  referenceCode,
  issueDate,
  dispatchStatus,
  disputeStatus,
  disputedAmount,
  paymentStatus,
  dueDate,
  coveredAmount,
  outstandingAmount,
  companyName,
  agreementCode,
  currency,
  modifyTabData,
  isConfirmed,
  tabName,
  tabData,
}) => {
  const { t } = useTranslation();
  const formValuesRef = useRef(null);
  const isFormPristine = useRef(true);
  const [selectedVersionId, setSelectedVersionId] = useState(0);
  const [charges, setCharges] = useState([]);
  const [editableRecord, setEditableRecord] = useState({});
  const [chargesMeta, setChargesMeta] = useState({});
  const [awaitedTasks, setAwaitedTasks] = useState([]);
  const [isOpenModal, { openModal, closeModal }] = useModalLogic('');
  const [isOpenConfirm, { openModal: openConfirm, closeModal: closeConfirm }] = useModalLogic('');
  const intervalId = useRef(null);
  const { token } = useToken();
  const {
    getCharges,
  } = useChargesApi();
  const {
    patchInvoice,
    getDownloadInvoice,
    isPendingPatchInvoice,
  } = useInvoicesApi();
  const {
    getAgreements,
    deleteAgreementVersion,
    deleteAgreement,
    isPendingDeleteAgreement,
    isPutAgreementVersion,
    isPendingPostAgreementVersion,
    isPendingDeleteAgreementVersion,
    isPendingGetAgreements,
    postAgreementVersion,
    putAgreementVersion,
    postAgreement,
  } = useAgreementsApi();
  const {
    timezones,
    currencies,
    legalEntitiesOptions,
    ownLegalEntitiesOptions,
    companiesOptions,
    getResourcesDictionary,
  } = useDictionariesApi();

  const timezonesOptions = useMemo(() => timezones
    .filter((v, i, a) => a.findIndex(I => (I.areaName === v.areaName)) === i)
    .map(I => ({
      value: I.areaName,
      label: I.label,
    })), [timezones]);
  const getChargesModify = (props) => {
    getCharges({
      filter: `{"condition":"AND","expressions":[{"field":"invoiceId","operator":"eq","value":"${id}"}]}`,
      successCallback: ({ data, meta }) => {
        setCharges(data);
        setChargesMeta(meta);
      },
      ...props,
    });
  };
  const {
    sendQuery,
    setAndSendLimit,
    setAndSendOffset,
    limit,
    offset,
    onSortChanged,
    onFilterChanged,
  } = useTable({
    sendQuery: getChargesModify,
  });
  const [newIssueDate, setNewIssueDate] = useState(issueDate ? moment(issueDate).toDate() : undefined);
  const [newDueDate, setNewDueDate] = useState(dueDate ? moment(dueDate).toDate() : undefined);
  const [newReferenceCode, setNewReferenceCode] = useState(referenceCode);

  const onDownloadClick = (postfix) => {
    getDownloadInvoice({
      id,
      postfix,
      successCallback: ({ data }) => {
        const isTaskAlreadyExist = awaitedTasks.some(task =>
          task.taskId === data.taskId && task.withDetails === data.withDetails);

        toastRef.current.show({
          message: isTaskAlreadyExist
            ? 'The task has already been created. Please wait for the download to start automatically.'
            : 'The task has been created. The download will start as soon as the file is ready. Please stay on screen.',
          intent: isTaskAlreadyExist ? 'warning' : 'success',
        });
        if (!isTaskAlreadyExist) {
          setAwaitedTasks(prev => ([
            ...prev,
            data,
          ]));
        }
      },
    });
  };

  const onAgreementLinkClick = () => {
    getAgreements({
      'id[of]': agreementId,
      successCallback: ({ resourceList }) => {
        if (resourceList.length) {
          setEditableRecord(resourceList[0]);
          openModal('view');
        } else {
          toastRef?.current?.show({ message: t('TOASTS.AGREEMENT_NOT_EXIST') });
        }
      },
    });
  };

  const headerCells = [
    {
      title: 'INSTANCES.ID',
      value: id,
      parentId: id,
    },
    {
      title: 'INSTANCES.COMPANY',
      value: companyName,
      id: undefined,
      parentId: id,
    },
    {
      title: 'INSTANCES.AGREEMENT',
      value: agreementCode,
      id: agreementId,
      onClick: onAgreementLinkClick,
      parentId: id,
    },
    {
      title: 'INSTANCES.DIRECTION',
      value: t(INVOICE_DIRECTIONS[direction]?.label),
      icon: INVOICE_DIRECTIONS[direction]?.icon,
      iconColor: INVOICE_DIRECTIONS[direction]?.iconColor,
      parentId: id,
    },
  ];
  const footerCells = [
    {
      title: 'INSTANCES.SYSTEM_NET',
      value: round({ num: netAmount }),
      label: currency,
      isNumber: true,
    },
    {
      title: 'INSTANCES.SYSTEM_TAX',
      value: round({ num: taxAmount }),
      label: currency,
      isNumber: true,
    },
    {
      title: 'INSTANCES.SYSTEM_TOTAL',
      value: round({ num: totalAmount }),
      label: currency,
      isNumber: true,
    },
    {
      title: 'INSTANCES.TOTAL_DUE',
      value: round({ num: dueAmount }),
      label: currency,
      isNumber: true,
      isBold: true,
    },
  ];
  const downloadOptions = [
    {
      label: 'CONTROLS.INVOICES.INVOICE',
      onClick: () => onDownloadClick('file'),
    },
    {
      label: 'CONTROLS.INVOICES.INVOICE_CHARGE_DETAILS',
      onClick: () => onDownloadClick('detailed-file'),
    },
  ];
  const confirmOptions = [
    // {
    //   label: 'CONTROLS.INVOICES.CONFIRM_SEND',
    //   onClick: Function.prototype,
    //   icon: 'envelope',
    // },
    {
      label: 'CONTROLS.INVOICES.CONFIRM_WITHOUT_SEND',
      onClick: () => {
        patchInvoice({
          id,
          postfix: 'confirm',
          successCallback: ({ data }) => {
            modifyTabData(data);
            sendQuery();
          },
        });
      },
      icon: 'tick-circle',
    },
  ];

  const onDowDoubleClickedModify = ({ data }) => {
    onRowDoubleClicked({
      data: {
        invoiceStatus: status,
        invoiceReferenceCode: referenceCode,
        updateChargesList: sendQuery,
        invoiceData: tabData,
        ...data,
      },
    });
  };
  const onTotalDueCellClick = (data) => {
    onDowDoubleClickedModify({ data });
  };
  const onSaveIssueDate = () => {
    patchInvoice({
      id,
      postfix: 'issue-date',
      body: {
        issueDate: `${moment(newIssueDate).format('YYYY-MM-DDTHH:mm:ss')}Z`,
      },
      successCallback: ({ data }) => {
        setNewIssueDate(data.issueDate ? moment(data.issueDate).toDate() : undefined);
        modifyTabData(data);
      },
    });
  };
  const onCloseIssuePopover = () => setNewIssueDate(issueDate ? moment(issueDate).toDate() : undefined);
  const onSaveReferenceCode = () => {
    patchInvoice({
      id,
      postfix: 'reference-code',
      body: {
        referenceCode: newReferenceCode,
      },
      successCallback: ({ data }) => {
        setNewReferenceCode(data.referenceCode);
        modifyTabData(data);
      },
    });
  };
  const onDeleteAgreementVersionClick = () => {
    openConfirm('delete-agreement-version');
  };
  const onDeleteAgreementClick = () => {
    openConfirm('delete-agreement');
  };
  const onCloseModal = () => {
    closeModal();
  };
  const onDeleteAgreementConfirm = () => {
    closeConfirm();
    deleteAgreement({
      id: editableRecord.id,
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  const onDeleteAgreementVersionConfirm = () => {
    if (selectedVersionId) {
      closeConfirm();
      deleteAgreementVersion({
        id: selectedVersionId,
        successCallback: () => {
          sendQuery();
          onCloseModal({ isSave: true });
        },
      });
    }
  };
  const onConfirmClose = () => {
    setEditableRecord();
    closeConfirm();
    closeModal();
    if (formValuesRef.current) {
      formValuesRef.current = null;
    }
  };
  const onSubmit = (values, isNewVersion = false) => {
    (editableRecord ? isNewVersion ? postAgreementVersion : putAgreementVersion : postAgreement)({
      body: {
        ...values,
      },
      successCallback: () => {
        sendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: ({ error, key } = {}) => {
        toastRef?.current?.showMessage({ message: key || error, intent: 'danger' });
      },
    });
  };
  const onCloseReferencePopover = () => setNewReferenceCode(referenceCode);
  const onSaveDueDate = () => {
    patchInvoice({
      id,
      postfix: 'due-date',
      body: {
        dueDate: `${moment(newDueDate).format('YYYY-MM-DDTHH:mm:ss')}Z`,
      },
      successCallback: ({ data }) => {
        setNewDueDate(data.dueDate ? moment(data.dueDate).toDate() : undefined);
        modifyTabData(data);
      },
    });
  };
  const onCloseDuePopover = () => setNewDueDate(newDueDate);
  const onRecalculate = () => {
    patchInvoice({
      id,
      postfix: 'refresh',
      successCallback: ({ data }) => {
        modifyTabData(data);
        sendQuery();
      },
    });
  };
  const onCancelConfirmation = () => {
    patchInvoice({
      id,
      postfix: 'cancel-confirmation',
      successCallback: ({ data }) => {
        modifyTabData(data);
        sendQuery();
      },
    });
  };
  const clearQueriesInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = undefined;
    }
  };

  useEffect(() => {
    clearQueriesInterval();
    if (awaitedTasks.length) {
      const sendRequests = () => {
        awaitedTasks.forEach(({ taskId, ...task }) => {
          fetch(endpoints.getFileTaskUrl(taskId, task), {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.headers.get('content-type') === 'application/json') {
                  return response.json();
                }
                return response.blob();
              }

              throw `${response.status} (${response.statusText})`;
            })
            .then((response) => {
              if (response.size && response.type) {
                const objectURL = URL.createObjectURL(response);
                const a = document.createElement('a');
                a.href = objectURL;
                a.download = tabName?.replace('.', ' ');
                document.body.appendChild(a);
                a.click();
                a.remove();

                setAwaitedTasks((prev) => {
                  const filteredTasks = prev.filter(I =>
                    (I.taskId !== taskId && I.withDetails !== task.withDetails));

                  if (!filteredTasks.length) {
                    clearQueriesInterval();
                  }

                  return filteredTasks;
                });
              } else if (taskId !== response.data.taskId) {
                setAwaitedTasks(prev => prev.map(I => ({
                  ...I,
                  taskId: response.data.taskId,
                })));
              }
            });
        });
      };

      setTimeout(sendRequests, 1500);
      intervalId.current = setInterval(sendRequests, 10000);
    }
  }, [awaitedTasks.length, token]);

  useEffect(() => {
    clearQueriesInterval();
    getResourcesDictionary();
  }, []);

  return (
    <>
      <DetailsBlock
        headerCells={headerCells}
        footerCells={footerCells}
        detailsBody={(
          <>
            <div className="invoice-details__column">
              <DetailCell
                parentId={id}
                title="INSTANCES.TYPE"
                value={t(`STATES.INVOICES.${documentType === 0 ? 'INVOICE' : 'CREDIT_NOTE'}`)}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.START_PERIOD"
                value={moment(periodStart).format('DD.MM.YYYY')}
                label={periodStartTz}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.END_PERIOD"
                value={moment(periodEnd).format('DD.MM.YYYY')}
                label={periodEndTz}
              />
            </div>
            <div className="invoice-details__column">
              <DetailCell
                parentId={id}
                title="INSTANCES.STATUS"
                value={t(INVOICE_STATUSES[status]?.label)}
                icon={INVOICE_STATUSES[status]?.icon}
                iconColor={INVOICE_STATUSES[status]?.iconColor}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.INVOICES.REFERENCE"
                value={referenceCode}
                editControl={!isConfirmed && (
                  <PopoverEditBlock
                    title="SCREENS.INVOICES.SET_REFERENCE"
                    onClose={onCloseReferencePopover}
                    onSave={onSaveReferenceCode}
                    disabled={!newReferenceCode}
                  >
                    <TextField
                      value={newReferenceCode}
                      onChange={setNewReferenceCode}
                      label="INSTANCES.INVOICES.REFERENCE"
                      placeholder="PLACEHOLDERS.ENTER_VALUE"
                      full
                      dense
                    />
                  </PopoverEditBlock>
                )}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.INVOICES.ISSUE_DATE"
                value={moment(issueDate).format('DD.MM.YYYY')}
                editControl={!isConfirmed && (
                  <PopoverEditBlock
                    title="SCREENS.INVOICES.SET_ISSUE_DATE"
                    onClose={onCloseIssuePopover}
                    onSave={onSaveIssueDate}
                    disabled={!newIssueDate}
                  >
                    <DatePickerField
                      value={newIssueDate}
                      onChange={setNewIssueDate}
                      format="DD.MM.YYYY"
                      label="INSTANCES.INVOICES.ISSUE_DATE"
                      pickerDense
                      dense
                      closeOnChange
                    />
                  </PopoverEditBlock>
                )}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.INVOICES.DISPATCH_STATUS"
                value={t(INVOICE_DISPATCH_STATUSES[dispatchStatus]?.label)}
                icon={INVOICE_DISPATCH_STATUSES[dispatchStatus]?.icon}
                iconColor={INVOICE_DISPATCH_STATUSES[dispatchStatus]?.iconColor}
              />
            </div>
            <div className="invoice-details__column">
              <DetailCell
                parentId={id}
                title="INSTANCES.INVOICES.PAYMENT_STATUS"
                value={t(INVOICE_PAYMENT_STATUSES[paymentStatus]?.label)}
                icon={INVOICE_PAYMENT_STATUSES[paymentStatus]?.icon}
                iconColor={INVOICE_PAYMENT_STATUSES[paymentStatus]?.iconColor}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.INVOICES.DUE_DATE"
                value={moment(dueDate).format('DD.MM.YYYY')}
                editControl={(
                  <PopoverEditBlock
                    title="SCREENS.INVOICES.SET_DUE_DATE"
                    onClose={onCloseDuePopover}
                    onSave={onSaveDueDate}
                    disabled={!newDueDate}
                  >
                    <DatePickerField
                      value={newDueDate}
                      onChange={setNewDueDate}
                      format="DD.MM.YYYY"
                      label="INSTANCES.INVOICES.DUE_DATE"
                      pickerDense
                      dense
                      closeOnChange
                    />
                  </PopoverEditBlock>
              )}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.INVOICES.COVERED_DUE"
                value={round({ num: coveredAmount })}
                label={currency}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.INVOICES.OUTSTANDING_DUE"
                value={round({ num: outstandingAmount })}
                label={currency}
              />
            </div>
            <div className="invoice-details__column" style={{ borderLeft: 'none' }}>
              <DetailCell
                parentId={id}
                title="INSTANCES.DISPUTE_STATUS"
                value={t(INVOICE_DISPUTE_STATUSES[disputeStatus]?.label)}
                icon={INVOICE_DISPUTE_STATUSES[disputeStatus]?.icon}
                iconColor={INVOICE_DISPUTE_STATUSES[disputeStatus]?.iconColor}
              />
              <DetailCell
                parentId={id}
                title="INSTANCES.DISPUTED"
                value={round({ num: disputedAmount })}
                label={currency}
              />
            </div>
          </>
      )}
        footerElement={(
          <>
            <ButtonDropdown
              buttonText="CONTROLS.INVOICES.DOWNLOAD"
              buttonIcon="import"
              options={downloadOptions}
              loading={!!awaitedTasks?.length}
              dataTestId={`${window.location.pathname}/download-button/${id}`}
            />
            <div className="invoice-details__buttons">
              {/* todo: открыть по готовности api */}
              {documentType === 0 && 5 < 2 && (
                <Button
                  onClick={Function.prototype}
                  text="CONTROLS.INVOICES.RESEND"
                  title={t('CONTROLS.INVOICES.RESEND')}
                  color="success"
                  view="smooth"
                  icon="repeat"
                  data-testid={`${window.location.pathname}/resend-button/${id}`}
                />
              )}
              {!isConfirmed && (
                <Button
                  onClick={onRecalculate}
                  text="CONTROLS.INVOICES.RECALCULATE"
                  title={t('CONTROLS.INVOICES.RECALCULATE')}
                  color="primary"
                  view="smooth"
                  icon="calculator"
                  disabled={isPendingPatchInvoice}
                  data-testid={`${window.location.pathname}/recalculate-button/${id}`}
                />
              )}
              {isConfirmed && (
                <Button
                  onClick={onCancelConfirmation}
                  text="CONTROLS.INVOICES.CANCEL_CONFIRM"
                  title={t('CONTROLS.INVOICES.CANCEL_CONFIRM')}
                  color="danger"
                  view="smooth"
                  icon="reset"
                  disabled={isPendingPatchInvoice}
                  data-testid={`${window.location.pathname}/cancel-confirm-button/${id}`}
                />
              )}
              {!isConfirmed && (
                <ButtonDropdown
                  buttonText="CONTROLS.INVOICES.CONFIRM"
                  buttonColor="success"
                  buttonIcon="tick-circle"
                  options={confirmOptions}
                  dataTestId={`${window.location.pathname}/confirm-button/${id}`}
                />
              )}
            </div>
          </>
      )}
        rightContentElement={(
          <div className="invoice-details__notes-wrapper">
            <NotesBlock invoiceId={id} />
          </div>
      )}
        tableProps={{
          columnDefs: CHARGES(onTotalDueCellClick),
          rowData: charges,
          onSortChanged,
          onFilterChanged,
          limit,
          page: offset,
          total: chargesMeta.size,
          onChangePage: setAndSendOffset,
          onChangeLimit: setAndSendLimit,
          sendQuery,
          onRowDoubleClicked: onDowDoubleClickedModify,
        }}
        rowStyle={{
          justifyContent: 'space-between',
        }}
        tableTitle="SCREENS.INVOICES.CHARGES"
      />
      {
        isOpenModal && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.AGREEMENTS.AGREEMENT"
            editableRecord={{
              id: editableRecord?.id,
              createdAt: editableRecord?.agreementCreatedAt,
              updatedAt: editableRecord?.agreementUpdatedAt,
            }}
            contextMenuItems={editableRecord && (
              <MenuItem
                type="dense"
                view="raised"
                color="danger"
                icon="trash"
                text={t('CONTROLS.AGREEMENTS.DELETE_THIS_AGREEMENT')}
                shouldDismissPopover={false}
                onClick={onDeleteAgreementClick}
              />
            )}
          >
            <AgreementsForm
              onSubmit={onSubmit}
              onCancel={onCloseModal}
              partnersOptions={companiesOptions}
              timezonesOptions={timezonesOptions}
              legalEntitiesOptions={legalEntitiesOptions}
              ownLegalEntitiesOptions={ownLegalEntitiesOptions}
              currenciesOptions={currencies}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDeleteAgreementVersion={onDeleteAgreementVersionClick}
              isFormPristine={isFormPristine}
              setSelectedVersionId={setSelectedVersionId}
              isPending={
                isPendingDeleteAgreement
                || isPutAgreementVersion
                || isPendingPostAgreementVersion
                || isPendingDeleteAgreementVersion
                || isPendingGetAgreements
                }
              formValuesRef={formValuesRef}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={
              isOpenConfirm === 'delete-agreement-version'
              || isOpenConfirm === 'delete-agreement'
            }
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete-agreement-version'
                ? onDeleteAgreementVersionConfirm
                : isOpenConfirm === 'delete-agreement'
                  ? onDeleteAgreementConfirm
                  : onConfirmClose
            }
          />
        )
      }
    </>
  );
};

export default InvoiceDetails;
