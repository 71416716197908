import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components';
import { CustomColumnsView } from 'components/blocks';
import { ConfirmModal } from 'components/modals';
import Table from 'components/Table';
import SenderForm from 'components/forms/SenderForm';
import ApplicationForm from 'components/forms/ApplicationForm';
import { SENDER_IDS } from 'consts/columns';
import toastRef from 'helpers/toast';
import { useModalLogic, useScreen } from 'hooks';
import {
  useSendersApi,
  useSmppConnectionsApi,
  useHttpConnectionsApi,
  useFeeRulesApi,
  useWabaApi,
  useDictionariesApi,
} from 'hooks/api';

import SelectChannel from './SelectChannel';
import PeriodicFee from './PeriodicFee';
import SetupFee from './SetupFee';
import ConfirmWriteOffModal from './ConfirmWriteOffModal';
import ConfirmSenderDeleteCheck from './ConfirmSenderDeleteCheck';

export default ({ screenRef }) => {
  const { t } = useTranslation();
  const {
    getAgreementsDictionary,
    getCompaniesDictionary,
  } = useDictionariesApi();
  const {
    getSenders,
    postSender,
    putSender,
    getSenderById,
    isPendingPostSender,
    isPendingPutSender,
    senders,
    sendersMeta,
    isPendingGetSenders,
    lastUpdatedGetSenders,
    deleteSender,
    isPendingDeleteSender,
    senderDeleteCheck,
  } = useSendersApi();
  const {
    openModal,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    editableRecord,
    setEditableRecord,
    formValuesRef,
  } = useScreen({
    screenRef,
    getFunc: getSenders,
    deleteFunc: deleteSender,
  });
  const { postSetupFee } = useFeeRulesApi();
  const {
    getSmppConnections,
    smppConnections,
    getSmppApiConnections,
    smppApiConnections,
  } = useSmppConnectionsApi();
  const { getHttpConnections, httpConnections } = useHttpConnectionsApi();
  const { waba, getWaba } = useWabaApi();
  const [selectedSenderId, setSelectedSenderId] = useState({});

  const smppConnectionsOptions = useMemo(() => smppConnections
    .map(item => ({
      value: item.id,
      label: `${item.name} (${item.enabled ? 'enabled' : 'disabled'})`,
    })), [smppConnections]);
  const smppApiConnectionsOptions = useMemo(() => smppApiConnections
    .map(item => ({
      value: item.id,
      label: `${item.name} (${item.enabled ? 'enabled' : 'disabled'})`,
    })), [smppApiConnections]);
  const httpConnectionsOptions = useMemo(() => httpConnections
    .map(item => ({
      value: item.id,
      label: `${item.name} (${item.enabled ? 'enabled' : 'disabled'})`,
      vendorType: item.vendorType,
    })), [httpConnections]);
  const wabaOptions = useMemo(() => waba
    .map(item => ({
      value: item.wabaId,
      label: item.name,
      companyId: item.companyId,
    })), [waba]);

  const [isOpenSenderCheckModal, { openModal: openSenderCheckModal, closeModal: closeSenderCheckModal }] = useModalLogic('');

  const onCloseModify = (isSave) => {
    setEditableRecord(undefined);
    setSelectedSenderId(null);
    onConfirmClose({ isSave });
  };

  const columns = useMemo(() => ([
    ...SENDER_IDS,
    {
      headerName: 'INSTANCES.ACTIONS',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererActions',
      width: 90,
      minWidth: 90,
      resizable: false,
      sortable: false,
      cellRendererParams: {
        buttons: [
          {
            id: 'periodic-fee',
            icon: 'credit-card',
            onClick: ({ id, displayName, channel }) => {
              setSelectedSenderId({ id, displayName, channel });
              openModal('periodicFee');
            },
            htmlTitle: t('SCREENS.SENDER_IDS.PERIODIC_FEE_RULES'),
          },
          {
            id: 'setup-fee',
            icon: 'bank-account',
            htmlTitle: t('SCREENS.SENDER_IDS.SETUP_FEE'),
            onClick: ({ id, displayName, channel }) => {
              setSelectedSenderId({ id, displayName, channel });
              openModal(channel === 1 ? 'setupFee' : 'write-off-confirm');
            },
          },
        ],
      },
      lockVisible: true,
      pinned: 'right',
    },
  ]), []);

  const onConfirmWriteOff = (isRewrite) => {
    postSetupFee({
      body: {
        senderId: selectedSenderId.id,
        force: isRewrite,
      },
      successCallback: () => {
        toastRef?.current?.showMessage({ message: 'Write-off was successful!', intent: 'success' });
        onCloseModify();
      },
      errorCallback: ({ data, key }) => {
        if (isRewrite) {
          toastRef?.current?.showMessage({ message: key || data?.message, intent: 'danger' });
          onCloseModify();
        } else if (key === 'balance_updater_service.conflict.duplicate') {
          openModal('rewrite-off-confirm');
        } else {
          toastRef?.current?.showMessage({ message: key || data?.message, intent: 'danger' });
          if (openModal) {
            onCloseModify();
          }
        }
      },
    });
  };
  const onRowClicked = ({
    data: {
      channel,
      id,
      status,
      properties,
      vendorConfig,
      clientConfig,
      ...data
    },
  }) => {
    switch (channel) {
      case 1: {
        setEditableRecord({
          id,
          mcc: properties.mcc,
          keyword: properties.keyword,
          senderStatus: status,
          smppApiConnectionId: clientConfig?.connectionId,
          ...data,
          ...vendorConfig,
        });
        break;
      }
      case 2:
        getSenderById({
          id,
          successCallback: (res) => {
            const initialContactArrayValues = [{ value: null, label: null }];

            // NOTE: необходимо для того, чтоб в случае, если в полях лежит null, в форме отобразились поля
            // с пустыми записями
            properties.phone = properties.phone || [...initialContactArrayValues];
            properties.website = properties.website || [...initialContactArrayValues];
            properties.email = properties.email || [...initialContactArrayValues];

            if (res.vendorType === 'alaris_gsma') {
              setEditableRecord({
                id,
                senderStatus: status,
                ...data,
                ...properties,
                ...vendorConfig,
              });
            } else {
              setEditableRecord({
                id,
                senderStatus: status,
                ...data,
                ...properties,
                vendorConfig: vendorConfig ? { ...vendorConfig } : null,
              });
            }
          },
        });
        break;
      case 3: {
        setEditableRecord({
          id,
          senderStatus: status,
          sessionModeOn: properties?.sessionModeOn,
          ...data,
          ...(vendorConfig && vendorConfig),
        });
        break;
      }
      case 4: {
        setEditableRecord({
          id,
          senderStatus: status,
          waba_id: properties.waba_id,
          appId: properties.appId,
          ...data,
          ...vendorConfig,
        });
        break;
      }
      case 7:
        setEditableRecord({
          id,
          senderStatus: status,
          ...data,
          ...(vendorConfig && vendorConfig),
          ...(properties && properties),
        });
        break;
      default:
        setEditableRecord({
          id,
          senderStatus: status,
          ...data,
          ...(vendorConfig && vendorConfig),
        });
        break;
    }

    openModal(channel);
  };
  const onSubmitSender = ({
    vendorType,
    senderStatus,
    serviceType,
    connectionId,
    mediaUrl,
    login,
    password,
    apiKey,
    phoneNumberId,
    accessToken,
    appId,
    smppApiConnectionId,
    appSecret,
    token,
    ...body
  }) => {
    let vendorConfig;
    let clientConfig;
    switch (isOpenModal) {
      case 1:
        clientConfig = {
          connectionId: smppApiConnectionId,
        };
        switch (vendorType) {
          case 'smpp':
            vendorConfig = {
              serviceType,
              connectionId,
            };
            break;
          case 'alaris_http_sms':
            vendorConfig = {
              serviceType,
              login,
              password,
            };
            break;
          default:
            break;
        }
        break;
      case 2:
        vendorConfig = body.vendorConfig;
        break;
      case 3: {
        switch (vendorType) {
          case 'mkit_http_viber':
            vendorConfig = {
              serviceType,
              connectionId,
            };
            break;
          case 'alaris_http_viber':
            vendorConfig = {
              serviceType,
              login,
              password,
            };
            break;
          default:
            break;
        }
        break;
      }
      case 4:
        switch (vendorType) {
          case 'dialog360':
            vendorConfig = {
              apiKey,
              mediaUrl,
            };
            break;
          case 'mkit_http_telin':
            vendorConfig = {
              connectionId,
              phoneNumberId,
              accessToken,
              appId,
            };
            break;
          case 'mkit_http_dialog360cloud':
            vendorConfig = {
              connectionId,
              apiKey,
            };
            break;
          default:
            vendorConfig = {
              connectionId,
            };
            break;
        }
        break;
      case 5:
        switch (vendorType) {
          case 'mkit_http_vk_ok':
            vendorConfig = {
              connectionId,
            };
            break;
          default:
            break;
        }
        break;
      case 6:
        switch (vendorType) {
          case 'mkit_http_wechat':
            vendorConfig = {
              appId,
              appSecret,
              token,
              connectionId,
            };
            break;
          default:
            break;
        }
        break;
      case 7:
        body.properties.apiKey = apiKey;

        vendorConfig = {
          connectionId,
        };
        break;
      default: break;
    }

    (body.id ? putSender : postSender)({
      body: {
        vendorType,
        channel: isOpenModal,
        ...body,
        status: senderStatus,
        vendorConfig,
        clientConfig,
      },
      successCallback: () => {
        sendQuery();
        onCloseModify(true);
      },
    });
  };

  const onDeleteSenderCheck = () => {
    senderDeleteCheck({
      id: editableRecord.id,
    }).then((response) => {
      const status = JSON.parse(response?.text)?.status;
      if (status === 'templates-exist') {
        openSenderCheckModal('delete');
      } else if (status === 'templates-not-found') {
        onDeleteClick();
      }
    });
  };

  const onConfirmDeleteCheck = () => {
    onDeleteConfirm();
    closeSenderCheckModal();
  };

  useEffect(() => {
    // todo времено, лучше сделать отдельный запрос на получение всех записей
    getCompaniesDictionary();
    getAgreementsDictionary();
    getSmppConnections({
      limit: 1000,
    });
    getHttpConnections({
      limit: 1000,
    });
    getWaba({
      limit: 1000,
    });
    getSmppApiConnections();
  }, []);

  return (
    <div>
      <Table
        limit={limit}
        total={sendersMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.SENDER_IDS"
        openForm={() => openModal('channel')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.SENDER_IDS.ADD"
        columnDefs={columns}
        isPending={isPendingGetSenders || !lastUpdatedGetSenders}
        rowData={senders}
        onRowClicked={onRowClicked}
        tableRef={tableRef}
        sendQuery={sendQuery}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={SENDER_IDS}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {isOpenConfirm && (
        <ConfirmModal
          isDelete={isOpenConfirm === 'delete'}
          closeModal={closeConfirm}
          onConfirm={
             isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
           }
        />
      )}
      {isOpenSenderCheckModal === 'delete' && (
        <ConfirmSenderDeleteCheck
          closeModal={closeSenderCheckModal}
          onConfirm={onConfirmDeleteCheck}
        />
      )}
      <SelectChannel
        isOpen={isOpenModal === 'channel'}
        onClose={onCloseModal}
        openModal={openModal}
      />
      {(isOpenModal && isOpenModal !== 2 && isOpenModal !== 'channel') && (
        <Modal
          mode={editableRecord ? 'edit' : 'add'}
          closeModal={onCloseModify}
          title="SCREENS.SENDER_IDS.SENDER"
          size="lx"
        >
          <SenderForm
            smppConnectionsOptions={smppConnectionsOptions}
            smppApiConnectionsOptions={smppApiConnectionsOptions}
            httpConnectionsOptions={httpConnectionsOptions}
            wabaOptions={wabaOptions}
            onSubmit={onSubmitSender}
            isPending={isPendingPostSender || isPendingPutSender}
            initialValues={editableRecord}
            isFormPristine={isFormPristine}
            onCancel={onCloseModify}
            onDelete={onDeleteSenderCheck}
            mode={editableRecord ? 'edit' : 'add'}
            channel={isOpenModal}
            formValuesRef={formValuesRef}
          />
        </Modal>
      )}
      {isOpenModal === 2 && (
        <Modal
          mode={editableRecord ? 'edit' : 'add'}
          closeModal={onCloseModify}
          title="SCREENS.SENDER_IDS.SENDER"
          size="lx"
        >
          <ApplicationForm
            onRightButtonClick={onSubmitSender}
            rightButtonText="CONTROLS.SAVE"
            leftButtonText={editableRecord ? 'CONTROLS.DELETE' : ''}
            onLeftButtonClick={onDeleteSenderCheck}
            initialValues={editableRecord}
            isPending={isPendingPostSender || isPendingPutSender || isPendingDeleteSender}
            isFormPristine={isFormPristine}
            onCancel={onCloseModify}
            formValuesRef={formValuesRef}
            mode={editableRecord ? 'edit' : 'add'}
            selectedChannel={2}
          />
        </Modal>
      )}
      {
        isOpenModal === 'periodicFee' && (
          <Modal
            closeModal={onCloseModify}
            title={t('SCREENS.SENDER_IDS.SENDER_PERIODIC_FEE_RULES', { sender: selectedSenderId?.displayName })}
            size="lx"
          >
            <PeriodicFee
              onCancel={onCloseModify}
              {...selectedSenderId}
            />
          </Modal>
        )
      }
      {
        isOpenModal === 'setupFee' && (
          <Modal
            closeModal={onCloseModify}
            title={t('SCREENS.SENDER_IDS.SENDER_SETUP_FEE', { sender: selectedSenderId?.displayName })}
          >
            <SetupFee
              onCancel={onCloseModify}
              {...selectedSenderId}
            />
          </Modal>
        )
      }
      {
        isOpenModal === 'write-off-confirm' && (
          <ConfirmWriteOffModal
            closeModal={() => onCloseModify()}
            onConfirm={() => onConfirmWriteOff()}
          />
        )
      }
      {
        isOpenModal === 'rewrite-off-confirm' && (
          <ConfirmWriteOffModal
            closeModal={() => onCloseModify()}
            onConfirm={() => onConfirmWriteOff(true)}
            isRewrite
          />
        )
      }
    </div>
  );
};
