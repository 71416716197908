import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import Button from '@setproduct-ui/core/Button';
import { DropdownField } from 'components/fields';
import { useIntegrationsApi } from 'hooks/api';
import Spinner from '@setproduct-ui/core/Spinner';
import { CHART_TYPE } from 'consts';
import fetchFile from 'helpers/fetchFile';
import endpoints from 'consts/endpoints';
import getFiltersForQueryNew from 'helpers/getFiltersForQueryNew';

import { CountryChart, DeliveryChart, OperatorChart } from './charts';
import DateRangeField from './DateRangeField';
import styles from './StatisticBlock.module.scss';

const StatisticBlock = ({
  id,
  name,
  companyId,
  setStatisticRecord,
}) => {
  const { t } = useTranslation();
  const chartContainerRef = useRef();

  const [isDownloading, setIsDownloading] = useState(false);
  const [chartType, setChartType] = useState('timeline');
  const [fractionalType, setFractionalType] = useState('1 week');
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(3, 'days').seconds(0).toDate(),
    to: moment().seconds(0).toDate(),
  });

  const {
    getIntegrationStatistics,
    timelineIntegrationsStatistic,
    byCountryIntegrationsStatistic,
    byNetworkIntegrationsStatistic,
    isPendingGetIntegrationStatistics,
  } = useIntegrationsApi();

  const statisticTypes = {
    byNetwork: 'by_network',
    byCountry: 'by_country',
    timeline: 'timeline',
  };

  const isNoData = useMemo(
    () => (!timelineIntegrationsStatistic.length
        && chartType === 'timeline'
        && !isPendingGetIntegrationStatistics
    ) || (!byCountryIntegrationsStatistic.length
        && chartType === 'byCountry'
        && !isPendingGetIntegrationStatistics
    ) || (!byNetworkIntegrationsStatistic.length
      && chartType === 'byNetwork'
      && !isPendingGetIntegrationStatistics
    ),
    [
      byCountryIntegrationsStatistic,
      timelineIntegrationsStatistic,
      byNetworkIntegrationsStatistic,
      isPendingGetIntegrationStatistics,
      chartType,
    ],
  );

  const currentChart = useMemo(() => {
    if (isPendingGetIntegrationStatistics) {
      return <Spinner size={30} />;
    }
    switch (chartType) {
      case 'timeline':
        return (
          <DeliveryChart
            chartContainerRef={chartContainerRef}
            data={timelineIntegrationsStatistic}
            fractionalType={fractionalType}
          />
        );
      case 'byNetwork':
        return (
          <OperatorChart
            data={byNetworkIntegrationsStatistic}
          />
        );
      case 'byCountry':
        return (
          <CountryChart
            data={byCountryIntegrationsStatistic}
          />
        );
      default:
        return <div />;
    }
  }, [
    chartType,
    dateRange,
    timelineIntegrationsStatistic,
    byNetworkIntegrationsStatistic,
    byCountryIntegrationsStatistic,
    chartContainerRef,
    isPendingGetIntegrationStatistics,
    fractionalType,
  ]);

  const onClickDownloadEdrs = () => {
    const filters = {
      timeSent: {
        values: {
          filterConfig: 'byValue',
          range: true,
          fromValue: dateRange?.from?.toISOString(),
          toValue: dateRange?.to?.toISOString(),
        },
        type: 'date',
        isWithoutEmpty: false,
      },
      integrationId: {
        values: {
          filterConfig: 'byValue',
          range: false,
          fromValue: id,
        },
        type: 'number',
        isWithoutEmpty: false,
      },
    };
    setIsDownloading(true);

    fetchFile({
      url: endpoints.getEdrDownloadUrl(
        filters
          ? encodeURIComponent(JSON.stringify(getFiltersForQueryNew(filters)))
          : null,
        'csv',
        companyId,
      ),
      fileName: 'edrs.csv',
      callback: () => setIsDownloading(false),
    });
  };

  const sendQuery = () => {
    const isDateOnlyPeriod = fractionalType === '1 day'
      || fractionalType === '1 week'
      || fractionalType === '1 month'
      || fractionalType === '1 quarter'
      || fractionalType === '1 year';

    const paramPeriodEnd = isDateOnlyPeriod
      ? `${moment(dateRange.to)
        .add(1, 'day')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.sss')}Z`
      : dateRange.to.toISOString();

    getIntegrationStatistics({
      type: statisticTypes[chartType],
      entityName: chartType,
      paramIntegrationId: id,
      paramPeriodStart: dateRange.from.toISOString(),
      paramPeriodEnd,
      paramTz: Intl.DateTimeFormat().resolvedOptions()?.timeZone,
      paramGranularity: fractionalType,
    });
  };

  const onClose = () => {
    setStatisticRecord(undefined);
  };

  useEffect(() => {
    if (dateRange?.from && dateRange?.to) {
      sendQuery();
    }
  }, [
    id,
    dateRange,
    chartType,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Button
          type="default"
          view="outlined"
          color="default"
          icon="chevron-left"
          onClick={onClose}
        />
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>{t('SCREENS.TWO_FACTOR.INTEGRATION_STATISTIC')}</div>
          <div className={styles.headerName}>{name}</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.filter}>
          <DateRangeField
            externalValue={dateRange}
            onChange={setDateRange}
            fractionalType={fractionalType}
            onChangeFractional={setFractionalType}
          />
          <div className={styles.typeControl}>
            <div>{t('SCREENS.TWO_FACTOR.INTEGRATION_STATISTIC')}</div>
            <DropdownField
              options={CHART_TYPE}
              value={chartType}
              onChange={setChartType}
            />
          </div>
        </div>
        <div ref={chartContainerRef} className={styles.body}>
          {isNoData ? (
            <div className={styles.noDataMessage}>
              <Icon icon="issue" size={64} color="var(--grey10)" />
              <span className={styles.noDataMessageText}>No data</span>
            </div>
          ) : currentChart}
        </div>
        <div className={styles.footer}>
          <Button
            view="outlined"
            type="default"
            color="default"
            icon="import"
            text="CONTROLS.TWO_FACTOR.DOWNLOAD_EDR"
            onClick={onClickDownloadEdrs}
            loading={isDownloading}
            disabled={!dateRange?.from || !dateRange?.to || isNoData}
          />
          <Button
            type="default"
            view="outlined"
            color="primary"
            icon="refresh"
            onClick={sendQuery}
            loading={isPendingGetIntegrationStatistics}
            disabled={isNoData}
          />
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
