import React, { useMemo } from 'react';
import {
  Field, FieldArray, Form, Formik,
} from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { MediaPreview, Typography } from 'components';
import {
  TextField, TextAreaField, DropdownField, SwitchField,
} from 'components/fields';

import styles from './WhatsAppTemplateForm.module.scss';

const HEADER_FORMATS = [
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.IMAGE',
    value: 'IMAGE',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.VIDEO',
    value: 'VIDEO',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.DOCUMENT',
    value: 'DOCUMENT',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.TEXT',
    value: 'TEXT',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.LOCATION',
    value: 'LOCATION',
  },
];

const BUTTONS_TYPE_FORMATS = [
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.QUICK_REPLY',
    value: 'reply',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.CALL_TO_ACTION',
    value: 'action',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.COPY_CODE',
    value: 'copy_code',
  },
];

const BUTTONS_ACTIONS_TYPES = [
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.PHONE',
    value: 'phone',
  },
  {
    label: 'INSTANCES.WHATS_APP_TEMPLATES.URL',
    value: 'url',
  },
];

export default ({
  initialValues = {},
}) => {
// styles
  const fieldStyle = ({
    width,
    marginTop,
    marginLeft,
  } = {}) => ({
    container: {
      width,
      marginLeft,
    },
    label: {
      marginTop,
    },
  });
  const switchFieldStyle = ({
    marginTop,
    marginLeft,
  } = {}) => ({
    input: {
      marginTop,
      marginLeft,
    },
  });

  // hooks
  const history = useHistory();
  // memed vars
  const formValues = initialValues.components.reduce((acc, item) => {
    acc[`${item.type?.toLowerCase()}Exist`] = true;

    if (item.type === 'HEADER') {
      acc.headerFormat = item.format;
      if (item.format === 'IMAGE'
          || item.format === 'VIDEO'
          || item.format === 'DOCUMENT'
      ) {
        acc.headerMedia = item?.example?.header_handle;
      } else {
        acc.headerTextVariables = item?.example?.header_text?.map((I, index) => ({
          key: index,
          value: I,
        }));
        acc.headerText = item?.headerText;
      }
    }
    if (item.type === 'BUTTONS') {
      let buttonsKey;
      switch (item.buttons[0].type) {
        case 'QUICK_REPLY':
          acc.buttonsType = 'reply';
          buttonsKey = 'replyButtons';
          break;
        case 'URL':
        case 'PHONE_NUMBER':
          acc.buttonsType = 'action';
          buttonsKey = 'actionButtons';
          break;
        case 'OTP':
          acc.buttonsType = 'copy_code';
          buttonsKey = 'otpButtons';
          break;
        default:
          break;
      }
      if (buttonsKey === 'replyButtons') {
        acc.replyButtons = item.buttons.map(I => ({ label: I.text }));
      }
      if (buttonsKey === 'otpButtons') {
        acc.otpButtons = item.buttons.map(I => ({ label: I.text }));
      }
      if (buttonsKey === 'actionButtons') {
        acc.actionButtons = item.buttons.map(({
          text,
          type,
          ...I
        }) => ({
          type: type === 'URL' ? 'url' : 'phone',
          label: text,
          value: I[type?.toLowerCase()],
        }));
      }
    }
    if (item.type === 'BODY') {
      acc.bodyTextVariables = item?.example?.body_text?.[0]?.map((I, index) => ({
        key: index,
        value: I,
      }));
      const body = '{VERIFICATION_CODE} is your verification code.';
      const bodyWithDisclaimer = `${body} For your security, do not share this code.`;
      if (item.add_security_recommendation) {
        acc.securityDisclaimer = item.add_security_recommendation;
        acc.bodyText = bodyWithDisclaimer;
      } else {
        acc.bodyText = body;
      }
    }

    if (item.type === 'FOOTER') {
      if (item.code_expiration_minutes) {
        acc.timeIndicator = item.code_expiration_minutes;
        acc.footerText = `This code expires in ${acc.timeIndicator} minutes.`;
      }
    }

    if (item.text) {
      acc[`${item.type?.toLowerCase()}Text`] = item.text;
    }

    return acc;
  }, {});

  const formatsByType = useMemo(() => {
    if (formValues.headerMedia) {
      switch (formValues.headerFormat) {
        case 'IMAGE':
          return { imageUrl: formValues.headerMedia[0] };
        case 'VIDEO':
          return { video: { link: formValues.headerMedia[0] } };
        case 'DOCUMENT':
          return { document: { link: formValues.headerMedia[0] } };
        default:
          return undefined;
      }
    }
    return undefined;
  }, [formValues.headerFormat]);

  // functions
  const goToCompany = () => {
    history.push({
      pathname: '/companies',
      state: {
        tableFilters: {
          id: {
            values: {
              range: false,
              exclude: false,
              toValue: '',
              fromValue: initialValues.companyId,
            },
            type: 'number',
            isWithoutEmpty: false,
          },
        },
      },
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...formValues,
      }}
    >
      {({
        values,
      }) => (
        <Form className={styles.templateForm}>
          <div className={styles.content}>
            <div className={styles.templateInfo}>
              <div className={styles.row}>
                <div className={styles.templateTopBlock}>
                  <Typography small colorStep={40}>
                    INSTANCES.COMPANY
                  </Typography>
                  <Typography color="primary" colorStep={60} className={styles.link} onClick={goToCompany}>
                    {values.companyName}
                  </Typography>
                </div>
                <div className={styles.templateTopBlock}>
                  <Typography small colorStep={40}>
                    INSTANCES.NAME
                  </Typography>
                  <Typography>
                    {values.name}
                  </Typography>
                </div>
                <div className={styles.templateTopBlock}>
                  <Typography small colorStep={40}>
                    INSTANCES.WHATS_APP_TEMPLATES.SENDER
                  </Typography>
                  <Typography>
                    {values.senderDisplayName}
                  </Typography>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.templateTopBlock}>
                  <Typography small colorStep={40}>
                    INSTANCES.WHATS_APP_TEMPLATES.CATEGORY
                  </Typography>
                  <Typography>
                    {values.category}
                  </Typography>
                </div>
                <div className={styles.templateTopBlock}>
                  <Typography small colorStep={40}>
                    INSTANCES.WHATS_APP_TEMPLATES.LANGUAGE
                  </Typography>
                  <Typography>
                    {values.languageNameEn}
                  </Typography>
                </div>
                <div className={styles.templateTopBlock}>
                  <Typography small colorStep={40}>
                    INSTANCES.CREATED
                  </Typography>
                  <Typography>
                    {moment(values.createdAt).format('DD.MM.YYYY')}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={styles.templateBlock}>
              {values.category !== 'AUTHENTICATION' && (
                <>
                  <Field
                    name="headerExist"
                    component={SwitchField}
                    color="primary"
                    type="def"
                    iStyle
                    leftPosition
                    label="INSTANCES.WHATS_APP_TEMPLATES.HEADER"
                    styles={{ input: { marginLeft: 0 } }}
                    disabled
                  />
                  {values.headerExist && (
                  <Field
                    name="headerFormat"
                    component={DropdownField}
                    options={HEADER_FORMATS}
                    label="INSTANCES.WHATS_APP_TEMPLATES.HEADER_TYPE"
                    styles={fieldStyle({
                      width: 200,
                      marginTop: 13,
                    })}
                    disabled
                    fill
                  />
                  )}
                  {(values.headerExist && values.headerFormat === 'TEXT') && (
                  <>
                    <Field
                      name="headerText"
                      id="headerTextField"
                      label="INSTANCES.WHATS_APP_TEMPLATES.TEXT"
                      component={TextField}
                      maxLength={60}
                      disabled
                    />
                    { values.headerTextVariables && (
                    <FieldArray
                      name="headerTextVariables"
                      render={() => (
                        <div>
                          {values.headerTextVariables.map((I, index) => (
                            <Field
                              name={`headerTextVariables.${index}.value`}
                              value={I.value}
                              component={TextField}
                              label={{
                                key: 'INSTANCES.WHATS_APP_TEMPLATES.HEADER_VARIABLES',
                                values: { number: `{${index + 1}}` },
                              }}
                              disabled
                              full
                            />
                          ))}
                        </div>
                      )}
                    />
                    )}
                  </>
                  )}
                  {(values.headerExist && values.headerMedia && (
                    values.headerFormat === 'IMAGE'
                          || values.headerFormat === 'VIDEO'
                          || values.headerFormat === 'DOCUMENT'
                  )) && (
                  <div style={{ marginTop: 5 }}>
                    <MediaPreview
                      file={formatsByType}
                    />
                  </div>
                  )}
                </>
              )}
              <Typography style={{ marginTop: 20 }}>
                INSTANCES.WHATS_APP_TEMPLATES.BODY
              </Typography>
              {values.category === 'AUTHENTICATION' ? (
                <Field
                  name="bodyText"
                  component={TextAreaField}
                  label="INSTANCES.WHATS_APP_TEMPLATES.TEXT"
                  disabled
                  full
                />
              ) : (
                <>
                  <Field
                    name="bodyText"
                    id="bodyTextField"
                    component={TextAreaField}
                    label="INSTANCES.WHATS_APP_TEMPLATES.TEXT"
                    styles={fieldStyle({
                      marginTop: 5,
                    })}
                    disabled
                  />
                  { values.bodyTextVariables && (
                    <FieldArray
                      name="bodyTextVariables"
                      render={() => (
                        <div>
                          {values.bodyTextVariables.map((I, index) => (
                            <Field
                              name={`bodyTextVariables.${index}.value`}
                              value={I.value}
                              component={TextField}
                              label={{
                                key: 'INSTANCES.WHATS_APP_TEMPLATES.BODY_VARIABLES',
                                values: { number: `{${index + 1}}` },
                              }}
                              disabled
                              full
                            />
                          ))}
                        </div>
                      )}
                    />
                  )}
                </>
              )}
              <Field
                name="footerExist"
                component={SwitchField}
                color="primary"
                type="def"
                iStyle
                leftPosition
                label={values.category === 'AUTHENTICATION'
                  ? 'INSTANCES.WHATS_APP_TEMPLATES.EXPIRATION_WARNING'
                  : 'INSTANCES.WHATS_APP_TEMPLATES.FOOTER'}
                styles={switchFieldStyle({ marginLeft: 0, marginTop: 20 })}
                disabled
              />
              {values.footerExist && (
                <Field
                  name="footerText"
                  component={TextField}
                  label="INSTANCES.WHATS_APP_TEMPLATES.TEXT"
                  styles={fieldStyle({
                    marginTop: 5,
                  })}
                  maxLength={60}
                  disabled
                  full
                />
              )}
              <Field
                name="buttonsExist"
                component={SwitchField}
                color="primary"
                type="def"
                iStyle
                leftPosition
                label="INSTANCES.WHATS_APP_TEMPLATES.BUTTONS"
                styles={switchFieldStyle({ marginLeft: 0, marginTop: 20 })}
                disabled
              />
              {values.buttonsExist && (
                <>
                  <Field
                    name="buttonsType"
                    component={DropdownField}
                    options={BUTTONS_TYPE_FORMATS}
                    label="INSTANCES.WHATS_APP_TEMPLATES.BUTTONS_TYPE"
                    styles={fieldStyle({
                      marginTop: 5,
                      width: 268,
                    })}
                    disabled
                    fill
                  />
                  <div>
                    <FieldArray
                      name="otpButtons"
                      render={() => (
                        <div>
                          {values.buttonsType === 'copy_code' && values.otpButtons.map((I, index) => (
                            <Field
                              name={`otpButtons.${index}.label`}
                              value={I.label}
                              component={TextField}
                              label="INSTANCES.LABEL"
                              disabled
                              full
                            />
                          ))}
                        </div>
                      )}
                    />
                    <FieldArray
                      name="replyButtons"
                      render={() => (
                        <div>
                          {values.buttonsType === 'reply' && values.replyButtons.map((I, index) => (
                            <Field
                              name={`replyButtons.${index}.label`}
                              value={I.label}
                              component={TextField}
                              label={{ key: 'INSTANCES.WHATS_APP_TEMPLATES.BUTTON_VARIABLES', values: { number: index + 1 } }}
                              styles={fieldStyle({
                                marginTop: 5,
                              })}
                              disabled
                              full
                            />
                          ))}
                        </div>
                      )}
                    />
                    <FieldArray
                      name="actionButtons"
                      render={() => (
                        <div>
                          {values.buttonsType === 'action' && values.actionButtons.map((I, index) => (
                            <div>
                              <Field
                                name={`actionButtons.${index}.type`}
                                value={I.type}
                                component={DropdownField}
                                options={BUTTONS_ACTIONS_TYPES}
                                styles={{ content: { width: 268, marginTop: 10 } }}
                                disabled
                              />
                              <Field
                                name={`actionButtons.${index}.label`}
                                value={I.label}
                                component={TextField}
                                label="INSTANCES.LABEL"
                                disabled
                                full
                              />
                              <Field
                                name={`actionButtons.${index}.value`}
                                value={I.value}
                                component={TextField}
                                label={I.type === 'phone' ? 'INSTANCES.WHATS_APP_TEMPLATES.PHONE' : 'INSTANCES.WHATS_APP_TEMPLATES.URL'}
                                disabled
                                full
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                </>
              )}
              <Typography style={{ marginTop: 20 }}>
                INSTANCES.WHATS_APP_TEMPLATES.COMMENT
              </Typography>
              <Field
                name="description"
                component={TextAreaField}
                maxLength={1000}
                disabled
                full
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
