import { Icon } from '@blueprintjs/core';
import React from 'react';

import TextField from 'components/fields/TextField';
import { Typography } from 'components';

import './style.scss';

export const HistoricalField = ({
  name: externalName,
  value: externalValue,
  onChange: externalOnChange,
  setFieldValue: externalSetFieldValue,
  field: {
    onChange = externalOnChange,
    value = externalValue,
    name = externalName,
  } = {},
  form: {
    setFieldValue = externalSetFieldValue,
    errors = {},
    touched = {},
  } = {},
  placeholder,
  label,
  disabled,
  historicalData = [],
  styles = {},
  isRequired,
  readOnly,
  clearButton = false,
  dense,
  fieldClassNames = {},
  ...props
}) => {
  const handleChange = (val) => {
    if (setFieldValue) {
      setFieldValue(name, val);
    } else {
      onChange(val);
    }
  };

  return (
    <div className={`historical-field ${fieldClassNames.container}`} style={styles.container}>
      <TextField
        name={name}
        label={label}
        classNames={{ container: 'historical-field__input' }}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        styles={{ container: styles.input, label: styles.label }}
        clearButton={clearButton}
        showErrorIcon={false}
        fieldError={errors[name]}
        fieldTouched={touched[name]}
        readOnly={readOnly}
        dense={dense}
        isRequired={isRequired}
        {...props}
      />
      {!!historicalData.length && (
        <div className="historical-field__dropdown" style={styles.dropdown}>
          <input
            id={name}
            className="historical-field__checkbox"
            type="checkbox"
          />
          <label htmlFor={name} className="historical-field__dropdown__title">
            <Typography className="historical-field__dropdown__title-text">Recent addresses</Typography>
            <Icon
              icon="chevron-down"
              iconSize={20}
              className="historical-field__dropdown__title-chevron"
            />
          </label>
          <div className="historical-field__dropdown__content">
            <div className="historical-field__dropdown__content-item">
              <Typography className="historical-field__dropdown__content-item-row">
                Томская область, с.Богашево, ул. Киевская, 28
              </Typography>
              <Typography className="historical-field__dropdown__content-item-row">
                10.10.2020
              </Typography>
            </div>
            <div className="historical-field__dropdown__content-item">
              <Typography className="historical-field__dropdown__content-item-row">
                Томская область, с.Богашево, ул. Киевская, 28
              </Typography>
              <Typography className="historical-field__dropdown__content-item-row">
                10.10.2020
              </Typography>
            </div>
            <div className="historical-field__dropdown__content-item">
              <Typography className="historical-field__dropdown__content-item-row">
                Томская область, с.Богашево, ул. Киевская, 28
              </Typography>
              <Typography className="historical-field__dropdown__content-item-row">
                10.10.2020
              </Typography>
            </div>
            <div className="historical-field__dropdown__content-item">
              <Typography className="historical-field__dropdown__content-item-row">
                Томская область, с.Богашево, ул. Киевская, 28
              </Typography>
              <Typography className="historical-field__dropdown__content-item-row">
                10.10.2020
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
