import React, {
  useState,
  useMemo,
  useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@setproduct-ui/core/Button';
import Spinner from '@setproduct-ui/core/Spinner';
import Table from 'components/Table';
import ScrollableTabs from 'components/ScrollableTabs';
import { SERVICES } from 'consts/columns';
import { CustomColumnsView } from 'components/blocks';
import { useScreen } from 'hooks';
import { useServicesApi, useDictionariesApi } from 'hooks/api';
import { ConfirmModal } from 'components/modals';
import Modal from 'components/Modal';
import SwitchField from 'components/fields/SwitchField';
import toastRef from 'helpers/toast';

import ServiceTab from './ServiceTab';
import ServiceForm from './ServiceForm';
import UploadModal from './UploadModal';
import ImportConfigurationModal from './ImportConfigurationModal';
import styles from './ServicesScreen.module.scss';

const ServicesScreen = ({
  screenRef,
}) => {
  const dispatch = useDispatch();
  const rateImportConfig = useSelector(state => state.states.rateImportConfig);
  const imperativeRef = useRef();
  const [openTabs, setOpenTabs] = useState([
    {
      id: 'table',
      title: 'Services',
      withoutCross: true,
    },
  ]);
  const [activeTab, setActiveTab] = useState(0);
  const [serviceToArchive, setServiceToArchive] = useState(null);
  const showArchivedServicesRef = useRef(false);
  const {
    currencies,
    getItemLinks,
    getListWithDefinitions,
    getResourcesDictionary,
    getServiceDomainsDictionary,
    getDictionariesWithLinks,
    getAttributes,
    isPendingGetListWithDefinitions,
  } = useDictionariesApi();
  const {
    servicesT,
    servicesTMeta,
    getServices,
    postService,
    patchService,
    patchArchiveService,
    patchUnarchiveService,
    getResourceAttributes,
    getBillingRules,
    getQuantityRules,
    postCloneService,
    getServiceProperties,
    clearRateImportInfo,
    isPendingGetServices,
    isPendingPostService,
    isPendingPatchService,
    isPendingPatchArchiveService,
    isPendingPatchUnarchiveService,
    isPendingGetResourceAttributes,
    lastUpdatedGetServices,
  } = useServicesApi();
  const getFunc = (queries) => {
    getServices({
      showArchived: showArchivedServicesRef.current,
      ...queries,
    });
  };

  const {
    isOpenModal,
    openModal,
    editableRecord,
    onCloseModal,
    isFormPristine,
    formValuesRef,
    openConfirm,
    isOpenConfirm,
    closeConfirm,
    sendQuery,
    limit,
    offset,
    setOffset,
    setLimit,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSortChanged,
    onFilterChanged,
    isOpenCustomViewDrawer,
    onSaveCustomView,
    closeCustomViewDrawer,
    onConfirmClose,
    onRowClicked,
    resetPagination,
  } = useScreen({
    screenRef,
    getFunc,
    useNewFilters: true,
  });

  const confirmModalProps = useMemo(() => {
    if (isOpenConfirm === 'archive') {
      const isArchived = showArchivedServicesRef.current;
      const actionType = isArchived ? 'UNARCHIVE' : 'ARCHIVE';

      return {
        title: `CONFIRM_MODALS.SERVICES.${actionType}_TITLE`,
        text: `CONFIRM_MODALS.SERVICES.${actionType}_TEXT`,
        rightText: `CONFIRM_MODALS.SERVICES.${actionType}`,
        leftText: 'CONTROLS.CANCEL',
      };
    }

    return {
      title: false,
      text: false,
      rightText: false,
      leftText: false,
    };
  }, [isOpenConfirm]);

  const openTab = (data) => {
    onConfirmClose();
    setOpenTabs((prev) => {
      const indexOfTab = prev.findIndex(tab => tab.id === data.id);

      if (indexOfTab !== -1) {
        setActiveTab(indexOfTab);
        return prev;
      }

      setActiveTab(prev.length);

      return ([
        ...prev,
        {
          id: data.id,
          title: data.name,
          data: {
            ...data,
          },
        },
      ]);
    });
  };
  const cloneService = (data) => {
    postCloneService({
      body: {
        id: data.id,
        name: `${data.name} (clone)`,
      },
      successCallback: () => sendQuery(),
    });
  };
  const handleMoveToArchive = () => {
    if (serviceToArchive) {
      (serviceToArchive.archivedAt ? patchUnarchiveService : patchArchiveService)({
        id: serviceToArchive.id,
        successCallback: () => {
          sendQuery();
          onCloseModal({ isSave: true });
          closeConfirm();
          toastRef.current.showMessage({
            message: `TOOLTIPS.SERVICES.${showArchivedServicesRef.current ? 'SUCCESS_UNARCHIVE' : 'SUCCESS_ARCHIVE'}`,
            intent: 'success',
          });
        },
        errorCallback: () => {
          onCloseModal({ isSave: true });
        },
      });
    }
  };

  const onArchiveClick = ({ id, archivedAt }) => {
    openConfirm('archive');
    setServiceToArchive({ id, archivedAt });
  };

  const columns = useMemo(() => [
    ...SERVICES,
    {
      headerName: 'INSTANCES.ACTIONS',
      cellClass: 'appearing-cell',
      cellRenderer: 'cellRendererActions',
      width: 120,
      minWidth: 120,
      resizable: false,
      sortable: false,
      valueFormatter: ({ data }) => {
        if (data.archivedAt) {
          return [
            {
              id: 'openTree',
              icon: 'diagram-tree',
              onClick: openTab,
              htmlTitle: 'View rates',
            },
            {
              id: 'view',
              icon: 'eye-open',
              color: '#2264D1',
              onClick: () => onRowClicked({ data }),
            },
            {
              id: 'unarchive',
              icon: 'unarchive',
              color: 'var(--green50)',
              onClick: ({ id, archivedAt }) => {
                onArchiveClick({ id, archivedAt });
              },
            },
          ];
        }
        return [
          {
            id: 'clone',
            icon: 'duplicate',
            onClick: cloneService,
            htmlTitle: 'Clone',
          },
          {
            id: 'openTree',
            icon: 'diagram-tree',
            onClick: openTab,
            htmlTitle: 'View rates',
          },
          {
            id: 'edit',
            icon: 'edit',
            color: '#2264D1',
            onClick: () => onRowClicked({ data }),
          },
          {
            id: 'archive',
            icon: 'archive',
            color: 'var(--red50)',
            onClick: ({ id, archivedAt }) => {
              onArchiveClick({ id, archivedAt });
            },
          },
        ];
      },
      lockVisible: true,
      pinned: 'right',
    },
  ], []);

  const onTabClose = (e, index) => {
    e?.stopPropagation();
    const filteredTabs = [...openTabs];

    filteredTabs.splice(index, 1);

    setOpenTabs(filteredTabs);
    if (activeTab === index) {
      if (filteredTabs.length) {
        setActiveTab(filteredTabs.length - 1);
      } else {
        setActiveTab(0);
      }
    }
  };
  const onChangeShowArchiveServices = (newVal) => {
    showArchivedServicesRef.current = newVal;
    resetPagination();
    sendQuery();
  };
  const modifyTabData = (newData, title) => {
    setOpenTabs(prevState => prevState.map((tab) => {
      if (tab.title === title) {
        return ({
          id: tab.id,
          title: newData.name,
          data: { ...newData },
        });
      }
      return tab;
    }));
    sendQuery();
  };
  const onSubmit = (body) => {
    (editableRecord ? patchService : postService)({
      body,
      successCallback: ({ data }) => {
        if (editableRecord && openTabs.findIndex(tab => tab.title === editableRecord.name) !== -1) {
          modifyTabData(data, editableRecord.name);
        } else if (!editableRecord) {
          setOpenTabs((prev) => {
            setActiveTab(prev.length);

            return ([
              ...prev,
              {
                id: data.id,
                title: data.name,
                data: {
                  ...data,
                },
              },
            ]);
          });
        }
        sendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  const resetRateImport = () => {
    dispatch({ type: 'resetRateImportConfig' });
    clearRateImportInfo();
  };
  const renderPanel = (tab) => {
    if (tab.title === 'Services') {
      return (
        <Table
          columnDefs={columns}
          rowData={servicesT}
          showFilters={false}
          filteredColumns={filteredColumns}
          sendQuery={sendQuery}
          limit={limit}
          page={offset}
          total={servicesTMeta.size}
          onChangePage={setOffset}
          onChangeLimit={setLimit}
          isPending={isPendingGetServices || !lastUpdatedGetServices}
          tableRef={tableRef}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          leftPaginationComponent={(
            <SwitchField
              value={showArchivedServicesRef.current}
              onChange={onChangeShowArchiveServices}
              color="primary"
              label="show archived"
              styles={{
                container: {
                  marginRight: 'auto',
                },
              }}
            />
          )}
        />
      );
    }

    if (isPendingGetListWithDefinitions || isPendingGetResourceAttributes) {
      return <Spinner className={styles.spinner} />;
    }

    return (
      <ServiceTab
        imperativeRef={imperativeRef}
        {...tab.data}
      />
    );
  };

  useEffect(() => {
    getItemLinks();
    getListWithDefinitions();
    getResourceAttributes();
    getResourcesDictionary();
    getServiceDomainsDictionary();
    getBillingRules();
    getQuantityRules();
    getDictionariesWithLinks();
    getServiceProperties();
    getAttributes();
  }, []);

  return (
    <div className={styles.servicesLayout}>
      <ScrollableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsList={openTabs}
        onTabClose={onTabClose}
        styles={{ wrapper: { flex: 1 } }}
        renderPanel={renderPanel}
        elementInTabsPanel={(
          <div className={styles.headerButtons}>
            <Button
              type="default"
              view="outlined"
              color="primary"
              icon="add-column-right"
              onClick={openCustomViewDrawer}
              text="CONTROLS.COLUMNS"
              dense
            />
            <Button
              type="default"
              color="primary"
              icon="plus"
              onClick={() => openModal('add')}
              text="CONTROLS.SERVICES.ADD"
              dense
            />
          </div>
        )}
      />
      {isOpenModal && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          title="INSTANCES.SERVICE"
          editableRecord={editableRecord}
        >
          <ServiceForm
            mode={isOpenModal}
            onCancel={onCloseModal}
            initialValues={editableRecord}
            changeMode={openModal}
            onArchiveClick={onArchiveClick}
            onSubmit={onSubmit}
            isFormPristine={isFormPristine}
            isPending={
              isPendingPostService
              || isPendingPatchService
              || isPendingPatchArchiveService
              || isPendingPatchUnarchiveService
            }
            formValuesRef={formValuesRef}
            currencies={currencies}
            openTab={openTab}
          />
        </Modal>
      )}
      {
        isOpenCustomViewDrawer && (
          <CustomColumnsView
            initialValues={filteredColumns}
            columnsNames={SERVICES}
            onSubmit={onSaveCustomView}
            onClose={closeCustomViewDrawer}
            isOpen={isOpenCustomViewDrawer}
          />
        )
      }
      {isOpenConfirm && (
      <ConfirmModal
        closeModal={closeConfirm}
        title={confirmModalProps.title}
        text={confirmModalProps.text}
        onConfirm={isOpenConfirm === 'archive' ? handleMoveToArchive : onConfirmClose}
        rightText={confirmModalProps.rightText}
        leftText={confirmModalProps.leftText}
      />
      )}
      <UploadModal
        isOpen={rateImportConfig.isDropFileOpen}
        onClose={resetRateImport}
      />
      {rateImportConfig.isImportModalOpen && (
        <ImportConfigurationModal
          isOpen
          onClose={resetRateImport}
          sourceNode={rateImportConfig.sourceNode}
          imperativeRef={imperativeRef}
        />
      )}
    </div>
  );
};

export default ServicesScreen;
