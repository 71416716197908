import routes, {
  accountManagementRoutes,
  financeManagementRoutes,
  usersRoutes,
  settingsRoutes,
} from './routes';

const switchRoutes = [
  ...routes,
  ...accountManagementRoutes,
  ...financeManagementRoutes,
  ...usersRoutes,
  ...settingsRoutes,
];

export default switchRoutes;
