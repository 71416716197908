import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Formik, Form, Field } from 'formik';
import { Tab, Icon } from '@blueprintjs/core';
import { isEqual, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from '@setproduct-ui/core/Tabs';
import { Typography } from 'components';
import { FormButtonsGroup } from 'components/blocks';
import {
  TextField,
  DropdownField,
  DatePickerField,
  CheckboxField,
} from 'components/fields';
import { useContentShadow } from 'hooks';
import { useUsersApi } from 'hooks/api';
import { UserInfoIcon } from 'assets/icons';
import { inputPatterns } from 'consts';

import RolesField from './RolesField';
import PermisionsField from './PermissionsField';

import IsFormChanged from '../IsFormChanged';
import OnChangeComponent from '../OnChangeComponent';

import './style.scss';

const UsersForm = ({
  initialValues = {
    twoFactorAuthentication: false,
    enable: true,
  },
  onSubmit,
  onCancel,
  onDelete,
  mode,
  partnersOptions,
  countriesOptions,
  timezones,
  boPermissions,
  boRoles,
  changeMode,
  isPending,
  validationSchema,
  isFormPristine,
  formValuesRef,
}) => {
  const fieldStyle = ({
    width,
    marginTop,
    maxHeight,
    marginRight,
    marginLeft,
    bottom,
    top,
  } = {}) => ({
    container: {
      width,
      marginRight,
      marginLeft,
    },
    label: {
      marginTop,
    },
    overlay: {
      maxHeight,
      bottom,
      top,
    },
  });
  const switchStyles = {
    container: {
      marginTop: 24,
    },
  };
  const formButtonsStyles = {
    container: {
      paddingTop: 28,
    },
  };

  const { getUserById } = useUsersApi();
  const formRef = useRef();
  const dispatch = useDispatch();
  const languagesOptions = useSelector(state => state.states.languagesOptions);
  const { activeTab: activePinTab } = useSelector(state => state.states.routes);
  const [activeTab, setActiveTab] = useState('1');
  const [isParent2FAEnabled, setIsParent2FAEnabled] = useState(false);
  const userContentRef = useRef(null);
  const {
    showTopShadow: showUserTopShadow,
    showBottomShadow: showUserBottomShadow,
  } = useContentShadow(userContentRef, activeTab);
  const rolesContentRef = useRef(null);
  const {
    showTopShadow: showRolesTopShadow,
    showBottomShadow: showRolesBottomShadow,
  } = useContentShadow(rolesContentRef, activeTab);

  const isView = useMemo(() => mode === 'view', [mode]);
  const isAdd = useMemo(() => mode === 'add', [mode]);

  const changeToEdit = () => changeMode('edit');
  const checkFunction = (values) => {
    if (!isEmpty(values)) {
      formValuesRef.current = values;

      if (activePinTab) {
        dispatch({
          type: 'saveRoute',
          formValues: values,
        });
      }
    }

    switch (mode) {
      case 'edit':
        return isEqual(values, initialValues);
      case 'add':
        return isEmpty(values);
      default:
        return true;
    }
  };
  const [permissionsTabHeight, setPermissionsTabHeight] = useState(
    userContentRef?.current?.clientHeight,
  );
  // if company 2FA true --> user 2FA disabled and true
  // if company 2FA false --> user 2FA enabled and false (editable)
  const onChangeParentEntity = ({ value }) => {
    const currentParentEntity = partnersOptions.find(I => I.value === value);
    if (currentParentEntity) {
      const parent2FA = !!currentParentEntity?.twoFactorAuthentication;
      setIsParent2FAEnabled(parent2FA);
      if (parent2FA) {
        formRef.current.setFieldValue('twoFactorAuthentication', true);
      } else if (isAdd) {
        formRef.current.setFieldValue('twoFactorAuthentication', false);
      }
    }
  };
  useEffect(() => {
    if (
      userContentRef?.current?.clientHeight
      && permissionsTabHeight !== userContentRef?.current?.clientHeight
    ) {
      setPermissionsTabHeight(userContentRef?.current?.clientHeight);
    }
  }, [userContentRef?.current?.clientHeight]);
  useEffect(() => {
    if (initialValues.id) {
      getUserById({
        id: initialValues.id,
        successCallback: ({ enable }) => {
          formRef?.current?.setFieldValue('enable', enable);
        },
      });
    }
  }, [initialValues]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
    >
      {({
        handleSubmit,
        errors,
        setFieldTouched,
        values,
      }) => (
        <Form className="users-form">
          <Tabs
            type="def"
            view="flat"
            id="DemoTabs"
            fill
            color="primary"
            className="users-form__tabs"
            onChange={setActiveTab}
          >
            <Tab
              id="1"
              title={(
                <div className="users-form__tabs__tab">
                  <UserInfoIcon className="users-form__tabs__tab__icon" />
                  <Typography className="users-form__tabs__tab__label">
                    SCREENS.USERS.GENERAL_INFO
                  </Typography>
                </div>
              )}
              panel={(
                <>
                  {showUserTopShadow && <div className="users-form__shadow" />}
                  <div className="users-form__content" ref={userContentRef}>
                    <Field
                      name="companyId"
                      label="INSTANCES.COMPANY"
                      styles={fieldStyle({ width: 363 })}
                      component={DropdownField}
                      options={partnersOptions}
                      placeholder="PLACEHOLDERS.SELECT_PARTNER"
                      disabled={isView}
                      isRequired={!isView}
                    />
                    <Field
                      name="username"
                      label="INSTANCES.LOGIN"
                      component={TextField}
                      styles={fieldStyle({
                        width: 363,
                        marginTop: 24,
                      })}
                      placeholder="PLACEHOLDERS.USERS.ENTER_LOGIN"
                      disabled={isView || mode === 'edit'}
                      isRequired={!isView}
                    />
                    <div className="users-form__row mt24">
                      <Field
                        name="password"
                        label="INSTANCES.PASSWORD"
                        type="password"
                        component={TextField}
                        styles={fieldStyle({ width: 268 })}
                        placeholder="PLACEHOLDERS.USERS.CREATE_A_PASSWORD"
                        disabled={isView}
                        isRequired={mode === 'add'}
                      />
                      <Field
                        name="rePassword"
                        label="INSTANCES.USERS.RE_TYPE_PASSWORD"
                        type="password"
                        component={TextField}
                        styles={fieldStyle({ width: 268 })}
                        placeholder="PLACEHOLDERS.USERS.TYPE_PASSWORD_AGAIN"
                        disabled={isView}
                        isRequired={mode === 'add'}
                      />
                    </div>
                    <div className="users-form__row mt32">
                      <Field
                        name="firstName"
                        label="INSTANCES.USERS.FIRST_NAME"
                        component={TextField}
                        styles={fieldStyle({ width: 268 })}
                        placeholder="PLACEHOLDERS.USERS.ENTER_NAME"
                        disabled={isView}
                        isRequired={!isView}
                      />
                      <Field
                        name="middleName"
                        label="INSTANCES.USERS.MIDDLE_NAME"
                        component={TextField}
                        styles={fieldStyle({ width: 268 })}
                        placeholder=""
                        disabled={isView}
                      />
                    </div>
                    <div className="users-form__row mt24">
                      <Field
                        name="lastName"
                        label="INSTANCES.USERS.LAST_NAME"
                        component={TextField}
                        styles={fieldStyle({ width: 268 })}
                        placeholder="PLACEHOLDERS.USERS.ENTER_SURNAME"
                        disabled={isView}
                        isRequired={!isView}
                      />
                      <Field
                        name="birthday"
                        label="INSTANCES.USERS.BIRTHDAY"
                        component={DatePickerField}
                        styles={fieldStyle({
                          width: 197,
                          marginRight: 'auto',
                          marginLeft: 18,
                        })}
                        disabled={isView}
                        format="DD.MM.YYYY"
                      />
                    </div>
                    <Field
                      name="jobTitle"
                      label="INSTANCES.JOB_TITLE"
                      component={TextField}
                      styles={fieldStyle({ width: 364, marginTop: 32 })}
                      placeholder="PLACEHOLDERS.USERS.ENTER_JOB_TITLE"
                      disabled={isView}
                    />
                    <div className="users-form__row mt24">
                      <Field
                        name="phoneNumber"
                        label="INSTANCES.PHONE_NUMBER"
                        component={TextField}
                        styles={fieldStyle({ width: 268 })}
                        placeholder="PLACEHOLDERS.USERS.ENTER_USER_PHONE"
                        disabled={isView}
                        regex={inputPatterns.numbers({ withPlus: true })}
                        isRequired={values.twoFactorAuthentication}
                      />
                      <Field
                        name="email"
                        label="INSTANCES.EMAIL"
                        component={TextField}
                        styles={fieldStyle({ width: 268 })}
                        placeholder="PLACEHOLDERS.USERS.ENTER_USER_EMAIL"
                        disabled={isView}
                        isRequired={!isView}
                      />
                    </div>
                    <div className="users-form__row mt32">
                      <Field
                        name="countryId"
                        label="INSTANCES.COUNTRY"
                        component={DropdownField}
                        options={countriesOptions}
                        styles={fieldStyle({
                          width: 363,
                        })}
                        placeholder="PLACEHOLDERS.SELECT_COUNTRY"
                        disabled={isView}
                      />
                      <Field
                        name="language"
                        label="INSTANCES.USERS.LANGUAGE"
                        component={DropdownField}
                        options={languagesOptions}
                        styles={fieldStyle({
                          width: 173,
                        })}
                        placeholder="PLACEHOLDERS.USERS.SELECT_LANGUAGE"
                        isRequired={!isView}
                        disabled={isView}
                      />
                    </div>
                    <Field
                      name="timeZoneId"
                      label="INSTANCES.TIMEZONE"
                      component={DropdownField}
                      options={timezones}
                      translateOptions={false}
                      styles={fieldStyle({
                        width: 363,
                        marginTop: 24,
                      })}
                      placeholder="PLACEHOLDERS.USERS.SELECT_TIMEZONE"
                      isRequired={!isView}
                      disabled={isView}
                    />
                    <Field
                      name="twoFactorAuthentication"
                      component={CheckboxField}
                      text="PLACEHOLDERS.USERS.TWO_FACTOR_AUTHENTICATION"
                      disabled={isParent2FAEnabled || isView}
                      styles={fieldStyle({
                        marginTop: 24,
                      })}
                    />
                    <Field
                      name="enable"
                      component={CheckboxField}
                      text="INSTANCES.USERS.ENABLED"
                      disabled={isView}
                    />
                  </div>
                </>
              )}
            />
            {5 < 2 && (
              <Tab
                id="2"
                title={(
                  <div className="users-form__tabs__tab">
                    <Icon
                      icon="shield"
                      iconSize={20}
                      className="users-form__tabs__tab__icon"
                    />
                    <Typography className="users-form__tabs__tab__label">
                      SCREENS.USERS.ROLES_PERMISSIONS
                    </Typography>
                  </div>
                )}
                panel={(
                  <>
                    {showRolesTopShadow && <div className="users-form__shadow" />}
                    <div
                      className="users-form__content"
                      ref={rolesContentRef}
                      style={{
                        minHeight: permissionsTabHeight,
                        maxHeight: permissionsTabHeight,
                      }}
                    >
                      <Field
                        name="roles"
                        component={RolesField}
                        disabled={isView}
                        options={boRoles}
                        placeholder="PLACEHOLDERS.USERS.SELECT_ROLE"
                      />
                      <Field
                        name="permissions"
                        boPermissions={boPermissions}
                        styles={switchStyles}
                        component={PermisionsField}
                        disabled={isView}
                      />
                    </div>
                  </>
                )}
              />
            )}
          </Tabs>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            styles={formButtonsStyles}
            showShadow={
              activeTab === '1' ? showUserBottomShadow : showRolesBottomShadow
            }
          />
          <IsFormChanged
            isFormPristine={isFormPristine}
            checkFunction={checkFunction}
          />
          <OnChangeComponent field="companyId" onChange={onChangeParentEntity} />
        </Form>
      )}
    </Formik>
  );
};
export default UsersForm;
