import React, {
  forwardRef,
  useState,
  useMemo,
  useRef,
  useImperativeHandle, useEffect,
} from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { useKey } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';

import { ButtonGroupField, CheckboxField, MaskField } from 'components/fields';
import { Typography } from 'components/Typography';
import Button from '@setproduct-ui/core/Button/Button';
import Card from '@setproduct-ui/core/Card';
import Chips from '@setproduct-ui/core/Chips';
import SearchInput from '@setproduct-ui/core/Inputs/SearchInput';
import { FILTER_BUTTONS } from 'consts';
import {
  useAccountsApi,
  useRatePlansApi,
  useDictionariesApi,
  usePrintableFormsApi,
  useWhatsAppTemplatesApi,
} from 'hooks/api';

import './style.scss';

export default forwardRef(({
  withFilter = false,
  initialValues = {},
  onCancel = Function.prototype,
  colDef: {
    field,
    fieldId,
    headerComponentParams: {
      withoutEmpty,
      items,
      type,
      reservedList,
      filterKey,
    } = {},
  } = {},
  filterChangedCallback,
  api,
  styles,
}, ref) => {
  const isApply = useRef();
  const formRef = useRef();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const { accountsAll } = useAccountsApi();
  const { ratePlansAll } = useRatePlansApi();
  const { getOperations, operations } = useAccountsApi();
  const {
    timezones,
    currencies,
    resourcesOptions,
    legalEntitiesOptions,
    servicesOptions,
    ownLegalEntitiesOptions,
    sendersOptions,
    boUsersOptions,
    portalsOptions,
    agreementsOptions,
    companiesOptionsWithOwn,
    companiesOptions,
    getCompaniesDictionary,
  } = useDictionariesApi();
  const {
    whatsAppCategories,
    whatsAppLanguages,
  } = useWhatsAppTemplatesApi();
  const { printableFormTypes } = usePrintableFormsApi();

  useKey('Enter', () => {
    formRef.current.handleSubmit();
  });

  const itemsModify = useMemo(() => {
    switch (reservedList) {
      case 'companies':
        return companiesOptions;
      case 'companiesWithOwn':
        return companiesOptionsWithOwn;
      case 'agreements':
        return agreementsOptions;
      case 'services':
        return servicesOptions;
      case 'boUsers':
        return boUsersOptions;
      case 'legalEntities':
        return legalEntitiesOptions;
      case 'operations':
        return operations.map(item => ({
          value: item,
          label: t(`SCREENS.AUDIT.${item}`),
        }));
      case 'ownLegalEntities':
        return ownLegalEntitiesOptions;
      case 'printableFormTypes':
        return printableFormTypes.map(item => ({
          value: item.id,
          label: item.name,
        }));
      case 'accounts':
        return accountsAll.map(item => ({
          value: item.id,
          label: item.name || `ID: ${item.id}`,
        }));
      case 'ratePlans':
        return ratePlansAll.map(item => ({
          value: item.id,
          label: item.name || `ID: ${item.id}`,
        }));
      case 'timezones':
        return timezones
          .filter((v, i, a) => a.findIndex(T => (T.label === v.label)) === i)
          .map(I => ({
            value: I.areaName,
            label: I.label,
          }));
      case 'currencies':
        return currencies;
      case 'resources':
        return resourcesOptions;
      case 'senders':
        return sendersOptions;
      case 'portals':
        return portalsOptions;
      case 'whatsAppCategories':
        return whatsAppCategories;
      case 'whatsAppLanguages':
        return whatsAppLanguages;
      default: return items;
    }
  }, [
    items,
    reservedList,
    servicesOptions,
    agreementsOptions,
    legalEntitiesOptions,
    companiesOptions,
    companiesOptionsWithOwn,
    accountsAll,
    timezones,
    currencies,
    ratePlansAll,
    ownLegalEntitiesOptions,
    operations,
  ]);

  const foundItems = useMemo(
    () => {
      if (!searchValue.trim()) {
        return itemsModify;
      }

      const desiredValue = searchValue.trim().toLowerCase();

      return itemsModify.filter(item => item.label.toLowerCase().includes(desiredValue));
    },
    [searchValue, itemsModify],
  );

  useKey('Escape', onCancel);

  useImperativeHandle(ref, () => ({
    getModel() {
      // eslint-disable-next-line react/no-this-in-sfc
      if (!this.isFilterActive()) {
        return null;
      }

      let { values } = formRef.current;

      values = Object.keys(values).reduce((acc, key) => {
        acc[key.replace('_', '')] = values[key];
        return acc;
      }, {});

      return {
        field: filterKey || field,
        values,
        type: type || 'list',
      };
    },
    setModel(data) {
      isApply.current = true;
      if (data) {
        const { values } = data;
        const nextValues = Object.keys(values || {}).reduce((acc, key) => {
          if (key !== 'empty' && key !== 'notempty' && key !== 'filterConfig') {
            acc[`_${key}`] = values[key];
          } else {
            acc[key] = values[key];
          }
          return acc;
        }, {});
        formRef.current.setValues(nextValues);

        // todo нужно для таких кейсов, применен именной фильтр к одному атрибуту
        // а потом применяем именой фильтр к другому атрибуты и поля в этом фильтре не заполняются
        setTimeout(() => {
          if (formRef?.current) {
            formRef.current.resetForm();
            formRef.current.setValues(nextValues);
          }
        });
      } else {
        formRef.current.resetForm();
        setSearchValue('');
      }
    },
    isFilterActive() {
      const { values } = formRef.current;

      if (!values || !isApply.current) {
        return false;
      }

      return Object.values(values).some(item => item);
    },
    doesFilterPass() {
      return true;
    },
  }));

  useEffect(() => {
    if (reservedList === 'operations') {
      getOperations();
    }
    if (reservedList === 'companies' && !companiesOptions.length) {
      getCompaniesDictionary();
    }
  }, [reservedList]);

  return (
    <Formik
      keepDirtyOnReinitialize
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (values.filterConfig === 'empty' || values.filterConfig === 'notempty') {
          formRef.current.resetForm();
          formRef.current.setFieldValue('filterConfig', values.filterConfig);
        }

        // todo убрать когда уберу возмодность создавать фильтры '2323'
        let processedValues = values;
        if (withFilter) {
          processedValues = Object.keys(values).reduce((acc, key) => {
            acc[key.replace('_', '')] = values[key];
            return acc;
          }, {});
        }

        isApply.current = true;
        filterChangedCallback({
          values: processedValues,
          field,
          fieldId,
        });
        api.menuFactory.hidePopup();
        api.redrawRows();
        setTimeout(() => api.sendQuery());
      }}
    >
      {({
        handleSubmit,
        values,
      }) => (
        <Form
          onSubmit={handleSubmit}
          className="list-filter"
          style={styles}
          data-testid={`${window.location.pathname}/filter/list/${field}`}
        >
          {withoutEmpty ? (
            <div className="list-filter__mask-field">
              <Field
                name="value"
                component={MaskField}
                placeholder={t('PLACEHOLDERS.FILTER.INPUT_MASK')}
              />
            </div>
          ) : (
            <>
              <Field
                name="filterConfig"
                className="list-filter__button-group"
                component={ButtonGroupField}
                options={FILTER_BUTTONS}
                fieldName={field}
                data-testid={`${window.location.pathname}/filter/list/${field}/filter-config`}
              />
              {values.filterConfig === 'notempty' || values.filterConfig === 'empty' ? (
                <Card
                  type="def"
                  view="smooth"
                  color="warning_alt"
                  interactive={false}
                  className="list-filter__card"
                >
                  <div className="list-filter__card__content">
                    <Icon color="#FDC68B" icon="info-sign" iconSize={16} />
                    <Typography
                      tagName="span"
                      className="list-filter__card__content__text"
                    >
                      {t('CONTROLS.FILTER.DISPLAYED_CELLS', {
                        what: t(`CONTROLS.FILTER.${values.filterConfig === 'empty' ? 'EMPTY_CELLS' : 'NOT_EMPTY_CELLS'}`),
                      })}
                    </Typography>
                  </div>
                </Card>
              ) : (
                <div className="list-filter__mask-field">
                  <SearchInput
                    type="def"
                    view="outlined"
                    color="default"
                    name="search"
                    placeholder={t('PLACEHOLDERS.FILTER.INPUT_MASK')}
                    fill
                    value={searchValue}
                    styles={{ width: 286 }}
                    onChange={setSearchValue}
                    data-testid={`${window.location.pathname}/filter/list/${field}/search`}
                  />
                  <div className="list-filter__list">
                    {
                      foundItems.map(item => (
                        <div className="list-filter__list__item" key={item.value}>
                          <Field
                            name={`_${item.value}`}
                            component={CheckboxField}
                            fieldType="dense"
                            className="list-filter__list__item__checkbox"
                            data-testid={`${window.location.pathname}/filter/list/${field}/item/${item.value}`}
                          />
                          {item.icon ? (
                            <Icon icon={item.icon} size={10} color={item.color} />
                          ) : (
                            item.color ? (
                              <Chips
                                type="dense"
                                className="list-filter__list__item__chips"
                                color={item.color ? item.color : 'white'}
                                tag={item.label}
                                round={false}
                              />
                            ) : (
                              <Typography
                                tagName="span"
                                className="list-filter__list__item__text"
                              >
                                {item.label}
                              </Typography>
                            )
                          )}
                        </div>
                      ))
                    }
                  </div>
                </div>
              )}
            </>
          )}
          <div className="list-filter__filter-controls">
            <Button
              dense
              view="filled"
              color="primary"
              onClick={handleSubmit}
              className="list-filter__filter-controls__button"
              data-testid={`${window.location.pathname}/filter/list/${field}/apply`}
            >
              <Typography
                tagName="span"
                className="list-filter__filter-controls__button__text"
              >
                {t('CONTROLS.FILTER.APPLY_FILTER')}
              </Typography>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
});
