/* eslint-disable */
import { Typography } from 'components';
import { DropdownField } from 'components/fields';
import Tabs from '@setproduct-ui/core/Tabs';
import { NoUserIcon, CrossIcon } from 'assets/icons';

import { useClickAway } from 'react-use';
import { Tab, Icon } from '@blueprintjs/core';
import React, { useState, useRef } from 'react';

import {
  Activity,
  Content,
  Finance,
  TotalInformation,
} from '../Tabs';

const MOCK = [
  {
    value: 1,
    label: 'OOO Коровка из Кореновки',
  },
];

import './style.scss';

const ClientModal = ({
  closeModal
}) => {
  const ref = useRef(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [isDropdownShow, setIsDropdownShow] = useState(false);

  useClickAway(ref, () => {
    setIsDropdownShow(false);
  });

  const dropdownStyle = {
    container: {
      width: 284,
    },
    input: {
      fontSize: 14,
    },
    overlay: {
      fontSize: 14,
    },
  };

  return (
    <div className="client-profile-container">
      <div className="client-block">
        <div className="client-header">
          <div className="info">
            <NoUserIcon className="info__img" />
            <Typography>Information technology</Typography>
          </div>
          <div className="reg-date">
            <Typography small colorStep={40}>Reg date</Typography>
            <Typography small>10.08.2020</Typography>
          </div>
          <div className="last-login">
            <Typography small colorStep={40}>Last login</Typography>
            <Typography small>10.08.2020</Typography>
          </div>
          <div className="select-client">
            <DropdownField
              options={MOCK}
              value={1}
              styles={dropdownStyle}
            />
          </div>
          <div ref={ref} className="client-header__client-more">
            <Icon
              icon="more"
              iconSize={20}
              className="client-header__more-icon client-header__icon"
              onClick={() => setIsDropdownShow(!isDropdownShow)}
            />
            <CrossIcon
              className="client-header__cross-icon client-header__icon"
              onClick={closeModal}
            />
            {/* <Modal */}
            {/*  mode="BLOCK_ACCOUNT" */}
            {/*  closeModal={() => {}} */}
            {/* /> */}
            {isDropdownShow && (
              <div className="client-header__client-more__dropdown">
                <div className="client-header__client-more__dropdown-item">
                  <Icon
                    icon="eye-open"
                    iconSize={20}
                    className="client-header__client-more__dropdown-item-icon"
                    color="var(--blue70)"
                  />
                  <Typography small>Impersonation</Typography>
                </div>
                <div className="client-header__client-more__dropdown-item">
                  <Icon
                    icon="delete"
                    iconSize={20}
                    className="client-header__client-more__dropdown-item-icon"
                    color="var(--red60)"
                  />
                  <Typography small>Block account</Typography>
                </div>
              </div>
            )}
          </div>
        </div>
         <div className="client-content">
          <Tabs
            id="clientTabs"
            type="def"
            view="flat"
            color="primary"
            className="client-content__tabs"
            onChange={setCurrentTab}
            renderActiveTabPanelOnly
            fill
          >
            <Tab className="client-content__tabs__tab" id={1} title="Activity" panel={<Activity />} />
            <Tab className="client-content__tabs__tab" id={2} title="Total information" panel={<TotalInformation />} />
            <Tab className="client-content__tabs__tab" id={3} title="Finance" panel={<Finance />} />
            <Tab className="client-content__tabs__tab" id={4} title="Content" panel={<Content />} />
          </Tabs>
         </div>
      </div>
    </div>

  );
};

export default ClientModal;
