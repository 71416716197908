import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@setproduct-ui/core/Button';
import ScrollableTabs from 'components/ScrollableTabs';
import {
  useLegalEntitiesApi,
  useDictionariesApi,
  useSelfRegisterApi,
} from 'hooks/api';
import { useFilteredColumns, useScreen, useTable } from 'hooks';
import Table from 'components/Table';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CustomColumnsView } from 'components/blocks';
import { LEGAL_ENTITIES, SELF_REGISTRATION_SETTINGS } from 'consts/columns';
import Modal from 'components/Modal';
import { LegalEntityForm, SelfRegistrationForm } from 'components/forms';
import './style.scss';

const OwnLegalEntitiesScreen = ({ screenRef }) => {
  const { t } = useTranslation();
  const selfRegTableRef = useRef(null);
  const legalEntityTableRef = useRef(null);

  const tabs = [
    {
      key: 'legal-entities',
      title: t('TABS.OWN_LEGAL_ENTITIES'),
      withoutCross: true,
    },
    {
      key: 'self-registration',
      title: t('TABS.SELF_REGISTRATION_SETTINGS'),
      withoutCross: true,
    },
  ];

  const {
    getOwnLegalEntities,
    postLegalEntity,
    putLegalEntity,
    deleteLegalEntity,
    ownLegalEntities,
    ownLegalEntitiesMeta,
    isPendingGetOwnLegalEntities,
    isPendingPostLegalEntity,
    isPendingPutLegalEntity,
    isPendingDeleteLegalEntity,
    lastUpdatedGetOwnLegalEntities,
  } = useLegalEntitiesApi();
  const {
    selfRegisterList,
    selfRegisterListMeta,
    getSelfRegisterList,
    postSelfRegister,
    putSelfRegister,
    deleteSelfRegister,
    isPendingGetSelfRegisterList,
    isPendingPostSelfRegister,
    isPendingPutSelfRegister,
    isPendingDeleteSelfRegister,
    lastUpdatedGetSelfRegisterList,
  } = useSelfRegisterApi();
  const {
    servicesOptions: servicesOptionsAll,
    companiesOptions,
    getPortalsDictionary,
    getTimezones,
    getCurrencies,
    getCompaniesDictionary,
    getOwnLegalEntitiesDictionary,
    getSendersDictionary,
    getServicesDictionary,
    isPendingGetServicesDictionary,
    getIntegrationsDictionary,
  } = useDictionariesApi();
  const {
    isOpenCustomViewDrawer: selfRegIsOpenCustomViewDrawer,
    closeCustomViewDrawer: selfRegCloseCustomViewDrawer,
    openCustomViewDrawer: selfRegOpenCustomViewDrawer,
    onSaveCustomView: selfRegOnSaveCustomView,
    filteredColumns: selfRegFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_self-reg`,
  });
  const {
    isOpenCustomViewDrawer: legalEntityIsOpenCustomViewDrawer,
    closeCustomViewDrawer: legalEntityCloseCustomViewDrawer,
    openCustomViewDrawer: legalEntityOpenCustomViewDrawer,
    onSaveCustomView: legalEntityOnSaveCustomView,
    filteredColumns: legalEntityFilteredColumns,
  } = useFilteredColumns({
    sessionKey: `${window.location.pathname}_legal-entity`,
  });
  const {
    sendQuery: legalEntitySendQuery,
    limit: legalEntityLimit,
    offset: legalEntityOffset,
    onSortChanged: legalEntityOnSortChanged,
    onFilterChanged: legalEntityOnFilterChanged,
    setLimit: legalEntitySetLimit,
    setOffset: legalEntitySetOffset,
  } = useTable({
    sendQuery: getOwnLegalEntities,
    tableRef: legalEntityTableRef,
  });
  const {
    sendQuery: selfRegSendQuery,
    limit: selfRegLimit,
    offset: selfRegOffset,
    onSortChanged: selfRegOnSortChanged,
    onFilterChanged: selfRegOnFilterChanged,
    setLimit: selfRegSetLimit,
    setOffset: selfRegSetOffset,
  } = useTable({
    sendQuery: getSelfRegisterList,
    tableRef: selfRegTableRef,
  });
  const {
    openModal,
    editableRecord,
    onCloseModal,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    onConfirmClose,
    closeConfirm,
    isOpenModal,
    setEditableRecord,
  } = useScreen({
    screenRef,
  });

  const [activeTab, setActiveTab] = useState(0);

  const columnDefs = useMemo(
    () =>
      (activeTab === 0
        ? LEGAL_ENTITIES.filter(col => col.field !== 'companyId')
        : SELF_REGISTRATION_SETTINGS),
    [activeTab],
  );
  const packsOptions = useMemo(
    () => servicesOptionsAll
      .filter(I => I.type === 2 || I.type === 3)
      .map(I => ({
        value: I.value,
        label: `${I.label} ${I.currency ? `(${I.currency})` : ''}`,
        type: I.type,
        currency: I.currency,
      })),
    [servicesOptionsAll],
  );
  const servicesOptions = useMemo(
    () => servicesOptionsAll
      .filter(I => I.type === 1)
      .map(I => ({
        value: I.value,
        label: `${I.label} ${I.currency ? `(${I.currency})` : ''}`,
        type: I.type,
        currency: I.currency,
      })),
    [servicesOptionsAll],
  );
  const onSubmitLegalEntity = (body) => {
    (editableRecord ? putLegalEntity : postLegalEntity)({
      body,
      successCallback: () => {
        legalEntitySendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };

  const onSubmitSelfReg = ({
    id,
    enabled,
    companyModeration,
    company2FA,
    integrationId,
    legalEntityId,
    portalId,
    phoneVerify,
    codePrefix,
    currency,
    creditLimit,
    billingTz,
    billingDay,
    billingPeriod,
    splitByMonth,
    services = [],
    packs = [],
    reportEmail,
    ...values
  }) => {
    const serviceInfo = [
      ...services.map(serviceId => ({
        serviceId,
        serviceType: 1,
      })),
      ...packs.map((packId) => {
        const packOption = packsOptions.find(option => option.value === packId);
        return {
          serviceId: packId,
          serviceType: packOption ? packOption.type : 0,
        };
      }),
    ];
    const form = new FormData();
    form.append('dto', new Blob([
      JSON.stringify({
        portalId,
        legalEntityId,
        integrationId,
        requiredInfo: {
          billingTz,
          billingDay,
          billingPeriod,
          currency,
          companyModeration,
          company2FA,
          splitByMonth,
          creditLimit,
          codePrefix,
          serviceInfo,
        },
        enabled,
        phoneVerify,
        reportEmail,
        ...(typeof values.terms === 'string' && { terms: values.terms }),
        ...(typeof values.policy === 'string' && { policy: values.policy }),
      }),
    ], {
      type: 'application/json',
    }));
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value !== 'string') {
        form.append(key, value);
      }
    });
    (id ? putSelfRegister : postSelfRegister)({
      id,
      body: form,
      successCallback: () => {
        selfRegSendQuery();
        onCloseModal({ isSave: true });
      },
    });
  };
  const onDeleteSelfRegConfirm = () => {
    closeConfirm();
    deleteSelfRegister({
      id: editableRecord.id,
      successCallback: () => {
        selfRegSendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: () => onCloseModal({ isSave: true }),
    });
  };
  const onDeleteLegalEntityConfirm = () => {
    closeConfirm();
    deleteLegalEntity({
      id: editableRecord.id,
      successCallback: () => {
        legalEntitySendQuery();
        onCloseModal({ isSave: true });
      },
      errorCallback: () => onCloseModal({ isSave: true }),
    });
  };
  const onTabClose = (e, index) => {
    e.stopPropagation();
    const filteredTabs = [...tabs];
    filteredTabs.splice(index, 1);
    if (activeTab === index) {
      if (filteredTabs.length) {
        setActiveTab(filteredTabs.length - 1);
      } else {
        setActiveTab(0);
      }
    } else if (activeTab) {
      setActiveTab(activeTab - 1);
    }
  };
  const transformResponseToFormValues = (data) => {
    const {
      requiredInfo: { serviceInfo },
      ...otherData
    } = data;

    const services = serviceInfo
      ?.filter(item => item.serviceType === 1)
      ?.map(item => item.serviceId);
    const packs = serviceInfo
      ?.filter(item => item.serviceType !== 1)
      ?.map(item => item.serviceId);

    return {
      ...otherData,
      legalEntityId: data.legalEntityId,
      portalId: data.portalId,
      enabled: data.enabled,
      phoneVerify: data.phoneVerify,
      companyModeration: data.requiredInfo.companyModeration,
      company2FA: data.requiredInfo.company2FA,
      splitByMonth: data.requiredInfo.splitByMonth,
      terms: data.terms,
      policy: data.policy,
      billingTz: data.requiredInfo.billingTz,
      billingDay: data.requiredInfo.billingDay,
      billingPeriod: data.requiredInfo.billingPeriod,
      currency: data.requiredInfo.currency,
      creditLimit: data.requiredInfo.creditLimit,
      codePrefix: data.requiredInfo.codePrefix,
      reportEmail: data.reportEmail,
      services,
      packs,
    };
  };
  const onRowClicked = ({ data }) => {
    const transformedData = activeTab === 1 ? transformResponseToFormValues(data) : data;
    setEditableRecord({
      ...transformedData,
    });
    openModal('view');
  };
  const renderPanel = (tab) => {
    switch (tab.key) {
      case 'legal-entities':
        return (
          <Table
            columnDefs={columnDefs}
            rowData={ownLegalEntities}
            onRowClicked={onRowClicked}
            showFilters={false}
            limit={legalEntityLimit}
            total={ownLegalEntitiesMeta?.size}
            page={legalEntityOffset}
            filteredColumns={legalEntityFilteredColumns}
            onChangePage={legalEntitySetOffset}
            onChangeLimit={legalEntitySetLimit}
            sendQuery={legalEntitySendQuery}
            isPending={isPendingGetOwnLegalEntities || !lastUpdatedGetOwnLegalEntities}
            tableRef={legalEntityTableRef}
            onSortChanged={legalEntityOnSortChanged}
            onFilterChanged={legalEntityOnFilterChanged}
          />
        );
      case 'self-registration':
        return (
          <Table
            columnDefs={columnDefs}
            rowData={selfRegisterList}
            onRowClicked={onRowClicked}
            showFilters={false}
            limit={selfRegLimit}
            total={selfRegisterListMeta?.size}
            page={selfRegOffset}
            filteredColumns={selfRegFilteredColumns}
            onChangePage={selfRegSetOffset}
            onChangeLimit={selfRegSetLimit}
            sendQuery={selfRegSendQuery}
            isPending={isPendingGetSelfRegisterList || !lastUpdatedGetSelfRegisterList || isPendingGetServicesDictionary}
            tableRef={selfRegTableRef}
            onSortChanged={selfRegOnSortChanged}
            onFilterChanged={selfRegOnFilterChanged}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getPortalsDictionary();
    getTimezones();
    getCurrencies();
    getCompaniesDictionary();
    getOwnLegalEntitiesDictionary();
    getSendersDictionary();
    getIntegrationsDictionary();
    getServicesDictionary({ showArchived: false });
  }, []);

  return (
    <div className="own-legal-entities-layout">
      <ScrollableTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabsList={tabs}
        onTabClose={onTabClose}
        styles={{ wrapper: { flex: 1 } }}
        renderPanel={renderPanel}
        alwaysShowTabsPanelElement
        elementInTabsPanel={(
          <div style={{ display: 'flex', flexDirection: 'row', gap: 14 }}>
            <Button
              type="default"
              view="outlined"
              color="primary"
              icon="add-column-right"
              onClick={activeTab
                ? selfRegOpenCustomViewDrawer
                : legalEntityOpenCustomViewDrawer}
              text="CONTROLS.COLUMNS"
              data-testid={`${window.location.pathname}/table/columns`}
              dense
            />
            <Button
              icon="plus"
              color="primary"
              className="screen-header__button"
              onClick={() => openModal('add')}
              disabled={isOpenModal}
              text={activeTab
                ? 'CONTROLS.LEGAL_ENTITIES.ADD_NEW_SET'
                : 'CONTROLS.LEGAL_ENTITIES.ADD'}
              data-testid={`${window.location.pathname}/create-instance/${activeTab
                ? 'set-entity'
                : 'legal-entity'}`}
              dense
            />
          </div>
        )}
      />
      {
        (selfRegIsOpenCustomViewDrawer || legalEntityIsOpenCustomViewDrawer) && (
          <CustomColumnsView
            initialValues={activeTab ? selfRegFilteredColumns : legalEntityFilteredColumns}
            columnsNames={columnDefs}
            onSubmit={activeTab
              ? selfRegOnSaveCustomView
              : legalEntityOnSaveCustomView}
            onClose={activeTab
              ? selfRegCloseCustomViewDrawer
              : legalEntityCloseCustomViewDrawer}
            isOpen={legalEntityIsOpenCustomViewDrawer || selfRegIsOpenCustomViewDrawer}
          />
        )
      }
      {
        (isOpenModal && activeTab === 0) && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.LEGAL_ENTITIES.LEGAL_ENTITY"
            editableRecord={editableRecord}
          >
            <LegalEntityForm
              onSubmit={onSubmitLegalEntity}
              onCancel={onCloseModal}
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              isFormPristine={isFormPristine}
              partnersOptions={companiesOptions}
              isPending={
                isPendingDeleteLegalEntity
                  || isPendingPostLegalEntity
                  || isPendingPutLegalEntity
              }
              formValuesRef={formValuesRef}
              isOwnLegalEntities
            />
          </Modal>
        )
      }
      {
        (isOpenModal && activeTab === 1) && (
          <Modal
            mode={isOpenModal}
            closeModal={onCloseModal}
            title="SCREENS.LEGAL_ENTITIES.SELF_REGISTRATION"
            editableRecord={editableRecord}
            size="lg"
          >
            <SelfRegistrationForm
              initialValues={editableRecord}
              mode={isOpenModal}
              changeMode={openModal}
              onDelete={onDeleteClick}
              onCancel={onCloseModal}
              onSubmit={onSubmitSelfReg}
              packsOptions={packsOptions}
              servicesOptions={servicesOptions}
              isPending={isPendingPostSelfRegister
                || isPendingPutSelfRegister
                || isPendingDeleteSelfRegister}
            />
          </Modal>
        )
      }
      {
        isOpenConfirm && (
          <ConfirmModal
            isDelete={isOpenConfirm === 'delete'}
            closeModal={closeConfirm}
            onConfirm={
              isOpenConfirm === 'delete'
                ? activeTab
                  ? onDeleteSelfRegConfirm
                  : onDeleteLegalEntityConfirm
                : onConfirmClose
            }
          />
        )
      }
    </div>
  );
};

export default OwnLegalEntitiesScreen;
