import React, { useMemo, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import OnChangeComponent from 'components/forms/OnChangeComponent';
import { BILLING_DAY, BILLING_PERIOD } from 'consts';
import {
  useCompaniesApi,
  useDictionariesApi,
} from 'hooks/api';
import { useContentShadow } from 'hooks';
import { FormButtonsGroup } from 'components/blocks';
import {
  TextField,
  DropdownField,
  CheckboxField,
  MultiselectField,
} from 'components/fields';
import { SELF_REGISTRATION_SCHEMA } from 'consts/validationSchemas';

import FileField from './FileField';
import styles from './SelfRegistrationForm.module.scss';

const SelfRegistrationForm = ({
  changeMode,
  isPending,
  initialValues = {
    enabled: false,
    phoneVerify: false,
    companyModeration: false,
    company2FA: false,
    splitByMonth: false,
  },
  onSubmit,
  mode,
  onDelete,
  onCancel,
  packsOptions,
  servicesOptions,
}) => {
  const { t } = useTranslation();

  const fieldStyle = ({
    width,
    marginTop,
    marginBottom,
    maxHeight,
    marginRight,
    marginLeft,
    bottom,
    top,
  } = {}) => ({
    container: {
      width,
      marginRight,
      marginLeft,
      marginBottom,
    },
    label: {
      marginTop,
    },
    overlay: {
      maxHeight,
      bottom,
      top,
    },
  });

  const contentRef = useRef(null);
  const [currentCurrency, setCurrentCurrency] = useState(initialValues?.currency || '');
  const {
    portalsOptions,
    ownLegalEntitiesOptions,
  } = useDictionariesApi();
  const {
    timezones,
    currencies,
    integrationsOptions: integrationsOptionsAll,
  } = useDictionariesApi();
  const {
    ownCompany,
  } = useCompaniesApi();
  const {
    showTopShadow,
    showBottomShadow,
  } = useContentShadow(contentRef);
  const isView = useMemo(() => mode === 'view', [mode]);
  const timezonesOptions = useMemo(() => timezones
    .map(I => ({
      value: I.areaName,
      label: I.label,
    })), [timezones]);
  const integrationsOptions = useMemo(() => integrationsOptionsAll
    .filter(I => I.handlerKey === '2fa-sms-verify' && I.companyId === ownCompany.id)
    .map(I => ({
      value: I.value,
      label: `${I.label} (${I.senderId})`,
    })), [integrationsOptionsAll, ownCompany]);
  const filteredServicesOptions = useMemo(() => {
    if (currentCurrency) {
      return servicesOptions.filter(I => I.currency === currentCurrency);
    }
    return servicesOptions;
  }, [servicesOptions, currentCurrency]);
  const filteredPackOptions = useMemo(() => {
    if (currentCurrency) {
      return packsOptions.filter(I => I.currency === currentCurrency || I.type === 2);
    }
    return packsOptions;
  }, [packsOptions, currentCurrency]);
  const ownLegalEntitiesPortalsOptions = useMemo(
    () => ownLegalEntitiesOptions
      .filter(I => I.portalId)
      .map(({ portalId, value, label }) => ({
        value,
        label,
        portalId,
      })),
    [integrationsOptionsAll],
  );

  const changeToEdit = () => changeMode('edit');
  const onChangeLegalEntityId = ({ value, form }) => {
    if (form.dirty) {
      const targetLegalEntity = ownLegalEntitiesPortalsOptions
        ?.find(I => I.value === value)
        ?.portalId;
      form.setFieldValue('portalId', targetLegalEntity);
    }
  };
  const onChangeCurrency = ({ value, form }) => {
    if (form.dirty) {
      form.setFieldValue('services', []);
      form.setFieldValue('packs', []);
      setCurrentCurrency(value);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SELF_REGISTRATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleSubmit,
        errors,
        setFieldTouched,
      }) => (
        <Form className={styles.form}>
          {showTopShadow && <div className={styles.shadow} />}
          <div
            ref={contentRef}
            className={styles.content}
          >
            <span className={styles.title}>
              {t('SCREENS.SELF_REGISTRATION.GENERAL')}
            </span>
            <div className={styles.row}>
              <Field
                name="legalEntityId"
                component={DropdownField}
                label={t('SCREENS.SELF_REGISTRATION.OWN_LEGAL_ENTITIES')}
                options={ownLegalEntitiesPortalsOptions}
                disabled={isView}
                isRequired={!isView}
                placeholder="SCREENS.SELF_REGISTRATION.SELECT_LEGAL_ENTITY"
                styles={fieldStyle({ width: '100%' })}
              />
              <Field
                name="portalId"
                component={DropdownField}
                label={t('SCREENS.SELF_REGISTRATION.PORTAL')}
                options={portalsOptions}
                readOnly
                disabled={isView}
                isRequired={!isView}
                placeholder="SCREENS.SELF_REGISTRATION.SELECT_PORTAL"
                styles={fieldStyle({ width: '100%' })}
              />
            </div>
            <Field
              type="dense"
              name="enabled"
              component={CheckboxField}
              styles={fieldStyle({ marginTop: 24, marginBottom: 24 })}
              text={t('SCREENS.SELF_REGISTRATION.SELF_REGISTRATION_ENABLED')}
              disabled={isView}
            />
            <div className={styles.row}>
              <Field
                name="terms"
                component={FileField}
                label={t('SCREENS.SELF_REGISTRATION.TERMS_OF_SERVICE')}
                hint={t('SCREENS.SELF_REGISTRATION.FORMATS_AVAILABLE_PDF')}
                inputStyle={{ width: 367.5, height: 40 }}
                disabled={isView}
                isRequired={!isView}
                full
              />
              <Field
                name="policy"
                component={FileField}
                label={t('SCREENS.SELF_REGISTRATION.PRIVACY_POLICY')}
                hint={t('SCREENS.SELF_REGISTRATION.FORMATS_AVAILABLE_PDF')}
                inputStyle={{ width: 367.5, height: 40 }}
                disabled={isView}
                isRequired={!isView}
                full
              />
            </div>
            <Field
              type="dense"
              name="phoneVerify"
              component={CheckboxField}
              styles={fieldStyle({ marginTop: 24, marginBottom: 24 })}
              text={t('SCREENS.SELF_REGISTRATION.MOBILE_PHONE_VERIFICATION')}
              disabled={isView}
              full
            />
            <div className={styles.row}>
              <Field
                name="integrationId"
                component={DropdownField}
                label={t('SCREENS.SELF_REGISTRATION.INTEGRATION')}
                options={integrationsOptions}
                disabled={isView}
                isRequired={!isView}
                placeholder="SCREENS.SELF_REGISTRATION.SELECT_INTEGRATION"
                styles={fieldStyle({ width: '100%' })}
              />
              <Field
                name="reportEmail"
                component={TextField}
                label={t('SCREENS.SELF_REGISTRATION.REPORT_EMAIL')}
                styles={fieldStyle({ width: '100%' })}
                placeholder="SCREENS.SELF_REGISTRATION.REPORT_EMAIL"
                disabled={isView}
                isRequired={!isView}
                full
              />
            </div>
            <div className={styles.row}>
              <Field
                type="dense"
                name="companyModeration"
                styles={fieldStyle({ marginTop: 24, marginBottom: 24 })}
                component={CheckboxField}
                text={t('SCREENS.SELF_REGISTRATION.MODERATION')}
                disabled={isView}
                full
              />
              <Field
                type="dense"
                name="company2FA"
                styles={fieldStyle({ marginTop: 24, marginBottom: 24 })}
                component={CheckboxField}
                text={t('SCREENS.SELF_REGISTRATION.2FA')}
                disabled={isView}
                full
              />
            </div>
            <span className={styles.title}>
              {t('SCREENS.SELF_REGISTRATION.AGREEMENT_DETAILS')}
            </span>
            <Field
              name="codePrefix"
              component={TextField}
              label={t('SCREENS.SELF_REGISTRATION.AGREEMENT_PREFIX')}
              styles={fieldStyle({ marginBottom: 24 })}
              placeholder="SCREENS.SELF_REGISTRATION.ENTER_AGREEMENT_PREFIX"
              disabled={isView}
              isRequired={!isView}
              full
            />
            <div className={styles.row} style={{ marginBottom: 24 }}>
              <Field
                name="currency"
                component={DropdownField}
                label={t('SCREENS.SELF_REGISTRATION.CURRENCY')}
                options={currencies}
                placeholder="SCREENS.SELF_REGISTRATION.SELECT_CURRENCY"
                disabled={isView}
                isRequired={!isView}
                styles={fieldStyle({ width: '100%' })}
              />
              <Field
                name="creditLimit"
                component={TextField}
                label={t('SCREENS.SELF_REGISTRATION.CREDIT_LIMIT')}
                placeholder="SCREENS.SELF_REGISTRATION.ENTER_CREDIT_LIMIT"
                disabled={isView}
                isRequired={!isView}
                full
              />
            </div>
            <div className={styles.row}>
              <Field
                name="billingTz"
                component={DropdownField}
                label={t('SCREENS.SELF_REGISTRATION.BILLING_TIMEZONE')}
                options={timezonesOptions}
                placeholder="SCREENS.SELF_REGISTRATION.SELECT_BILLING_TIMEZONE"
                disabled={isView}
                isRequired={!isView}
                styles={fieldStyle({ width: '100%' })}
              />
              <Field
                name="billingDay"
                component={DropdownField}
                label={t('SCREENS.SELF_REGISTRATION.BILLING_DAY')}
                options={BILLING_DAY}
                placeholder="SCREENS.SELF_REGISTRATION.SELECT_BILLING_DAY"
                disabled={isView}
                isRequired={!isView}
                styles={fieldStyle({ width: '100%' })}
              />
              <Field
                name="billingPeriod"
                component={DropdownField}
                label={t('SCREENS.SELF_REGISTRATION.BILLING_PERIOD')}
                options={BILLING_PERIOD}
                placeholder="SCREENS.SELF_REGISTRATION.SELECT_BILLING_PERIOD"
                disabled={isView}
                isRequired={!isView}
                styles={fieldStyle({ width: '100%' })}
              />
            </div>
            <Field
              type="dense"
              name="splitByMonth"
              styles={fieldStyle({ marginTop: 24, marginBottom: 24 })}
              component={CheckboxField}
              text={t('SCREENS.SELF_REGISTRATION.SPLIT_BY_MONTH')}
              disabled={isView}
            />
            <span className={styles.title}>
              {t('SCREENS.SELF_REGISTRATION.SERVICES_AND_PACKS')}
            </span>
            <div className={styles.row}>
              <Field
                name="services"
                component={MultiselectField}
                label={t('SCREENS.SELF_REGISTRATION.SERVICES')}
                allowCreate={false}
                options={filteredServicesOptions}
                disabled={isView || !values?.currency}
                isRequired={!isView}
                styles={fieldStyle({
                  width: '100%',
                  bottom: '100%',
                  top: 'unset',
                })}
              />
              <Field
                name="packs"
                component={MultiselectField}
                label={t('SCREENS.SELF_REGISTRATION.PACKS')}
                allowCreate={false}
                options={filteredPackOptions}
                disabled={isView || !values?.currency}
                styles={fieldStyle({
                  width: '100%',
                  bottom: '100%',
                  top: 'unset',
                })}
              />
            </div>
          </div>
          <FormButtonsGroup
            onDelete={onDelete}
            onCancel={onCancel}
            onApply={isView ? changeToEdit : handleSubmit}
            mode={mode}
            errors={errors}
            setFieldTouched={setFieldTouched}
            isPending={isPending}
            showShadow={showBottomShadow}
          />
          <OnChangeComponent field="legalEntityId" onChange={onChangeLegalEntityId} />
          <OnChangeComponent field="currency" onChange={onChangeCurrency} />
        </Form>
      )}
    </Formik>
  );
};

export default SelfRegistrationForm;
