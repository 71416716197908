import React, { useMemo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Badge from '@setproduct-ui/core/Badge/Badge';
import Button from '@setproduct-ui/core/Button';

import './style.scss';

const ScreenHeader = ({
  title,
  openColumns,
  openForm,
  buttonText,
  buttonView = 'filled',
  classNames = {},
  showFilters = true,
  showColumns = true,
  isOpen,
  iconColor = 'white',
  dense,
}) => {
  const headerClassName = cx('screen-header', {
    [classNames.screenHeader]: !!classNames.screenHeader,
  });
  const titleClassName = cx('screen-header__title', {
    'screen-header__title_dense': dense,
  });
  const columnsButtonClass = cx({
    'screen-header__columns': buttonText || openForm,
  });

  const { t } = useTranslation();

  const dataTestId = useMemo(() => `${title ? `/${title.toLowerCase()?.replace('.', '-')}` : ''}`, [title]);

  return (
    <div className={headerClassName}>
      <span
        data-testid={`${window.location.pathname}${dataTestId}/title`}
        className={titleClassName}
      >
        {t(title)}
      </span>
      <div>
        {
          showFilters
          && (process.env.NODE_ENV !== 'production'
            || window.location.hostname.includes('mkitdev2.alarislabs'))
          && (
            <Badge
              view="outlined"
              color="primary"
              value="5"
              className="screen-header__badge"
            >
              <Button
                type={dense ? 'default' : 'card'}
                view="outlined"
                color="primary"
                icon="settings"
                className="screen-header__prime-button"
                text="CONTROLS.FILTERS"
                dense
                data-testid={`${window.location.pathname}${dataTestId}/filters`}
              />
            </Badge>
          )
        }
        {
          showColumns && (
            <Button
              type={dense ? 'default' : 'card'}
              view="outlined"
              color="primary"
              icon="add-column-right"
              className={columnsButtonClass}
              onClick={openColumns}
              text="CONTROLS.COLUMNS"
              dense
              data-testid={`${window.location.pathname}${dataTestId}/columns`}
            />
          )
        }
        {
          (openForm || buttonText) && (
            <Button
              icon="plus"
              view={buttonView}
              iconColor={iconColor}
              color="primary"
              className="screen-header__button"
              onClick={openForm}
              disabled={isOpen}
              text={buttonText}
              dense={dense}
              data-testid={`${window.location.pathname}${dataTestId}/create-instance`}
            />
          )
        }
      </div>
    </div>
  );
};

export default ScreenHeader;
