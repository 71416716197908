import endpoints from 'consts/endpoints';

export default ({
  id,
  body,
  successCallback,
  errorCallback,
} = {}) => ({
  url: endpoints.getUpdateUserPasswordUrl(id),
  body,
  options: {
    method: 'PUT',
  },
  meta: {
    authToken: true,
    successCallback,
    errorCallback,
  },
});
