import { Typography } from 'components';
import Button from '@setproduct-ui/core/Button';

import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css';
import { useTranslation } from 'react-i18next';
import React, {
  useState, useEffect, useMemo,
} from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import ReportBlockPanel from './ReportBlockPanel';
import ReportListPanel from './ReportListPanel';
import TabItem from './TabItem';

import './style.scss';

const RunReportBlock = ({
  list = [],
  onSelectReport,
  editableReport,
  reportParameters,
  postCreateReport,
  onClose,
  onNewReport,
  onEditReport,
  activeTab,
  setActiveTab,
  handleChangeTab,
  reports,
  isPendingPostCreateReport,
  getGroups,
  internalReports,
  setInternalReports,
  childrenValuesRef,
  history,
  formValuesRef,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeTab: activeRouteTab } = useSelector(state => state.states.routes);

  const [selectedSection, setSelectedSection] = useState({});
  const [selectedSubsection, setSelectedSubsection] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [showMore, setShowMore] = useState(true);

  const initialFormValues = useMemo(() => {
    if (history.location.state) {
      const data = JSON.parse(history.location.state.data);
      return data.fv;
    }

    return null;
  }, [history.length, history.location.state]);

  const getFilterType = (type) => {
    switch (type) {
      case 'Number field':
        return 'numberColumnFilter';
      case 'Text field':
        return 'maskColumnFilter';
      case 'Date':
        return 'dateColumnFilter';
      default:
        return false;
    }
  };

  const modifyReports = useMemo(() => reports.map(report => ({
    name: report.name,
    templateId: report.templateId,
    columns: report.attributes?.map((attribute) => {
      const columnParams = attribute.name.split('|');
      const columnName = columnParams.shift();
      return {
        columnParams,
        headerName: columnName,
        field: attribute.name,
        cellRenderer: 'cellRendererReport',
        filter: getFilterType(attribute.type),
        flex: 1,
        valueFormatter: attribute.type === 'Date'
          ? ({ value }) => moment.utc(value).format('DD.MM.YYYY HH:mm:ss')
          : undefined,
        headerComponentParams: {
          withoutExclude: true,
        },
      };
    }),
    size: report.size,
    offset: report.offset,
    limit: report.limit,
    list: report.values,
    initialParameters: report.parameters,
    parameters: report.parameters.reduce((acc, item) => ({
      ...acc,
      [item.bindName]: item.parameterType === 'Date' && item.value
        ? moment(item.value) : item.value,
    }), {}),
  })), [reports]);

  const displayedList = useMemo(() => {
    const modifySearchValue = searchValue.trim().toLowerCase();
    let resultList = [...list];
    resultList = resultList.reduce((acc, currVal) => {
      const mReports = currVal.reports.filter(R =>
        R.public === (internalReports !== 'internal')
        && R.name.toLowerCase().includes(modifySearchValue));
      if (mReports.length) {
        acc.push({
          ...currVal,
          reports: mReports,
        });
      }
      return acc;
    }, []);
    return resultList;
  }, [list, searchValue, internalReports]);
  const initialValues = useMemo(() => {
    if (editableReport.id === selectedSubsection.id
      && !isEmpty(editableReport.initialValues)) {
      return editableReport.initialValues;
    }

    return reportParameters.reduce((acc, item) => {
      if (item.parameterType === 'Date'
        || item.parameterType === 'Date and time'
        || item.parameterType === 'Month and year'
        || item.parameterType === 'Year'
      ) {
        const date = new Date(item.defaultExpression);

        if (date.toString() !== 'Invalid Date') {
          return {
            ...acc,
            [item.bindName]: date,
          };
        }
        return {
          ...acc,
        };
      } if (item.parameterType === 'Multiselect') {
        if (item.defaultExpression?.includes('|')) {
          const defaultValues = item.defaultExpression.split('|');
          const fundedValues = item.value.filter(I => defaultValues.includes(I));
          return {
            ...acc,
            [item.bindName]: fundedValues,
          };
        }
        if (item.value.find(I => I === item.defaultExpression)) {
          return {
            ...acc,
            [item.bindName]: [item.defaultExpression],
          };
        }
        return {
          ...acc,
        };
      } if (item.parameterType === 'Combobox') {
        if (item.value.find(I => I === item.defaultExpression)) {
          return {
            ...acc,
            [item.bindName]: item.defaultExpression,
          };
        }
        return {
          ...acc,
        };
      } if (item.parameterType === 'Spinner field'
        || item.parameterType === 'Number field'
      ) {
        const number = Number.parseInt(item.defaultExpression, 10);
        if (!Number.isNaN(number)) {
          return {
            ...acc,
            [item.bindName]: item.defaultExpression,
          };
        }
        return {
          ...acc,
        };
      }
      return {
        ...acc,
        [item.bindName]: item.defaultExpression,
      };
    }, {});
  }, [editableReport, selectedSubsection, reportParameters]);

  const onSelectSubsection = (template) => {
    setSelectedSubsection(template);
    onSelectReport(template.id);
  };

  const onRunReport = (values) => {
    const equalReportsCount = reports
      .filter(report => report.templateId === selectedSubsection.id).length;
    let reportName = `(${equalReportsCount + 1}) ${selectedSection.title}: ${selectedSubsection.title}`;

    if (reports.find(R => R.name === reportName)) {
      reportName = `(${equalReportsCount + 2}) ${selectedSection.title}: ${selectedSubsection.title}`;
    }
    let valuesModify = {};

    const getMomentValue = (key) => {
      const valueModify = values[key];
      const { parameterType } = reportParameters.find(I => I.bindName === key);

      switch (parameterType) {
        case 'Date':
          valueModify.set('hour', 0).set('minute', 0).set('second', 0);
          break;
        case 'Month and year':
          valueModify.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
          break;
        case 'Year':
          valueModify.set('month', 0).set('date', 1).set('hour', 0).set('minute', 0)
            .set('second', 0);
          break;
        default: break;
      }

      return `${moment(valueModify).format('YYYY-MM-DDTHH:mm:ss')}Z`;
    };

    Object.keys(values).forEach((key) => {
      valuesModify = {
        ...valuesModify,
        [key]: moment.isMoment(values[key]) ? getMomentValue(key) : values[key],
      };
    });

    postCreateReport({
      body: {
        name: reportName,
        templateId: selectedSubsection.id,
        params: {
          ...valuesModify,
        },
        limit: 100,
        offset: 0,
      },
      successCallback: () => {
        setActiveTab(reportName);
        setShowMore(false);
        setTimeout(() => {
          setShowMore(true);
        });
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(editableReport)) {
      const section = list.find(item => item.reports
        .find(R => R.id === editableReport.id));
      const subsection = section.reports
        .find(item => item.id === editableReport.id);

      onSelectReport(subsection.id);
      setSelectedSection({ id: section.id, title: section.name });
      setSelectedSubsection({
        id: subsection.id,
        title: subsection.name,
        description: subsection.description,
      });
    }
  }, [editableReport]);

  useEffect(() => {
    getGroups();
  }, []);
  useEffect(() => {
    childrenValuesRef.current = {
      ...(childrenValuesRef.current || {}),
      ss: selectedSection,
      sb: selectedSubsection,
      sv: searchValue,
      sm: showMore,
    };

    if (activeRouteTab) {
      dispatch({
        type: 'saveRoute',
        cv: childrenValuesRef.current,
      });
    }
  }, [selectedSection, selectedSubsection, searchValue, showMore]);
  useEffect(() => {
    if (history.location.state) {
      const data = JSON.parse(history.location.state.data);
      const { cv = {} } = data;
      const {
        ss: initialSelectedSection,
        sb: initialSelectedSubsection,
        sv: initialSearchValue,
        sm: initialShowMore,
      } = cv;

      setSelectedSection(initialSelectedSection);
      setSelectedSubsection(initialSelectedSubsection);
      setSearchValue(initialSearchValue);
      setShowMore(initialShowMore);
    } else {
      setSelectedSection({});
      setSelectedSubsection({});
      setSearchValue('');
      setShowMore(true);
    }
  }, [history.length, history.location.state]);

  const tabs = useMemo(() => {
    const items = [...modifyReports];
    items.unshift({ id: 'control', name: t('SCREENS.REPORTS.REPORTS_LIST') });
    return items.map((I) => {
      if (I.id === 'control') {
        return {
          title: <TabItem title={I.name} main />,
          // eslint-disable-next-line react/no-unstable-nested-components
          getContent: () => (
            <ReportListPanel
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              displayedList={displayedList}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              selectedSubsection={selectedSubsection}
              onSelectSubsection={onSelectSubsection}
              reportParameters={reportParameters}
              initialValues={initialValues}
              onRunReport={onRunReport}
              onEditReport={onEditReport}
              isPendingPostCreateReport={isPendingPostCreateReport}
              setInternalReports={setInternalReports}
              internalReports={internalReports}
              formValuesRef={formValuesRef}
              initialFormValues={initialFormValues}
            />
          ),
          key: I.id,
          tabClassName: 'run-report-block__tab',
          panelClassName: 'run-report-block__panel',
        };
      }
      return {
        title: <TabItem
          title={I.name}
          main={false}
          removable
          onRemove={() => {
            const nameReport = I.name.replace(/\s+/g, '').toLowerCase();
            sessionStorage.removeItem(`columns_${window.location.pathname}_${nameReport}`);
            sessionStorage.removeItem(`filters_${window.location.pathname}_${nameReport}`);
            onClose(I.name);
          }}
        />,
        // eslint-disable-next-line react/no-unstable-nested-components
        getContent: () => (
          <ReportBlockPanel
            report={I}
            isPendingPostCreateReport={isPendingPostCreateReport}
            postCreateReport={postCreateReport}
          />
        ),
        key: I.name,
        tabClassName: 'run-report-block__tab',
        panelClassName: 'run-report-block__panel',
      };
    });
  }, [
    selectedSection,
    selectedSubsection,
    displayedList,
    reportParameters,
    modifyReports,
    isPendingPostCreateReport,
  ]);

  return (
    <div className="run-report-block">
      <div className="run-report-block__header">
        <div className="run-report-block__title">
          <Typography type="h5">SCREENS.REPORTS.REPORT</Typography>
        </div>
        {activeTab === 'control' && (
        <Button
          dense
          icon="plus"
          type="action"
          view="filled"
          color="primary"
          className="run-report-block__button"
          onClick={onNewReport}
        >
          <Typography
            tagName="span"
            className="run-report-block__button-text"
          >
            CONTROLS.REPORTS.NEW_REPORT
          </Typography>
        </Button>
        )}
      </div>
      <Tabs
        selectedTabKey={activeTab}
        items={tabs}
        showInkBar
        onChange={handleChangeTab}
        showMore={showMore}
        transform={false}
      />
    </div>
  );
};

export default RunReportBlock;
