import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Icon } from '@blueprintjs/core';

import './style.scss';

const ScrollableTabs = ({
  tabsList,
  onTabClose,
  renderPanel,
  styles = {},
  activeTab,
  setActiveTab,
  elementInTabsPanel = null,
  alwaysShowTabsPanelElement = false,
}) => {
  const onChangeTab = (e, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div className="scrollable-tabs" style={styles.wrapper}>
      <div className="scrollable-tabs__tabs-panel-wrapper">
        <Tabs
          variant="scrollable"
          value={activeTab}
          onChange={onChangeTab}
          className="scrollable-tabs__tabs"
        >
          {tabsList.map((tab, index) => (
            <Tab
              id={`scrollable-tab-${index}`}
              aria-controls={`tab-panel-${index}`}
              data-testid={`${window.location.pathname}/scrollable-tabs/${tab.id || tab.title}`}
              key={tab.id || tab.title}
              label={(
                <div className="scrollable-tabs__tabs-list-item">
                  <span className="scrollable-tabs__tabs-list-item-text">{tab.title}</span>
                  {tab.withoutCross ? null : (
                    <Icon
                      icon="cross"
                      size={12}
                      color="var(--grey70)"
                      className="scrollable-tabs__close-tab-button"
                      onClick={e => onTabClose(e, index)}
                    />
                  )}
                </div>
              )}
            />
          ))}
        </Tabs>
        {/* в случае, если в будущем нужны будут элементы в панели не только для первой вкладки - нужно переделать */}
        {(activeTab === 0 || alwaysShowTabsPanelElement) && elementInTabsPanel}
      </div>
      {tabsList.map((tab, index) => (
        <div
          role="tabpanel"
          hidden={index !== activeTab}
          id={`tab-panel-${index}`}
          aria-labelledby={`scrollable-tab-${index}`}
          key={tab.id || tab.title}
        >
          {renderPanel(tab, index)}
        </div>
      ))}
    </div>
  );
};

export default ScrollableTabs;
