import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useCompaniesApi } from 'hooks/api';
import { paths, routes } from 'routes';
import endpoints from 'consts/endpoints';

import SidebarItem from './SidebarItem';

import './style.scss';

const Sidebar = () => {
  const asideShow = useSelector(state => state.states.asideShow);
  const sidebarClasses = classNames('sidebar', {
    sidebar_thin: !asideShow,
    sidebar_wide: asideShow,
  });
  const { ownCompany } = useCompaniesApi();

  const {
    logoSquare,
    logoExpanded,
  } = useMemo(() => {
    try {
      const parsedPortal = JSON.parse(localStorage.getItem('boPortal'));

      return {
        logoSquare: endpoints.getPortalAssetsUrl(parsedPortal.logoSquareDark),
        logoExpanded: endpoints.getPortalAssetsUrl(parsedPortal.logoExpandedDark),
      };
    } catch (error) {
      return {};
    }
  }, []);

  const renderLinks = useCallback(
    () =>
      routes.filter(route => !(route.isForTenantOnly && ownCompany.parentCompanyId)).map(({
        path,
        children,
        isForTenantOnly,
        ...rest
      }) => {
        // Костыль для текущей логики скрытия интерфейсов реселлера
        if (path === '/settings' && ownCompany.parentCompanyId) {
          return (
            <SidebarItem
              key="/own-legal-entities"
              path="/own-legal-entities"
              asideShow={asideShow}
              name="TABS.OWN_LEGAL_ENTITIES"
              icon="cog"
            />
          );
        }
        if (path === '/mcc-mnc' && !(process.env.NODE_ENV !== 'production' || window.location.hostname.includes('mkitdev2.alarislabs'))) {
          return null;
        }
        return (
          <SidebarItem
            key={path}
            path={path}
            asideShow={asideShow}
            childrenRoutes={children?.filter(route => !(route.isForTenantOnly && ownCompany.parentCompanyId))}
            {...rest}
          />
        );
      }),
    [asideShow, ownCompany],
  );

  return (
    <aside className={sidebarClasses}>
      <Link className="sidebar__header" to={paths.dashboard}>
        <img src={asideShow ? logoExpanded : logoSquare} alt="" />
      </Link>
      <nav className="sidebar__links">{renderLinks()}</nav>
    </aside>
  );
};

export default Sidebar;
