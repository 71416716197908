import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';

import {
  getRoles as getRolesApi,
  getPermissions as getPermissionsApi,
} from 'queries/access';

import {
  getArray,
  useISESelector,
} from './selectors';

export default () => {
  const [
    {
      isPending: isPendingGetRoles,
      lastUpdated: lastUpdatedGetRoles,
      isFinished: isFinishedGetRoles,
    },
    getRoles,
  ] = useMutation(getRolesApi);
  const [
    {
      isPending: isPendingGetPermissions,
      lastUpdated: lastUpdatedGetPermissions,
      isFinished: isFinishedGetPermissions,
    },
    getPermissions,
  ] = useMutation(getPermissionsApi);

  const selector = useCallback(state => ({
    boRoles: getArray(state, 'boRoles'),
    boPermissions: getArray(state, 'boPermissions'),
    mkitRoles: getArray(state, 'mkitRoles'),
    mkitPermissions: getArray(state, 'mkitPermissions'),
  }), []);
  const data = useISESelector(selector);

  return {
    getRoles,
    getPermissions,
    isPendingGetRoles,
    isFinishedGetRoles,
    isPendingGetPermissions,
    isFinishedGetPermissions,
    lastUpdatedGetRoles,
    lastUpdatedGetPermissions,
    ...data,
  };
};
