import { Icon } from '@blueprintjs/core';
import { useMeasure } from 'react-use';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import React, { useMemo, useState } from 'react';

import {
  CLIENT_PROFILE_TEMPLATES,
  CLIENT_PROFILE_COMMUNICATION_HISTORY,
} from 'consts/columns';
import Table from 'components/Table';
import SearchInput from '@setproduct-ui/core/Inputs/SearchInput';
import { Pagination, Typography } from 'components';

import './style.scss';

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
  },
];

const paginatorStyles = {
  container: {
    marginTop: 18,
    justifyContent: 'flex-end',
  },
};

export default () => {
  const [ref, { width }] = useMeasure();
  const [searchValue, setSearchValue] = useState('');

  const images = useMemo(
    () =>
      (searchValue
        ? itemData.filter(I => I.title.toLowerCase().includes(searchValue.toLowerCase()))
        : itemData),
    [searchValue],
  );

  return (
    <div className="content" ref={ref}>
      <div className="content__templates-table__wrapper">
        <Typography large>INSTANCES.CLIENT_PROFILE.TEMPLATES</Typography>
        <Table
          className="content__templates-table__table"
          classNames={{ screenHeader: 'content__templates-table__header', table: 'content__templates-table__table-wrapper' }}
          showFilters={false}
          showColumns={false}
          columnDefs={CLIENT_PROFILE_TEMPLATES}
          rowData={[]}
          total={10}
          rowBuffer={5}
          newDesign
        />
      </div>
      <div className="content__communication-table__wrapper">
        <Typography large>INSTANCES.CLIENT_PROFILE.COMMUNICATIONS_HISTORY</Typography>
        <Table
          className="content__communication-table__table"
          classNames={{ screenHeader: 'content__communication-table__header', table: 'content__communication-table__table-wrapper' }}
          showFilters={false}
          showColumns={false}
          columnDefs={CLIENT_PROFILE_COMMUNICATION_HISTORY}
          rowData={[]}
          total={10}
          rowBuffer={5}
          newDesign
        />
      </div>
      <div className="content__graph-content">
        <Typography large className="content__graph-content__title">INSTANCES.CLIENT_PROFILE.GRAPHIC_CONTENT</Typography>
        <SearchInput
          type="def"
          view="outlined"
          color="default"
          placeholder="Search"
          onChange={setSearchValue}
          value={searchValue}
          fill
        />
        <ImageList className="gallery" sx={{ width }} cols={Math.floor(width / 190)} gap={24} rowHeight={164}>
          {images.map(item => (
            <ImageListItem
              sx={{ height: 164, width: 164 }}
              className="gallery__item"
              key={item.img}
            >
              <div className="gallery__item__overlay">
                <Typography small className="gallery__item__overlay-name">thUP.png PNG 512 x 461 px 264.63 KB</Typography>
                <div className="gallery__item__overlay-icons">
                  <Icon
                    icon="import"
                    iconSize={20}
                    color="var(--white)"
                    className="gallery__item__overlay-icon"
                  />
                  <Icon
                    icon="trash"
                    iconSize={20}
                    color="var(--white)"
                    className="gallery__item__overlay-icon"
                  />
                </div>
              </div>
              <img
                name={item.img}
                className="gallery__item__image"
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Pagination
          total={100}
          limit={12}
          page={1}
          styles={paginatorStyles}
        />
      </div>
    </div>
  );
};
