import endpoints from 'consts/endpoints';

export default ({
  limit = 10000,
  offset = 0,
  ...queries
} = {}) => ({
  url: endpoints.getUsersUrl(),
  body: {
    limit,
    offset: offset ? ((offset - 1) * limit) : offset,
    group: 'backoffice',
    ...queries,
  },
  options: {
    method: 'GET',
  },
  meta: {
    authToken: true,
  },
  transform: ({ resourceList }) => ({
    boUsersOptions: resourceList.map(({
      id,
      firstName,
      lastName,
      username,
      ...rest
    }) => ({
      value: id,
      label: `${firstName} ${lastName} (${username})`,
      ...rest,
    })),
    boUsersDictionary: resourceList.reduce((acc, { id, ...values }) => {
      acc[id] = {
        ...values,
      };

      return acc;
    }, {}),
  }),
  update: {
    boUsersOptions: (oldValue, newValue) => newValue,
    boUsersDictionary: (oldValue, newValue) => newValue,
  },
});
