import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { Icon } from '@blueprintjs/core';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import Button from '@setproduct-ui/core/Button';
import Spinner from '@setproduct-ui/core/Spinner';

import './style.scss';

const ButtonDropdown = ({
  options,
  buttonText,
  buttonStyle,
  buttonIcon,
  buttonColor,
  targetStyle = {},
  loading,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);

  const onItemClick = (callback) => {
    callback();
    setIsOpenMenu(false);
  };

  return (
    <Popover2
      renderTarget={({ ref, ...targetProps }) => (
        <div
          ref={ref}
          style={targetStyle}
          {...targetProps}
        >
          <Button
            text={buttonText}
            title={t(buttonText)}
            view="smooth"
            style={buttonStyle}
            icon={loading ? <Spinner size={20} /> : buttonIcon}
            onClick={setIsOpenMenu}
            color={buttonColor}
            data-testid={dataTestId}
          />
        </div>
      )}
      content={(
        <div className="button-dropdown__overlay">
          {options.map(item => (
            <div
              role="presentation"
              onClick={() => onItemClick(item.onClick)}
              className="button-dropdown__item"
              key={item.label}
            >
              {!!item.icon && (
                <Icon
                  icon={item.icon}
                  size={16}
                  color={`var(--${item.iconColor || 'green'}60)`}
                  style={{ marginRight: 6 }}
                />
              )}
              {t(item.label)}
            </div>
          ))}
        </div>
      )}
      placement="bottom-start"
      transitionDuration={0}
      isOpen={isOpenMenu}
      interactionKind="click"
      onClose={() => setIsOpenMenu(false)}
      minimal
      usePortal
    />
  );
};

export default ButtonDropdown;
