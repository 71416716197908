import { useEffect } from 'react';

const useAsideOpened = (isOpen) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('asideOpened');
    } else {
      document.body.classList.remove('asideOpened');
    }
    return () => {
      document.body.classList.remove('asideOpened');
    };
  }, [isOpen]);
};

export default useAsideOpened;
