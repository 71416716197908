/* eslint-disable */
import { Typography } from 'components';
import { FlatDropdown } from 'components/fields';
import Table from 'components/Table';
import { CLIENT_PROFILE_CHANNELS, CLIENT_PROFILE_SENDERS } from 'consts/columns';

// import MultilineChart from 'mkit-front-ds/dist/components/charts/MultilineChart';
import React from 'react';

import './style.scss';

// const data = [
//   {
//     month: 'Jan',
//     channel: 'SMS',
//     trafficCount: 11000,
//   },
//   {
//     month: 'Feb',
//     channel: 'SMS',
//     trafficCount: 18000,
//   },
//   {
//     month: 'Mar',
//     channel: 'SMS',
//     trafficCount: 20000,
//   },
//   {
//     month: 'Jan',
//     channel: 'RCS',
//     trafficCount: 1000,
//   },
//   {
//     month: 'Feb',
//     channel: 'RCS',
//     trafficCount: 15000,
//   },
//   {
//     month: 'Mar',
//     channel: 'RCS',
//     trafficCount: 13000,
//   },
// ];

// const colors = ['#FB982E', '#F6655A', '#5C6BC0', '#65B168'];

const channelsData = [
  {
    channel: 'RCS',
    connected: '10.07.19',
    disabled: '',
    optOut: '0',
    api: '10.07.19',
  },
  {
    channel: 'SMS',
    connected: '01.11.18',
    disabled: '',
    optOut: '1445',
    api: '13.09.19',
  },
  {
    channel: 'Viber',
    connected: '09.02.19',
    disabled: '13.10.19',
    optOut: '300',
    api: '',
  },
  {
    channel: 'WhatsApp',
    connected: '04.05.20',
    disabled: '04.01.21',
    optOut: '0',
    api: '04.05.20',
  },
];

const sendersData = [
  {
    channel: 'SMS',
    senderId: 'Aquamen',
    direction: 'MTS',
    connected: '01.11.18',
    templates: '2',
  },
  {
    channel: 'SMS',
    senderId: 'Aquamen',
    direction: 'Megafon',
    connected: '01.11.18',
    templates: '2',
  },
  {
    channel: 'SMS',
    senderId: 'Aquamen',
    direction: 'Beeline',
    connected: '01.11.18',
    templates: '4',
  },
  {
    channel: 'SMS',
    senderId: '38784ххххххх',
    direction: 'All Europe',
    connected: '11.12.18',
  },
  {
    channel: 'RCS',
    senderId: 'Aquamen',
    direction: 'All Europe',
  },
  {
    channel: 'Viber',
    senderId: 'Aquamen',
    direction: 'All Rus',
    connected: '01.02.19',
    disabled: '13.10.19',
  },
  {
    channel: 'WhatsApp',
    senderId: 'Aquamen',
    direction: 'World',
    connected: 43955,
    disabled: 44200,
    templates: '4',
  },
];

const PERIODS = [
  { value: '0', label: 'За 3 месяца' },
  { value: '0', label: 'За 1 год' },
  { value: '0', label: 'С момента регистрации' },
];

export default () => {
  const fieldStyle = ({
    marginBottom,
  }) => ({
    container: {
      marginBottom,
    },
  });

  return (
    <div className="activity">
      <div className="activity__header-blocks">
        <div className="activity__header-blocks__item activity__header-blocks__item_abonents">
          <Typography small colorStep={40}>Абонентов в базе</Typography>
          <div className="activity__header-blocks__item_bottom">
            <Typography type="h4">3 456</Typography>
            <FlatDropdown
              onChange={() => {}}
              options={PERIODS}
              value="0"
              styles={fieldStyle({ marginBottom: 7 })}
            />
          </div>
        </div>
        <div className="activity__header-blocks__item activity__header-blocks__item_companies">
          <Typography small colorStep={50}>Кампаний и получателей</Typography>
          <div className="activity__header-blocks__item_bottom">
            <Typography type="h4">12/500</Typography>
            <FlatDropdown
              onChange={() => {}}
              options={PERIODS}
              value="0"
              styles={fieldStyle({ marginBottom: 5 })}
            />
          </div>
        </div>
        <div className="activity__header-blocks__item activity__header-blocks__item_dialogs">
          <div className="activity__header-blocks__item_left">
            <Typography small colorStep={50}>Диалогов и участников</Typography>
            <Typography type="h4">57/100</Typography>
          </div>
        </div>
      </div>
      <div className="activity__content">
        <div className="activity__content__table-wrapper">
          <Typography large>INSTANCES.CLIENT_PROFILE.CHANNELS</Typography>
          <Table
            className="activity__content__table"
            classNames={{ screenHeader: 'activity__content__table-header', table: 'activity__content__wrapper' }}
            withPagination={false}
            showFilters={false}
            showColumns={false}
            columnDefs={CLIENT_PROFILE_CHANNELS}
            rowData={channelsData}
            total={10}
            rowHeight={40}
            denseRowHeight={40}
            rowBuffer={5}
          />
        </div>
        <div className="activity__content__graph-wrapper">
          <div className="activity__content__graph-header">
            <Typography large className="activity__content__graph-title">INSTANCES.CLIENT_PROFILE.TRAFFIC</Typography>
            <FlatDropdown
              onChange={() => {}}
              options={PERIODS}
              value="0"
            />
          </div>
          <div className="activity__content__graph">
            Soon
          </div>
        </div>
      </div>
      <div className="activity__senders-table__wrapper">
        <Typography large className="activity__senders-table__title">INSTANCES.CLIENT_PROFILE.SENDERS</Typography>
        <Table
          className="activity__senders-table__table"
          classNames={{ screenHeader: 'activity__senders-table__header', table: 'activity__senders-table__wrapper' }}
          withPagination={false}
          showFilters={false}
          showColumns={false}
          columnDefs={CLIENT_PROFILE_SENDERS}
          rowData={sendersData}
          total={10}
          rowHeight={40}
          denseRowHeight={40}
          rowBuffer={5}
        />
      </div>
    </div>
  );
};
