import React, {
  useEffect, useMemo,
} from 'react';

import { CustomColumnsView } from 'components/blocks';
import Table from 'components/Table';
import { Modal } from 'components';
import { ConfirmModal } from 'components/modals';
import { USERS } from 'consts/columns';
import {
  useAccessApi,
  useDictionariesApi,
  useUsersApi,
} from 'hooks/api';
import { UsersForm } from 'components/forms';
import {
  BOUSERS_SCHEMA,
  PASSWORD_SCHEMA,
  PASSWORD_LENGTH_SCHEMA,
} from 'consts/validationSchemas';
import { useScreen } from 'hooks';

export default ({ screenRef }) => {
  // hooks
  const {
    getRoles,
    getPermissions,
    boRoles,
    boPermissions,
  } = useAccessApi();
  const {
    countries,
    timezones,
    companiesOptionsWithOwn,
    getCompaniesDictionary,
  } = useDictionariesApi();
  const {
    boUsers,
    boUsersMeta,
    currentUser,
    getUsers,
    deleteUser,
    postUser,
    putUser,
    putUpdateUserPassword,
    isPendingDeleteUser,
    isPendingPostUser,
    isPendingPutUser,
    patchAuthorities,
    isPendingPatchAuthorities,
    isPendingGetUsers,
    isPendingPutUpdateUserPassword,
    lastUpdatedGetUsers,
  } = useUsersApi();
  const getUsersQuery = (queries) => {
    getUsers({
      group: 'backoffice',
      ...queries,
    });
  };
  const onRowClickCallback = data => data;
  const {
    openModal,
    editableRecord,
    sendQuery,
    onCloseModal,
    limit,
    offset,
    setOffset,
    setLimit,
    onSortChanged,
    onFilterChanged,
    filteredColumns,
    openCustomViewDrawer,
    tableRef,
    onSaveCustomView,
    closeCustomViewDrawer,
    isOpenCustomViewDrawer,
    onDeleteClick,
    isFormPristine,
    formValuesRef,
    isOpenConfirm,
    closeConfirm,
    onDeleteConfirm,
    onConfirmClose,
    isOpenModal,
    onRowClicked,
    setEditableRecord,
  } = useScreen({
    screenRef,
    getFunc: getUsersQuery,
    deleteFunc: deleteUser,
    onRowClickCallback,
  });
  // memed vars
  const countryOptions = useMemo(
    () =>
      countries.map(item => ({
        value: item.value,
        label: item.label,
      })),
    [countries],
  );
  // functions
  const onSubmit = ({
    password,
    rePassword,
    ...values
  }) => (editableRecord ? putUser : postUser)({
    body: {
      ...values,
      group: 'backoffice',
    },
    successCallback: (res) => {
      sendQuery();

      const updatePermissions = () => {
        const allPermissions = boPermissions
          .map(item => item.permissions.map(el => el.id))
          .flat();
        patchAuthorities({
          body: {
            id: res.id,
            permissions: allPermissions,
          },
          successCallback: () => onCloseModal({ isSave: true }),
        });
      };

      if (password) {
        putUpdateUserPassword({
          id: res.id,
          body: {
            password,
          },
          successCallback: () => updatePermissions(),
          errorCallback: () => setEditableRecord(res),
        });
      } else {
        updatePermissions();
      }
    },
  });

  // effects
  useEffect(() => {
    getCompaniesDictionary();
    getRoles();
    getPermissions();
  }, []);

  const validationSchema = isOpenModal === 'add'
    ? BOUSERS_SCHEMA.concat(PASSWORD_SCHEMA)
    : BOUSERS_SCHEMA.concat(PASSWORD_LENGTH_SCHEMA);

  return (
    <div>
      <Table
        limit={limit}
        total={boUsersMeta.size}
        page={offset}
        onChangePage={setOffset}
        onChangeLimit={setLimit}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        filteredColumns={filteredColumns}
        tableHeader="TABS.BACKOFFICE_USERS"
        openForm={() => openModal('add')}
        openCustomColumns={openCustomViewDrawer}
        buttonText="CONTROLS.USERS.ADD"
        columnDefs={USERS(currentUser?.companyId)}
        rowData={boUsers}
        onRowClicked={onRowClicked}
        sendQuery={sendQuery}
        isPending={isPendingGetUsers || !lastUpdatedGetUsers}
        tableRef={tableRef}
      />
      <CustomColumnsView
        initialValues={filteredColumns}
        columnsNames={USERS()}
        onSubmit={onSaveCustomView}
        onClose={closeCustomViewDrawer}
        isOpen={isOpenCustomViewDrawer}
      />
      {isOpenModal && (
        <Modal
          mode={isOpenModal}
          closeModal={onCloseModal}
          title="SCREENS.USERS.USER"
          editableRecord={editableRecord}
        >
          <UsersForm
            mode={isOpenModal}
            onCancel={onCloseModal}
            initialValues={editableRecord}
            changeMode={openModal}
            onDelete={onDeleteClick}
            onSubmit={onSubmit}
            isPending={
              isPendingDeleteUser
              || isPendingPostUser
              || isPendingPutUser
              || isPendingPatchAuthorities
              || isPendingPutUpdateUserPassword
            }
            partnersOptions={companiesOptionsWithOwn}
            countriesOptions={countryOptions}
            timezones={timezones}
            boPermissions={boPermissions}
            boRoles={boRoles}
            isFormPristine={isFormPristine}
            validationSchema={validationSchema}
            formValuesRef={formValuesRef}
          />
        </Modal>
      )}
      {isOpenConfirm && (
        <ConfirmModal
          isDelete={isOpenConfirm === 'delete'}
          closeModal={closeConfirm}
          onConfirm={
            isOpenConfirm === 'delete' ? onDeleteConfirm : onConfirmClose
          }
        />
      )}
    </div>
  );
};
