const paths = {
  dashboard: '/dashboard',
  mccMnc: '/mcc-mnc',
  companies: '/companies',
  payments: '/payments',
  adapters: '/adapters',
  clients: '/clients',
  mKitUsers: '/mKit-users',
  agreements: '/agreements',
  accountsMain: '/accounts',
  accounts: '/accounts/:id',
  eventTypes: '/event-types',
  backOfficeUsers: '/backoffice-users',
  ratePlans: '/rate-plans',
  rates: '/rates',
  reports: '/reports',
  senderIds: '/sender-ids',
  applications: '/applications',
  charges: '/charges',
  settings: '/settings',
  smppConnections: '/smpp-connections',
  httpConnections: '/http-connections',
  ownLegalEntities: '/own-legal-entities',
  legalEntities: '/legal-entities',
  servicesEnabled: '/services-enabled',
  invoices: '/invoices',
  packs: '/packs-enabled',
  notificationsSettings: '/notifications-settings',
  notificationsChannelsSettings: '/notifications-channels-settings',
  services: '/services',
  printableForms: '/printable-forms',
  audit: '/audit',
  waba: '/waba',
  preModeration: '/pre-moderation',
  portals: '/portals',
  whatsappTemplates: '/whats-app-templates',
  clientSmppConnections: '/client-smpp-connections',
  additionalFees: '/additional-fees',
  twoFactorSms: '/2fa-sms',
};

export default paths;
